<template>
  <Header></Header>
  <section class="spacing__x f-header banner">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="mb-30">
            <p class="regular-color-1">We are excited to have you and your little ones at Kids Fun Hut. To ensure a safe
              and enjoyable experience for all, please read and understand the following terms and conditions before
              entering:</p>
            <span class="color-secondary-2 fw-700 mb-10 d-inline-block">Supervision</span>
            <div class="count-listing">
              <p class="heading-color-1"><span>1</span>Children must be supervised at all times by a responsible adult or
                guardian. Kids Fun Hut staff is available to assist with safety, however, the ultimate responsibility lies
                with the parents/guardians.</p>
            </div>
          </div>

          <span class="color-secondary-2 fw-700 mb-10 d-inline-block">
            Age and Weight Restrictions:
          </span>
          <div class="count-listing">
            <p class="heading-color-1"><span>1</span>Our play area is specifically designed for children of different ages
              depending on the activities/ attractions they chose to enjoy.</p>
          </div>
          <span class="color-secondary-2 fw-700 mb-10 d-inline-block">
            Health and Safety:
          </span>
          <div class="row">
            <div class="col-lg-12">
              <div class="count-listing">
                <p class="heading-color-1"><span>1</span> For the safety of all children, please do not visit the play area if your child is feeling unwell or has any contagious illnesses.</p>
                <p class="heading-color-1"><span>2</span> Proper attire, including socks, must be worn at all times. No shoes or bare feet are allowed inside the play area.</p>
                <p class="heading-color-1"><span>3</span> We maintain a strict no-nuts policy. Please refrain from bringing any nut-based products into the play area to accommodate children with allergies.</p>
              </div>
            </div>
          </div>

          <span class="color-secondary-2 fw-700 mb-10 d-inline-block">
            Respect for Others:
          </span>
          <div class="row">
            <div class="col-lg-12">
              <div class="count-listing">
                <p class="heading-color-1"><span>1</span> Be considerate of other guests and encourage your child to share and play nicely with others.</p>
                <p class="heading-color-1"><span>2</span> Rough play or aggressive behavior is not permitted.</p>
                <p class="heading-color-1"><span>3</span> Use of foul language or disrespectful conduct will result in eviction from the premises.</p>
              </div>
            </div>
          </div>


          <span class="color-secondary-2 fw-700 mb-10 d-inline-block">
            Food and Beverages:
          </span>
          <div class="row">
            <div class="col-lg-12">
              <div class="count-listing">
                <p class="heading-color-1"><span>1</span> Food and drinks are not allowed inside the play area.</p>
                <p class="heading-color-1"><span>2</span> We provide designated areas for dining and offer a selection of child-friendly snacks and beverages in our cafeteria.</p>
              </div>
            </div>
          </div>

          <span class="color-secondary-2 fw-700 mb-10 d-inline-block">
            Personal Belongings:
          </span>
          <div class="row">
            <div class="col-lg-12">
              <div class="count-listing">
                <p class="heading-color-1"><span>1</span> Kids Fun Hut is not responsible for any lost or stolen items. Please keep your personal belongings secure.</p>
              </div>
            </div>
          </div>


          <span class="color-secondary-2 fw-700 mb-10 d-inline-block">
            Cleanliness:
          </span>
          <div class="row">
            <div class="col-lg-12">
              <div class="count-listing">
                <p class="heading-color-1"><span>1</span> Please help us keep the play area clean and safe. Dispose of trash in designated bins and report any spills or hazards to our staff.</p>
              </div>
            </div>
          </div>

          <span class="color-secondary-2 fw-700 mb-10 d-inline-block">
            Photography and Video:
          </span>
          <div class="row">
            <div class="col-lg-12">
              <div class="count-listing">
                <p class="heading-color-1"><span>1</span> Taking photos and videos of your children is allowed. However, respect the privacy of other families by not capturing images or videos of their children without permission.</p>
              </div>
            </div>
          </div>

          <span class="color-secondary-2 fw-700 mb-10 d-inline-block">
            Liability:
          </span>
          <div class="row">
            <div class="col-lg-12">
              <div class="count-listing">
                <p class="heading-color-1"><span>1</span> Kids Fun Hut is not responsible for accidents or injuries that occur within the premises. For more information, please refer to our Waiver page for all queries regarding your child’s safety.</p>
              </div>
            </div>
          </div>

          <span class="color-secondary-2 fw-700 mb-10 d-inline-block">
            Closures and Refunds:
          </span>
          <div class="row">
            <div class="col-lg-12">
              <div class="count-listing">
                <p class="heading-color-1"><span>1</span> All tickets and purchases are non-refundable.</p>
                <p class="heading-color-1"><span>2</span> In case of unforeseen closures, we will provide refunds or alternative arrangements. Please refer to our website or contact us for specific closure policies.</p>
              </div>
            </div>
          </div>

          <span class="color-secondary-2 fw-700 mb-10 d-inline-block">
            Code of Conduct:
          </span>
          <div class="row">
            <div class="col-lg-12">
              <div class="count-listing">
                <p class="heading-color-1"><span>1</span> Failure to comply with these terms and conditions may result in expulsion from the play area without a refund.</p>
                <p class="heading-color-1"><span>2</span> Kids Fun Hut reserves the right to deny/ revoke entry of an adult or child in case of equipment misuse/ disrespect/ pose of harm or non-compliance to the terms and conditions set by Kids Fun Hut.</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <div class="count-listing">
                <p class="heading-color-1"> By submitting the waiver form at Kids Fun Hut, you acknowledge that you have read, understood, and agreed to the terms and conditions mentioned here and on the Waiver form. We are committed to providing a safe, fun, and enriching environment for children, and we appreciate your cooperation in creating a positive experience for everyone. Enjoy your visit!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer></Footer>
</template>
  
<script>
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
export default {
  name: 'Terms',
  components: {
    Header,
    Footer
  }
}
</script>