<template>
    <Header></Header>
    <Footer></Footer>
  </template>
  
  <script>
  import Header from './layouts/Header.vue'
  import Footer from './layouts/Footer.vue'
  export default {
    name: 'Camps',
    components: {
      Header,
      Footer
    }
  }
  </script>