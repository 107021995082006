
<template>
    <Header componentName="about-us" />
    <section class="parties-text-section theme-color spacing__x over-hidden">
        <div class="container position-relative">
            <img class="element element-star" src="@/assets/images/elements/star-element.png" alt="">
            <img class="element element-2" src="@/assets/images/elements/element-2.png" alt="">
            <div class="row align-items-center mb-40">
                <div class="col-lg-12">
                    <div class="mb-30">
                        <span class="color-secondary-2 fw-700 mb-10 d-inline-block tt-capitalize">ATTRACTIONS</span>
                        <h4 class="mb-20 heading-color-1 fs-30 tt-capitalize">
                            We Have Something For Everyone
                        </h4>
                    </div>
                </div>
            </div>

        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="aboutMainCards mb-4" :style="{ backgroundImage: `url(${getImgPath('about-main-1.png')})` }">
                        <div class="aboutMainCardsText">
                            <h4 class="section__heading"><span class="color-tertiary">Climbing </span> wall </h4>
                            <p>Indoor Vertical Rock Climb For Toddlers.</p>
                            <router-link to="/attractions/wall-climbing" class="btn-bor">Learn More</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="aboutMainCards mb-4" :style="{ backgroundImage: `url(${getImgPath('about-main-2.png')})` }">
                        <div class="aboutMainCardsText">
                            <h4 class="section__heading"><span class="color-tertiary">Soft</span> Play</h4>
                            <p>The ultimate kids heaven.</p>
                            <router-link to="/attractions/soft-play-ground" class="btn-bor">Learn More</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="aboutMainCards mb-4" :style="{ backgroundImage: `url(${getImgPath('about-main-3.png')})` }">
                        <div class="aboutMainCardsText">
                            <h4 class="section__heading"><span class="color-tertiary">Ninja</span> Course</h4>
                            <p>Run, Jump, Climb and Explore.</p>
                            <router-link to="/attractions/ninja-course" class="btn-bor">Learn More</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="aboutMainCards mb-4" :style="{ backgroundImage: `url(${getImgPath('about-main-4.png')})` }">
                        <div class="aboutMainCardsText">
                            <h4 class="section__heading"><span class="color-tertiary">Interactive </span> Light Wall</h4>
                            <p>Sensory and Responsive Light Interaction.</p>
                            <router-link to="/attractions/interactive-light-wall" class="btn-bor">Learn More</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="aboutMainCards mb-4" :style="{ backgroundImage: `url(${getImgPath('about-main-5.png')})` }">
                        <div class="aboutMainCardsText">
                            <h4 class="section__heading"><span class="color-tertiary">Soccer and Basket</span> Ball Court</h4>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting.</p>
                            <router-link to="/attractions/soccer-basketball-court" class="btn-bor">Learn More</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="aboutMainCards mb-4" :style="{ backgroundImage: `url(${getImgPath('about-main-6.png')})` }">
                        <div class="aboutMainCardsText">
                            <h4 class="section__heading"><span class="color-tertiary">Toddler </span> Area</h4>
                            <p>Dedicated and Supervised Play Area for Infants.</p>
                            <router-link to="/attractions/toddler-area" class="btn-bor">Learn More</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="aboutMainCards mb-4" :style="{ backgroundImage: `url(${getImgPath('about-main-7.png')})` }">
                        <div class="aboutMainCardsText">
                            <h4 class="section__heading"><span class="color-tertiary">Zipline</span> Area</h4>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting.</p>
                            <router-link to="/attractions/zipline" class="btn-bor">Learn More</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
</template>

<script>
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
export default {
    name: 'AboutUs',
    components: {
        Header,
        Footer
    },
    methods: {
        getImgPath(img) {
            return img ? require('@/assets/images/' + img) : '';
        }
    }
}
</script>
<style>
.promotionMain {
    margin-bottom: 30px;
}
</style>