export default {
    namespaced: true,
    state: {
        token: null,
        user: null
    },
    getters: {
        token(state) {
            const _localStorage = localStorage.getItem('token');
            if ((_localStorage != undefined) && (state.token == null))
                state.token = _localStorage;
            return state.token;
        },
        userDetails(state) {
            const userLocalStorage = localStorage.getItem('user');
            const user = JSON.parse(userLocalStorage);
            if ((user != undefined) && (state.user == null))
                state.user = user;
            return state.user;
        },
        isAuth(state, getters) {
            state.token = getters.token;
            if (state.token) return true;
            else return false;
        }
    },
    actions: {
        login({ commit }, object) {
            commit('SET_TOKEN', object.token);
            commit('SET_USER', object.user);
        },
        updateUser({ commit }, object) {
            commit('SET_USER', object);
        },
        logout({ commit }) {
            commit('SET_TOKEN', null);
            commit('SET_USER', null);
            localStorage.removeItem('token');
            localStorage.removeItem('user');
        }
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
            localStorage.setItem('token', state.token);
        },
        SET_USER(state, user) {
            state.user = user;
            const json = JSON.stringify(state.user);
            localStorage.setItem('user', json);
        }
    }
};
