<template>
    <Header />
    <section class="spacing__x f-header">
        <img class="prop prop-top-left" src="@/assets/images/elements/element-3.png" alt="" />
        <img class="prop prop-middle-left" src="@/assets/images/elements/element-4-1.png" alt="" />
        <img class="prop prop-middle-right" src="@/assets/images/elements/element-5.png" alt="" />
        <img class="prop prop-bottom-right" src="@/assets/images/elements/element-4.png" alt="" />
        <img class="prop prop-bottom-left" src="@/assets/images/elements/element-2.png" alt="" />
        <div class="container">
            <div class="cart-detail-section formButton mb-40 d-flex justify-content-between">
                <h1 class="fs-30 color-1 mb-10 flex-1 text-sm-center">
                    {{ showHeading() }}
                </h1>
                <!-- <div class="row justify-content-end flex-grow-1 flex-shrink-0 align-items-center gap-10 mb-15">
                    <a class="c-btn bg-color-tertiary br-10 text-shadow" href="#">Continue Shopping</a>
                    <template v-if="!$store.getters['auth/isAuth']">
                        <hr class="vr" />
                        <router-link to="/login" class="color-1">
                            <i class="fa fa-lock"></i>
                            Login
                        </router-link>
                    </template>
                </div> -->
            </div>

            <div class="form__wrap leadForm">
                <h4 class="section__heading color-white text-center py-4">
                    Please Fill In The Details
                </h4>
                <form @submit.prevent="formSubmit">
                    <div class="input__wrap">
                        <div class="form--label-wrap">
                            <label for="childPhoto">Child Photo:</label>
                        </div>
                        <div>
                            <input type="file" ref="fileInput" accept="image/*" id="childPhoto" @change="uploadFiles" />
                        </div>
                        <div class="input-errors" v-for="(error, index) of v$.membershipForm.childPhoto.$errors"
                            :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                        <div v-if="membershipForm.childPhoto != ''">
                            <img :src="membershipForm.childPhoto" />
                        </div>
                    </div>
                    <div class="input__wrap">
                        <div class="form--label-wrap">
                            <label for="firstName">Child First Name:</label>
                        </div>
                        <input type="text" id="firstName" v-model="membershipForm.firstName"
                            @blur="v$.membershipForm.firstName.$touch" />
                        <div class="input-errors" v-for="(error, index) of v$.membershipForm.firstName.$errors"
                            :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                    </div>

                    <div class="input__wrap">
                        <div class="form--label-wrap">
                            <label for="secondName">Child Second Name:</label>
                        </div>
                        <input type="text" id="secondName" v-model="membershipForm.secondName"
                            @blur="v$.membershipForm.secondName.$touch" />
                        <div class="input-errors" v-for="(error, index) of v$.membershipForm.secondName.$errors"
                            :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                    </div>

                    <div class="input__wrap">
                        <div class="form--label-wrap">
                            <label for="phoneNo">Phone Number:</label>
                        </div>
                        <input type="tel" id="phoneNo" v-model="membershipForm.phoneNo"
                            @blur="v$.membershipForm.phoneNo.$touch" />
                        <div class="input-errors" v-for="(error, index) of v$.membershipForm.phoneNo.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                    </div>

                    <button type="submit" class="c-btn style-4" :class="{ 'not-allowed': v$.membershipForm.$invalid }"
                        :disabled="v$.membershipForm.$invalid">
                        SAVE INFORMATION
                    </button>
                </form>
            </div>
        </div>
    </section>
    <Footer />
</template>
  
<script>
import Header from './layouts/Header.vue';
import Footer from './layouts/Footer.vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { validName, validPhoneNo } from '../utility/Validator';
import { AttractionType } from '../enums/index';
import { redirectScreen } from '../utility/helpers';

export default {
    name: 'MembershipForm',
    components: {
        Header,
        Footer
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            membershipForm: {
                childPhoto: '',
                firstName: '',
                secondName: '',
                phoneNo: ''
            }
        }
    },
    methods: {
        redirectScreen,
        uploadFiles(event) {
            let fileInput = event.target;
            const file = fileInput.files[0];
            if (file) {
                // Use FileReader to read the selected file as base64
                const reader = new FileReader();
                reader.onload = () => {
                    this.membershipForm.childPhoto = reader.result;
                };
                reader.readAsDataURL(file);
            }
        },
        formSubmit() {
            this.v$.$validate();
            if (!this.v$.$error) {
                this.$store.dispatch("loader/setLoader", true);
                const membership = this.findAttraction(AttractionType.Membership);
                membership.meta.child = this.membershipForm;
                delete membership.meta.party_reservation;
                this.$store.dispatch('cart/UpdateCart', {
                    activity: membership,
                    type: AttractionType.Membership
                });
                this.redirectScreen();
                setTimeout(() => {
                    this.$store.dispatch("loader/setLoader", false);
                }, 1000);
            }
        },
        showHeading() {
            const membership = this.findAttraction(AttractionType.Membership);
            const heading = membership.attractionName;
            return heading;
        },
        findAttraction(type) {
            const attractionByType = this.$store.getters['cart/Cart'].find(x => x.attractionType === type);
            if (attractionByType) {
                const attraction = attractionByType.attractions[0];
                return attraction;
            }
            return null;
        }
    },
    validations() {
        {
            return {
                membershipForm: {
                    childPhoto: {
                        required
                    },
                    firstName: {
                        required,
                        name_validation: {
                            $validator: validName,
                            $message: 'Invalid Name. Valid name only contain letters, dashes (-) and spaces.'
                        }
                    },
                    secondName: {
                        required,
                        name_validation: {
                            $validator: validName,
                            $message: 'Invalid Name. Valid name only contain letters, dashes (-) and spaces.'
                        }
                    },
                    phoneNo: {
                        required,
                        phone_validation: {
                            $validator: validPhoneNo,
                            $message: 'Invalid Phone Number. Valid phone number only contain Number.'
                        }
                    },
                },
            };
        }
    }
}
</script>