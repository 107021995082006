<template>
    <Header />
            <section class="spacing__x f-header">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="wavier-signin-info bg-color-primary">
                            <h4 class="fs-40">Sign online and save some time!</h4>
                            <p>
                                Everyone is required to sign a waiver form before entering Kids Fun Hut. The waiver is available online to avoid lineups or you can sign it in person once you arrive at Kids Fun Hut.
                            </p>
                            <router-link to="/signin-waiver" class="c-btn style-1 bg-color-secondary">Sign Waiver Now</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
</template>
<script>
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
export default {
  name: 'Waiver',
  components: {
    Header,
    Footer
  }
}
</script>