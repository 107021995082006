<template>
    <Header></Header>
    <Footer></Footer>
  </template>
  
  <script>
  import Header from '../layouts/Header.vue'
  import Footer from '../layouts/Footer.vue'
  export default {
    name: 'TrampolinePark',
    components: {
      Header,
      Footer
    }
  }
  </script>