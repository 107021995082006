import { createRouter, createWebHistory } from 'vue-router';
import Home from './src/components/Home.vue';
import Thankyou from './src/components/Helpers/Thankyou.vue';
import PaymentThankyou from './src/components/Helpers/PaymentThankyou.vue';
import PageNotFound from './src/components/Helpers/PageNotFound.vue';
import ArcadeGames from './src/components/Attractions/ArcadeGames.vue';
import IndoorPlayground from './src/components/Attractions/IndoorPlayground.vue';
import NinjaCourse from './src/components/Attractions/NinjaCourse.vue';
import TrampolinePark from './src/components/Attractions/TrampolinePark.vue';
import WallClimbing from './src/components/Attractions/WallClimbing.vue';
import AttractionListing from './src/components/AttractionListing.vue';
import Camps from './src/components/Camps.vue';
import Contact from './src/components/Contact.vue';
import GiftCards from './src/components/GiftCards.vue';
import Groups from './src/components/Groups.vue';
import Login from './src/components/Login.vue';
import Membership from './src/components/Membership.vue';
import Parties from './src/components/Parties.vue';
import Promotions from './src/components/Promotions.vue';
import Register from './src/components/Register.vue';
import SignIn from './src/components/SignIn.vue';
import SignInWaiver from './src/components/SignInWaiver.vue';
import Terms from './src/components/Terms.vue';
import Waiver from './src/components/Waiver.vue';
import WaiverDetail from './src/components/waiver-detail/Index.vue';
import CartDetail from './src/components/CartDetail.vue';
import BookingForm from './src/components/BookingForm.vue';
import CartDateTime from './src/components/CartDateTime.vue';
import MembershipForm from './src/components/MembershipForm.vue';
import SoftPlay from './src/components/Attractions/SoftPlay.vue';
import InteractiveLightWall from './src/components/Attractions/InteractiveLightWall.vue';
import ToddlerArea from './src/components/Attractions/ToddlerArea.vue';
import FunCafe from './src/components/Attractions/FunCafe.vue';
import BirthdayRooms from './src/components/Attractions/BirthdayRooms.vue';
import SoccerCourt from './src/components/Attractions/SoccerCourt.vue';   
import ZipLine from './src/components/Attractions/ZipLine.vue';
import AboutUs from './src/components/AboutUs.vue';
import ProfileListing from './src/components/my-profile/Index.vue';
import BillingInfo from './src/components/BillingInfo.vue';
const DEFAULT_TITLE = 'Kids Fun Hut';
const attractionsRoute = [
    {
        path: '/attractions/arcade-games',
        name: 'ArcadeGames',
        component: ArcadeGames,
        meta: { title: 'Arcade Games' }
    },
    {
        path: '/attractions/indoor-playground',
        name: 'IndoorPlayground',
        component: IndoorPlayground,
        meta: { title: 'Indoor Playground' }
    },
    {
        path: '/attractions/ninja-course',
        name: 'Ninja Course',
        component: NinjaCourse,
        meta: { title: 'Ninja Course' }
    },
    {
        path: '/attractions/trampoline-park',
        name: 'TrampolinePark',
        component: TrampolinePark,
        meta: { title: 'Trampoline Park' }
    },
    {
        path: '/attractions/wall-climbing',
        name: 'WallClimbing',
        component: WallClimbing,
        meta: { title: 'Wall Climbing' }
    },
    {
        path: '/attractions/soft-play-ground',
        name: 'SoftPlay',
        component: SoftPlay,
        meta: { title: 'Soft Play' }
    },
    {
        path: '/attractions/interactive-light-wall',
        name: 'InteractiveLightWall',
        component: InteractiveLightWall,
        meta: { title: 'Interactive Light Wall' }
    },
    {
        path: '/attractions/toddler-area',
        name: 'ToddlerArea',
        component: ToddlerArea,
        meta: { title: 'Toddler Area' }
    },
    {
        path: '/attractions/fun-cafe',
        name: 'FunCafe',
        component: FunCafe,
        meta: { title: 'Fun Cafe' }
    },
    {
        path: '/attractions/birthday-rooms',
        name: 'BirthdayRooms',
        component: BirthdayRooms,
        meta: { title: 'Birthday Rooms' }
    },
    {
        path: '/attractions/soccer-basketball-court',
        name: 'SoccerCourt',
        component: SoccerCourt,
        meta: { title: 'Soccer Court' }
    },
    {
        path: '/attractions/zipline',
        name: 'ZipLine',
        component: ZipLine,
        meta: { title: 'ZipLine' }
    },
];

const helperRoutes = [
    {
        path: '/thankyou',
        name: 'Thankyou',
        component: Thankyou,
        meta: { title: 'Thanks' }
    },
    {
        path: '/payment-thankyou',
        name: 'Payment Thankyou',
        component: PaymentThankyou,
        meta: { title: 'Thanks' }
    },
    {
        path: '/404',
        name: 'Page Not Found',
        component: PageNotFound,
        meta: { title: 'Page Not Found' }
    }
];

export const routes = [
    {
        path: '/:catchAll(.*)',
        redirect: '/404'
    },
    {
        path: '/shop/:name',
        name: 'Booking Listing',
        component: AttractionListing,
        meta: { title: 'Weekends' }
    },
    ...attractionsRoute,
    ...helperRoutes,
    {
        path: '/camps',
        name: 'Camps',
        component: Camps,
        meta: { title: 'Camps' }
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
        meta: { title: 'Contact' }
    },
    {
        path: '/gift-cards',
        name: 'GiftCards',
        component: GiftCards,
        meta: { title: 'Gift Cards' }
    },
    {
        path: '/groups',
        name: 'Groups',
        component: Groups,
        meta: { title: 'Groups' }
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { title: 'Home' }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: { title: 'Login' }
    },
    {
        path: '/membership',
        name: 'Membership',
        component: Membership,
        meta: { title: 'Membership' }
    },
    {
        path: '/parties',
        name: 'Parties',
        component: Parties,
        meta: { title: 'Birthday Parties' }
    },
    {
        path: '/promotions',
        name: 'Promotions',
        component: Promotions,
        meta: { title: 'Promotions' }
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        meta: { title: 'Register' }
    },
    {
        path: '/sign-in',
        name: 'Sign In',
        component: SignIn,
        meta: { title: 'SignIn' }
    },
    {
        path: '/signin-waiver',
        name: 'Sign In Waiver',
        component: SignInWaiver,
        meta: { title: 'Sign In Waiver' }
    },
    {
        path: '/terms',
        name: 'Terms',
        component: Terms,
        meta: { title: 'Terms' }
    },
    {
        path: '/waiver',
        name: 'Waiver',
        component: Waiver,
        meta: { title: 'Waiver' }
    },
    {
        path: '/waiver-detail',
        name: 'Waiver Detail',
        component: WaiverDetail,
        meta: { title: 'Waiver Detail' }
    },
    {
        path: '/cart-detail',
        name: 'CartDetail',
        component: CartDetail,
        meta: { title: 'Cart Detail' }
    },
    {
        path: '/booking-form',
        name: 'BookingForm',
        component: BookingForm,
        meta: { title: 'Book Party' }
    },
    {
        path: '/cart-schedule',
        name: 'CartDateTime',
        component: CartDateTime,
        meta: { title: 'Cart Schedule' }
    },
    {
        path: '/membership-detail',
        name: 'MembershipForm',
        component: MembershipForm,
        meta: { title: 'Membership Detail' }
    },
    {
        path: '/about-us',
        name: 'AboutUs',
        component: AboutUs,
        meta: { title: 'About Us' }
    },
    {
        path: '/my-profile',
        name: 'MyProfile',
        component: ProfileListing,
        meta: { title: 'My Profile' }
    },
    {
        path: '/billing-info',
        name: 'BillingInfo',
        component: BillingInfo,
        meta: { title: 'Billing Info' }
    },
    {
        path: '/shop/:name',
        name: 'Booking Listing',
        component: AttractionListing,
        meta: {
            titles: [
                { route: '/shop/weekends', title: 'Weekends' },
                { route: '/shop/weekdays', title: 'Weekends' },
                { route: '/shop/book-party', title: 'Book Party' }
            ]
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.afterEach((to, from) => {
    window.scrollTo(0, 0);
    if (Object.keys(to.params).length > 0 && to.meta?.titles?.length > 0) {
        const foundedRoute = to.meta?.titles.find((x) => x.route == to.fullPath);
        document.title = foundedRoute ? `${foundedRoute.title} | ${DEFAULT_TITLE}` : DEFAULT_TITLE;
    } else {
        document.title = to.meta.title ? `${to.meta.title} | ${DEFAULT_TITLE}` : DEFAULT_TITLE;
    }
});

export default router;
