<template>
    <Header />
    <!-- HEADING SECTION START -->
    <section class="spacing__x f-header banner">
        <img class="prop prop-middle-left" src="@/assets/images/elements/element-2.png" alt="">
        <img class="prop prop-bottom-right" src="@/assets/images/elements/element-3.png" alt="">
        <div class="container text-center position-relative">
            <h1 class="fs-60 heading-color-1">
                Kids Fun Hut Group Bookings
            </h1>
        </div>
    </section>
    <!-- HEADING SECTION END -->
    <!-- GROUP SECTION START -->
    <section class="spacing__x pt-0 pb-0 groupSection">
        <img src="@/assets/images/group-prop-2.png" alt="" class="prop prop-top-right star">
        <img src="@/assets/images/group-prop-2.png" alt="" class="prop prop-top-left star">

        <div class="container-fluid">
            <div class="section__text text-center">
                <h4 class="section__heading color-1">Coming Soon</h4>
                <!--<p class="color-1">Have custom requirements for a party or group? Email us today and get quotes for Kids Fun Hut booking catered to your needs. Bookings only entertained at least 7-Days in advance.</p>-->
                <!-- <div class="galleryButtons d-flex gap-20">
                    <button class="c-btn style-2">Camp Booking</button>
                    <button class="c-btn style-3">Day care Booking</button>
                    <button class="c-btn style-3">School Trips</button>
                </div> -->
            </div>
            <div class="row justify-content-center">
                <!--<div class="col-lg-4">
                    <div class="package-box style1">
                        <div class="price">
                            <h4 class="fs-40 fw-600  w-100 text-center position-relative z-index-1">
                                Entry
                                <div class=" pt-2">
                                    <span class="d-inline color-tertiary fw-300 strike-through mr-2">$20</span>
                                    <span class="d-inline">$15/Child</span>
                                </div>
                            </h4>
                        </div>
                        <div class="price-content groupPriceContent">
                            <h5 class="group--list-title">ACCESS TO:</h5>
                            <ul>
                                <li>Bouncy Castle</li>
                                <li>Ninja Courses</li>
                                <li>Warrior Zone</li>
                                <li>Softplay Area</li>
                                <li>3 Hour Play Time</li>
                            </ul>
                            <p class="group--blue-title">2 supervisors for every 10 kids</p>
                            <h5 class="group--footer-heading">A Room For Kids to Leave Their Bags!</h5>
                            <p class="group--footer-note">Anti-slip socks (required for all kids entering the facility)
                            </p>
                            <h5 class="group--footer-category">Arcade Games</h5>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="package-box style2">
                        <div class="price">
                            <h4 class="fs-40 fw-600  w-100 text-center position-relative z-index-1">
                                Entry + sock
                                <div class=" pt-2">
                                    <span class="d-inline color-tertiary fw-300 strike-through mr-2">$23</span>
                                    <span class="d-inline">$17.5/Child</span>
                                </div>
                            </h4>
                        </div>
                        <div class="price-content groupPriceContent">
                            <h5 class="group--list-title">ACCESS TO:</h5>
                            <ul>
                                <li>Bouncy Castle</li>
                                <li>Ninja Courses</li>
                                <li>Warrior Zone</li>
                                <li>Softplay Area</li>
                                <li>3 Hour Play Time</li>
                            </ul>
                            <p class="group--blue-title">2 supervisors for every 10 kids</p>
                            <h5 class="group--footer-heading">A Room For Kids to Leave Their Bags!</h5>
                            <p class="group--footer-note">Anti-slip socks (required for all kids entering the facility)
                            </p>
                            <h5 class="group--footer-category">Arcade Games</h5>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="package-box style1">
                        <div class="price">
                            <h4 class="fs-40 fw-600  w-100 text-center position-relative z-index-1">
                                Entry + sock+
                                <br>
                                Arcade
                                <div class=" pt-2">
                                    <span class="d-inline color-tertiary fw-300 strike-through mr-2">$28</span>
                                    <span class="d-inline">$22.5/Child</span>
                                </div>
                            </h4>
                        </div>
                        <div class="price-content groupPriceContent">
                            <h5 class="group--list-title">ACCESS TO:</h5>
                            <ul>
                                <li>Bouncy Castle</li>
                                <li>Ninja Courses</li>
                                <li>Warrior Zone</li>
                                <li>Softplay Area</li>
                                <li>3 Hour Play Time</li>
                            </ul>
                            <p class="group--blue-title">2 supervisors for every 10 kids</p>
                            <h5 class="group--footer-heading">A Room For Kids to Leave Their Bags!</h5>
                            <p class="group--footer-note">Anti-slip socks (required for all kids entering the facility)
                            </p>
                            <h5 class="group--footer-category">Arcade Games</h5>
                        </div>
                    </div>
                </div>-->
            </div>
        </div>
    </section>
    <!-- GROUP SECTION END -->

    <FiveColGridSection />
    <Footer></Footer>
</template>
  
<script>
import Header from './layouts/Header.vue';
import Footer from './layouts/Footer.vue';
import FiveColGridSection from './layouts/FiveColGridSection.vue';
export default {
    name: 'Groups',
    components: {
        Header,
        Footer,
        FiveColGridSection
    }
}
</script>