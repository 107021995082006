<template v-if="carousels && carousels.length !== 0">
    <div class="projectSliderContainer">
        <div class="row">
            <Carousel v-bind="settings"
                      :breakpoints="breakpoints"
                      ref="carousel"
                      v-model="currentSlide"
                      snapAlign="start">
                <Slide v-for="carousel in carousels"
                       :key="carousel.id">
                    <div class="carousel__item">
                        <div class="img__wrap">
                            <img :src="getImgPath(`${carousel.image}`)"
                                 alt="" />
                        </div>
                    </div>
                </Slide>
            </Carousel>
            <!-- <div class="col-lg-4">
                <div class="projectCard"
                     :style="{ backgroundImage: `url(${getImgPath('projectCard-1.png')})` }">
                </div>
            </div>
            <div class="col-lg-4">
                <div class="projectCard"
                     :style="{ backgroundImage: 'url(' + getImgPath('projectCard-2.png') + ')' }">

                </div>
            </div>
            <div class="col-lg-4">
                <div class="projectCard"
                     :style="{ backgroundImage: `url(${getImgPath('projectCard-3.png')})` }">
                </div>
            </div> -->
        </div>
        <div class="projectSliderArrows">
            <button class="prev"
                    @click="prev"><i class="ri-arrow-left-s-line"></i></button>
            <button class="next"
                    @click="next"><i class="ri-arrow-right-s-line"></i></button>
        </div>
    </div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
export default {
    name: 'HomeGalleryCarousel',
    props: {
        carousels: {
            type: Array,
            required: true
        }
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation
    },
    data() {
        return {
            // carousel settings
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            breakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                },
            },
            currentSlide: 0,
        };
    },
    methods: {
        next() {
            this.$refs.carousel.next();
        },
        prev() {
            this.$refs.carousel.prev();
        },
        getImgPath(img) {
            return img ? require('@/assets/images/home/' + img) : '';
        }
    }
};
</script>
