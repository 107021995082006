<template>
    <Header></Header>
    
    <!-- HEADING SECTION END -->
    <section class="spacing__x f-header ninja-text-section">
        <img class="element element-3" src="@/assets/images/elements/element-3.png" alt="" />
        <img class="element-2" src="@/assets/images/elements/element-2.png" alt="" />
        <img class="element-5" src="@/assets/images/elements/element-5.png" alt="" />
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mb-5">
                        <h1 class="fs-60 heading-color-1">{{ this.ComponentName }}</h1>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-7">
                    <div class="mb-30">
                        <span class="color-secondary-2 fw-700 mb-10 d-inline-block">
                            Soccer and Basketball Court
                        </span>
                        <h2 class="mb-20 heading-color-1 fs-30">
                            Where Little Athletes Score Big!
                        </h2>
                        <p class="fs-17 regular-color-1">
                            At Kids Fun Hut, we've created the ultimate indoor sports haven for young athletes to kick, shoot, and score in an environment designed just for them. Our indoor soccer and basketball court offer a dynamic space
                            where kids can cultivate their love for sports, build skills, and most importantly, have a blast!
                        </p>
                    </div>
                    <h3 class="fs-20 heading-color-1 tt-capitalize fw-600 mb-30">
                        Key Features of Soccer and Basketball Court:
                    </h3>

                    <div class="row">
                        <div class="col-lg-6">
                            <div class="count-listing">
                                <p class="heading-color-1"><span>1</span>Kid-Friendly Facility</p>
                                <p class="heading-color-1">
                                    <span>2</span>Safe and Supervised <br />
                                    Play
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="count-listing">
                                <p class="heading-color-1 align-items-start"><span>3</span>Skill-building</p>
                                <p class="heading-color-1"><span>4</span>Team spirit and Teamwork</p>
                            </div>
                        </div>
                    </div>

                    <p class="fs-17 regular-color-1 mb-30">
                        At Kids Fun Hut, we believe in the power of sports to inspire, teach, and create joyful memories. Book your court time today and watch as your child scores big in a space designed just for them! Age Requirements:
                        Toddlers to 12 years.
                    </p>
                </div>
                <div class="col-lg-5">
                    <img class="right-side" src="@/assets/images/attractions/soccer-and-basketball.png" alt="" />
                </div>
            </div>
            <div class="row">
                <div class="btn-list mb-20">
                    <router-link to="/shop/weekends" class="c-btn style-3">
                        Buy Weekend Tickets
                    </router-link>
                    <router-link to="/shop/weekdays" class="c-btn style-2">
                        Buy Weekday Tickets
                    </router-link>
                </div>
            </div>
        </div>
    </section>

    <section class="spacing__x showcase-ninja-section">
        <img class="element-4" src="@/assets/images/elements/element-4.png" alt="" />
    </section>


    <Footer></Footer>
</template>
  
<script>
import Header from '../layouts/Header.vue'
import Footer from '../layouts/Footer.vue'
export default {
    name: 'SoccerCourt',
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            ComponentName: 'Soccer and Basketball Court'
        };
    },
    methods: {
    }
};
</script>
