<template>
    <Header v-if="showHeaderFooterPanel" />
    <section class="" :class="showHeaderFooterPanel && 'f-header spacing__x-150'">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h1 class="fs-40 fw-700 tt-uppercase heading-color-1 mb-4">Payment Information</h1>
                </div>
                <div class="col-lg-12">
                    <form class="row fg-mb form" @submit.prevent="formSubmit">
                        <!-- nav-bar -->
                        <div class="col-lg-12">
                            <ul class="nav mb-4">
                                <li class="nav-item mr-3">
                                    <a class="nav-link c-btn style-2 active" @click="() => this.activeScreen = 1">Billing
                                        Information</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link c-btn style-3" @click="() => {
                                        if (!this.addressesMatch)
                                            this.activeScreen = 2;
                                    }" :disabled="addressesMatch" :class="{ 'not-allowed': addressesMatch }">
                                        Shipping Information
                                    </a>
                                </li>

                            </ul>
                        </div>
                        <!-- billing-detail-form -->
                        <div class="col-lg-12" v-if="activeScreen == 1">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Billing Street Address 1</label>
                                        <input type="text" class="custom-input-style h-60"
                                            v-model="form.billing_address.streetAddress1"
                                            @blur="v$.form.billing_address.streetAddress1.$touch">
                                        <div class="input-errors"
                                            v-for="(error, index) of v$.form.billing_address.streetAddress1.$errors"
                                            :key="index">
                                            <div class="error-msg">{{ error.$message }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Billing Street Address 2</label>
                                        <input type="text" class="custom-input-style h-60"
                                            v-model="form.billing_address.streetAddress2">
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Billing Street Address 3</label>
                                        <input type="text" class="custom-input-style h-60"
                                            v-model="form.billing_address.streetAddress3">
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-row">
                                        <div class="form-group col-md-3">
                                            <label>Billing City</label>
                                            <input type="text" class="custom-input-style h-60"
                                                v-model="form.billing_address.city"
                                                @blur="v$.form.billing_address.city.$touch">
                                            <div class="input-errors"
                                                v-for="(error, index) of v$.form.billing_address.city.$errors" :key="index">
                                                <div class="error-msg">{{ error.$message }}</div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label>Billing State</label>
                                            <input type="text" class="custom-input-style h-60"
                                                v-model="form.billing_address.state"
                                                @blur="v$.form.billing_address.state.$touch">
                                            <div class="input-errors"
                                                v-for="(error, index) of v$.form.billing_address.state.$errors"
                                                :key="index">
                                                <div class="error-msg">{{ error.$message }}</div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label>Billing Postal Code</label>
                                            <input type="text" class="custom-input-style h-60"
                                                v-model="form.billing_address.postalCode"
                                                @blur="v$.form.billing_address.postalCode.$touch">
                                            <div class="input-errors"
                                                v-for="(error, index) of v$.form.billing_address.postalCode.$errors"
                                                :key="index">
                                                <div class="error-msg">{{ error.$message }}</div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label>Billing Country</label>
                                            <input type="text" class="custom-input-style h-60"
                                                v-model="form.billing_address.country"
                                                @blur="v$.form.billing_address.country.$touch">
                                            <div class="input-errors"
                                                v-for="(error, index) of v$.form.billing_address.country.$errors"
                                                :key="index">
                                                <div class="error-msg">{{ error.$message }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="c-check-box form-group">
                                        <input type="checkbox" id="addressesMatch" v-model="addressesMatch">
                                        <label for="addressesMatch">Shipping Address Same As Billing Address</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- shipping-detail-form -->
                        <div class="col-lg-12" v-else>
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Shipping Street Address 1</label>
                                        <input type="text" class="custom-input-style h-60"
                                            v-model="form.shipping_address.streetAddress1"
                                            @blur="v$.form.shipping_address.streetAddress1.$touch">
                                        <div class="input-errors"
                                            v-for="(error, index) of v$.form.shipping_address.streetAddress1.$errors"
                                            :key="index">
                                            <div class="error-msg">{{ error.$message }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Shipping Street Address 2</label>
                                        <input type="text" class="custom-input-style h-60"
                                            v-model="form.shipping_address.streetAddress2">
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Shipping Street Address 3</label>
                                        <input type="text" class="custom-input-style h-60"
                                            v-model="form.shipping_address.streetAddress3">
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-row">
                                        <div class="form-group col-md-3">
                                            <label for="city">Shipping City</label>
                                            <input type="text" class="custom-input-style h-60"
                                                v-model="form.shipping_address.city"
                                                @blur="v$.form.shipping_address.city.$touch">
                                            <div class="input-errors"
                                                v-for="(error, index) of v$.form.shipping_address.city.$errors"
                                                :key="index">
                                                <div class="error-msg">{{ error.$message }}</div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label for="state">Shipping State</label>
                                            <input type="text" class="custom-input-style h-60"
                                                v-model="form.shipping_address.state"
                                                @blur="v$.form.shipping_address.state.$touch">
                                            <div class="input-errors"
                                                v-for="(error, index) of v$.form.shipping_address.state.$errors"
                                                :key="index">
                                                <div class="error-msg">{{ error.$message }}</div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label for="postalCode">Shipping Postal Code</label>
                                            <input type="text" class="custom-input-style h-60"
                                                v-model="form.shipping_address.postalCode"
                                                @blur="v$.form.shipping_address.postalCode.$touch">
                                            <div class="input-errors"
                                                v-for="(error, index) of v$.form.shipping_address.postalCode.$errors"
                                                :key="index">
                                                <div class="error-msg">{{ error.$message }}</div>
                                            </div>    
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label for="country">Shipping Country</label>
                                            <input type="text" class="custom-input-style h-60"
                                                v-model="form.shipping_address.country"
                                                @blur="v$.form.shipping_address.country.$touch">
                                            <div class="input-errors"
                                                v-for="(error, index) of v$.form.shipping_address.country.$errors"
                                                :key="index">
                                                <div class="error-msg">{{ error.$message }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="d-inline-block form-group">
                                <button type="submit" class="c-btn style-1" :disabled="v$.form.$invalid"
                                    :class="{ 'not-allowed': v$.form.$invalid }">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <Footer v-if="showHeaderFooterPanel" />
</template>

<script>
import Header from "./layouts/Header.vue";
import Footer from "./layouts/Footer.vue";
import { MyProfile } from "../apiAgent";
import useVuelidate from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators';
import { validString } from '../utility/Validator';
export default {
    name: "BillingInfo",
    props: {
        showHeaderFooterPanel: {
            type: Boolean,
            default: true,
            required: false
        }
    },
    components: {
        Header,
        Footer
    },
    setup() {
        return { v$: useVuelidate() };
    },
    mounted() {
        this.getBillingInfo();
    },
    data() {
        return {
            form: {
                billing_address: {
                    streetAddress1: "",
                    streetAddress2: "",
                    streetAddress3: "",
                    city: "",
                    state: "",
                    postalCode: "",
                    country: "",
                },
                shipping_address: {
                    streetAddress1: "",
                    streetAddress2: "",
                    streetAddress3: "",
                    city: "",
                    state: "",
                    postalCode: "",
                    country: "",
                }
            },
            activeScreen: 1,
            addressesMatch: false,
        }
    },
    methods: {
        getBillingInfo() {
            this.$store.dispatch("loader/setLoader", true);
            try {
                const user = this.$store.getters['auth/userDetails'];
                if (user.billing_address != null) {
                    this.form.billing_address = user.billing_address;
                }
                if (user.shipping_address != null) {
                    this.form.shipping_address = user.shipping_address;
                }
                setTimeout(() => {
                    this.$store.dispatch("loader/setLoader", false);
                }, 500);
            } catch (error) {
                console.error(error);
                this.$store.dispatch("loader/setLoader", false);
            }
        },
        formSubmit() {
            this.v$.$validate();
            if (!this.v$.$error) {
                this.$store.dispatch('loader/setLoader', true);
                const user = this.$store.getters['auth/userDetails'];
                const body = {
                    id: user.id,
                    name: user.name,
                    email: user.email,
                    phone_number: user.phone_number,
                    dob_day: user.dob_day,
                    dob_month: user.dob_month,
                    dob_year: user.dob_year,
                    billing_address: this.form.billing_address,
                    shipping_address: this.form.shipping_address
                };
                if(this.addressesMatch){
                    body.shipping_address = body.billing_address;
                }
                MyProfile.UpdateUser(user.id, body)
                    .then(() => {
                        const userDetails = { ...this.$store.getters['auth/userDetails'], ...body };
                        this.$store.dispatch('auth/updateUser', userDetails);
                        this.$store.dispatch('loader/setLoader', false);
                        this.$iziToast.success({
                            title: 'Success',
                            message: 'Payment details updated!'
                        });
                        const previousRoute = this.$router.options.history.state.back;
                        if (previousRoute == '/cart-detail') {
                            this.$router.push('./cart-detail');
                        }
                    })
                    .catch(error => {
                        this.$store.dispatch('loader/setLoader', false);
                        console.error(error);
                        this.$iziToast.error({
                            title: 'Error',
                            message: 'Something went wrong, try again or contact site administrator!',
                        });
                    });
            }
        }
    },
    validations() {
        {
            return {
                form: {
                    billing_address: {
                        streetAddress1: {
                            required
                        },
                        city: {
                            required,
                            state_validation: {
                                $validator: validString,
                                $message: 'Invalid City, Please enter valid state.'
                            }
                        },
                        state: {
                            required,
                            state_validation: {
                                $validator: validString,
                                $message: 'Invalid State, Please enter valid state.'
                            }
                        },
                        postalCode: {
                            required
                        },
                        country: {
                            required,
                            state_validation: {
                                $validator: validString,
                                $message: 'Invalid Country, Please enter valid state.'
                            }
                        },
                    },
                    shipping_address: {
                        streetAddress1: {
                            required: requiredIf(function () {
                                return !this.addressesMatch;
                            }),
                        },
                        city: {
                            required: requiredIf(function () {
                                return !this.addressesMatch;
                            }),
                            city_validation: {
                                $validator: function () {
                                    if (!this.addressesMatch) {
                                        return validString(this.form.shipping_address.city);
                                    }
                                    return true;
                                },
                                $message: 'Invalid City, Please enter valid state.'
                            }
                        },
                        state: {
                            required: requiredIf(function () {
                                return !this.addressesMatch;
                            }),
                            state_validation: {
                                $validator: function () {
                                    if (!this.addressesMatch) {
                                        return validString(this.form.shipping_address.state);
                                    }
                                    return true;
                                },
                                $message: 'Invalid State, Please enter valid state.'
                            }
                        },
                        postalCode: {
                            required: requiredIf(function () {
                                return !this.addressesMatch;
                            })
                        },
                        country: {
                            required: requiredIf(function () {
                                return !this.addressesMatch;
                            }),
                            country_validation: {
                                $validator: function () {
                                    if (!this.addressesMatch) {
                                        return validString(this.form.shipping_address.country);
                                    }
                                    return true;
                                },
                                $message: 'Invalid Country, Please enter valid state.'
                            }
                        },
                    }
                }
            }
        }
    }
}
</script>