export default {
    namespaced: true,
    state: {
        //NOTE: cart Model.
        // {
        //     attractionType: '',
        //     booking_date: '',
        //     booking_time: '',
        //     attractions: [
        //         {
        //             attractionId: 0,
        //             attractionName: '',
        //             singlePrice: 0.0,
        //             advancePayment : 0.0,
        //             roomId : null, 
        //             quantity: 0,
        //             discount:
        //             {
        //                 amount: 0.00,
        //                 type: ''
        //             },
        //             meta: {}
        //         }
        //     ],
        //    partyTimeSlots:[], //This object will only be set, if attraction is Party.
        // }
        cart: []
    },
    getters: {
        Cart(state) {
            const storageCart = localStorage.getItem('cart');
            if (state.cart.length == 0 && storageCart != undefined) {
                const _cart = JSON.parse(storageCart);
                state.cart = _cart;
            }
            return state.cart;
        }
    },
    actions: {
        SaveCart({ commit }, cart) {
            commit('SET_CART', cart);
        },
        UpdateCart({ commit }, object) {
            commit('UPDATE_CART', object);
        },
        RemoveCart({ commit }, object) {
            commit('UNSET_CART', object);
        },
        DeleteCart({ commit }) {
            commit('DELETE_CART');
        },
        DeleteSpecificAttraction({ commit }, attractionName){
            commit('DELETE_ATTRACTION_FROM_CART', attractionName);
        }
    },
    mutations: {
        SET_CART(state, object) {
            const cart = state.cart.find((o) => o.attractionType === object.attractionType);
            if (cart)
                cart.attractions.push(object.attractions[0]);
            else 
                state.cart.push(object);
            const _json = JSON.stringify(state.cart);
            localStorage.setItem('cart', _json);
        },
        UPDATE_CART(state, object) {
            const cart = state.cart.find((o) => o.attractionType === object.type);
            const index = cart.attractions.findIndex((o) => o.attractionId == object.activity.attractionId);
            cart.attractions[index] = { ...object.activity };
            const _json = JSON.stringify(state.cart);
            localStorage.setItem('cart', _json);
        },
        UNSET_CART(state, object) {
            const cart = state.cart.find((o) => o.attractionType === object.attractionType);
            const index = cart.attractions.findIndex((o) => o.attractionId == object.attractionId);
            cart.attractions.splice(index, 1);
            if (cart.attractions.length > 0) {
                const _json = JSON.stringify(state.cart);
                localStorage.setItem('cart', _json);
            } else {
                const _index = state.cart.findIndex((o) => o.attractionType === object.attractionType);
                state.cart.splice(_index, 1);
                const _json = JSON.stringify(state.cart);
                localStorage.setItem('cart', _json);
                if(state.cart.length === 0){
                    localStorage.removeItem('cart');
                }
            }
        },
        DELETE_CART(state) {
            state.cart = [];
            localStorage.removeItem('cart');
        },
        DELETE_ATTRACTION_FROM_CART(state, attractionName){
            const _index = state.cart.findIndex((o) => o.attractionType === attractionName);
            if(_index != -1 ){
                state.cart.splice(_index, 1);
                const _json = JSON.stringify(state.cart);
                localStorage.setItem('cart', _json);
            }
        }
    }
};