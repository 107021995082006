<template>
    <Header></Header>
    <section class="spacing__x f-header banner">
        <img
            class="element-3"
            src="@/assets/images/elements/element-3.png"
            alt=""
        />
        <div class="container text-center position-relative">
            <h1 class="fs-60 heading-color-1">
                {{ this.ComponentName }}
            </h1>
        </div>
    </section>

    <section class="ninja-text-section">
        <img
            class="element-2"
            src="@/assets/images/elements/element-2.png"
            alt=""
        />
        <img
            class="element-5"
            src="@/assets/images/elements/element-5.png"
            alt=""
        />
        <div class="container">
            <div class="row">
                <div class="col-lg-7">
                    <div class="mb-30">
                        <span
                            class="color-secondary-2 fw-700 mb-10 d-inline-block"
                            >A World of Wonder for Your Little Explorers</span
                        >
                        <h2 class="mb-20 heading-color-1 fs-30">
                            Ultimate Soft Playground Experience
                        </h2>
                        <p class="fs-17 regular-color-1">
                            At Kids Fun Hut, we've crafted a magical space where
                            the tiniest adventurers can giggle, crawl, and play
                            to their hearts' content. Our dedicated Toddler Area
                            is designed with love, safety, and endless joy in
                            mind, ensuring that your little ones have the
                            perfect environment to take their first steps into
                            the wonders of play.
                        </p>
                    </div>
                    <h3
                        class="fs-20 heading-color-1 tt-capitalize fw-600 mb-30"
                    >
                        Key Features of Toddler Area:
                    </h3>

                    <div class="row">
                        <div class="col-lg-6">
                            <div class="count-listing">
                                <p class="heading-color-1">
                                    <span>1</span>Safe Haven for Tiny Feet
                                </p>
                                <p class="heading-color-1">
                                    <span>2</span>Parent Friendly
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="count-listing">
                                <p class="heading-color-1 align-items-start">
                                    <span>3</span>
                                    Soft Play Structures
                                </p>
                                <p class="heading-color-1">
                                    <span>4</span>
                                    Colorful Environment
                                </p>
                            </div>
                        </div>
                    </div>
                    <p class="fs-17 regular-color-1 mb-30">
                        Foster early social skills as your little ones make
                        friends in a space designed for shared play,
                        cooperation, and joyful interactions. Relax in our cozy
                        seating areas strategically placed within the Toddler
                        Area, allowing you to keep a close eye on your little
                        adventurers while they explore and play. Our Toddler
                        Area is designed with a focus on safety. Soft padded
                        surfaces, gentle slopes, and age-appropriate features
                        create a secure play palace for your little explorers.
                    </p>
                </div>
                <div class="col-lg-5">
                    <img
                        class="right-side"
                        src="@/assets/images/attractions/toddler-area.png"
                        alt=""
                    />
                    <!-- <img class="right-side" src="@/assets/images/attractions/wall-climbing.jpg" alt=""> -->
                </div>
            </div>
        </div>
    </section>

    <section class="spacing__x showcase-ninja-section">
        <img
            class="element-4"
            src="@/assets/images/elements/element-4.png"
            alt=""
        />
        <img
            class="element-6"
            src="@/assets/images/elements/element-6.png"
            alt=""
        />
        <img
            class="element-7"
            src="@/assets/images/elements/element-7.png"
            alt=""
        />
        <img
            class="element-8"
            src="@/assets/images/elements/element-8.png"
            alt=""
        />
        <div class="container">
            <div class="text-center">
                <div class="mb-20">
                    <h4 class="mb-20 heading-color-1 fs-30 tt-capitalize">
                        Bring out the Ninja in your child
                    </h4>
                    <p class="fs-17 regular-color-1">
                        Step into a world of ninja adventures as your kids take
                        on various challenges inspired by the legendary warriors
                        of ancient Japan. Our ninja-themed décor and immersive
                        environment will transport them to a world of
                        excitement. Whether your child is 3 or 13, we have
                        age-appropriate obstacles and activities suitable for
                        every young ninja. From climbing walls to trampoline
                        parks, there's something for everyone.
                    </p>
                </div>
                <div class="mb-20">
                    <span
                        class="color-secondary-2 fw-700 mb-10 d-inline-block tt-capitalize"
                        >Family Fun</span
                    >
                    <p class="fs-17 regular-color-1">
                        Ninja Kids Adventure Park isn't just for kids. We
                        welcome parents to join in the fun, watch their little
                        ninjas conquer obstacles, or relax in our Ninja Lounge
                        while sipping on a beverage.
                    </p>
                </div>
                <div class="mb-20">
                    <span
                        class="color-secondary-2 fw-700 mb-10 d-inline-block tt-capitalize"
                        >Safe and Supervised</span
                    >
                    <p class="fs-17 regular-color-1">
                        Safety is our top priority. Our facility is equipped
                        with state-of-the-art safety features, and our trained
                        staff members are always present to ensure a secure and
                        enjoyable experience. Check our opening hours, admission
                        rates, and special offers. Don't forget to review our
                        safety guidelines and dress code to ensure a safe and
                        enjoyable experience for everyone.
                    </p>
                </div>
                <div class="btn-list mb-20 justify-content-center">
                    <router-link to="/shop/weekends" class="c-btn style-3"
                        >Buy Weekend Tickets</router-link
                    >
                    <router-link to="/shop/weekdays" class="c-btn style-2"
                        >Buy Weekday Tickets</router-link
                    >
                </div>
            </div>
        </div>
    </section>

    <Footer></Footer>
</template>

<script>
import Header from "../layouts/Header.vue";
import Footer from "../layouts/Footer.vue";
export default {
    name: "BirthdayRooms",
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            ComponentName: "BirthdayRooms",
        };
    },
    methods: {},
};
</script>
