import { createStore } from 'vuex'
import auth from './modules/auth';
import cart from './modules/cart';
import loader from './modules/loader';
import bookingForm from './modules/booking-form';
import promotionMembership from './modules/promotion-membership';

export default createStore({
    modules: {
        auth,
        cart,
        loader,
        bookingForm,
        promotionMembership
    },
});