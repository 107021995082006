export default {
    namespaced: true,
    state: {
      isLoading: false,
    },
    getters: {
      isLoader(state) {
        return state.isLoading;
      },
    },
    actions: {
      setLoader({ commit }, load) {
        commit('SET_Loader', load);
      }
    },
    mutations: {
      SET_Loader(state, isLoad) {
        state.isLoading = isLoad;
      }
    }
  }