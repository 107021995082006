<template>
  <Header componentName="home"/>
  <!-- HOME BANNER START -->
  <section class="homeBanner spacing__x f-header">
    <!-- PROP -->
    <img src="@/assets/images/home/home-prop-1.png" alt="" class="prop prop-top-left homeProp-1">
    <img src="@/assets/images/home/home-prop-2.png" alt="" class="prop prop-top-right prop-screen-end">
    <img src="@/assets/images/home/home-prop-3.png" alt="" class="prop prop-bottom-right prop-screen-end">
    <img src="@/assets/images/home/prop-4.png" alt="" class="prop prop-middle-center prop-screen-end">

    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-lg-5 col-sm-12">
          <div class="bannerText">
            <h1>
              The ultimate place for Fun, Excitement and Joy
            </h1>
            <p>Come to a place where adventure and wonder starts for the little ones.</p>
            <router-link to="/contact" class="c-btn style-3">Get In Touch</router-link>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12">
          <!-- Add Carousel here. -->
          <HomeHeadingCarousel :carousels="carousels"/>
        </div>
      </div>
    </div>
    <div class="bannerInfo">
      <div class="d-flex gap-15 align-items-center">
        <div class="flex-shrink-0"><img src="@/assets/images/home/cal.png" alt=""></div>
        <div class="text__wrap">
          <a href="mailto:info@kidsfunhut.ca" class="color-secondary-2 d-block">info@kidsfunhut.ca</a>
          <a href="tel:5488893504" class="color-primary d-block">548-889-3504</a>
        </div>
      </div>
    </div>
  </section>
  <!-- HOME BANNER END -->

  <!-- HOME ABOUT SECTION START -->
  <section class="homeAboutSection bg-color-primary spacing__x bottom">

    <div class="prop__container" style="max-width: 1390px; width: 100%; margin-inline: auto;">
      <img src="@/assets/images/home/about-prop.png" alt="" class="prop prop-top-left prop-screen-end">
      <img src="@/assets/images/home/about-prop-3.png" alt="" class="prop prop-bottom-left prop-screen-end">
      <img src="@/assets/images/home/about-prop-2.png" alt="" class="prop prop-top-right prop-screen-end">
      <img src="@/assets/images/home/about-prop-4.png" alt="" class="prop prop-bottom-right prop-screen-end">
      <div class="spacing__x-150">
        <!-- PROPS -->
        <div class="container">
          <div class="row align-items-center justify-content-between">
            <div class="col-lg-5 col-sm-12">
              <div class="img__wrap">
                <img src="@/assets/images/home/home-about.png" alt="">
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <div class="homeAboutText">
                <h4 class="section__heading">
                  Why Us?
                </h4>
                <p>Step into a world where dreams come alive and ordinary days transform into extraordinary
                  adventures. Kids Fun Hut is a place where young hearts can laugh, play and jump their
                  hearts out. So are you ready for the best day of their life?</p>
                <div class="aboutStats">
                  <div class="d-flex justify-content-between">
                    <h6>Excitement </h6>
                    <div class="percentage">
                      100%
                    </div>
                  </div>
                  <div class="statLine">
                    <div style="width: 100%;"></div>
                  </div>
                </div>
                <div class="aboutStats">
                  <div class="d-flex justify-content-between">
                    <h6>Emotion</h6>
                    <div class="percentage">
                      100%
                    </div>
                  </div>
                  <div class="statLine">
                    <div style="width: 100%;"></div>
                  </div>
                </div>
                <router-link to="/about-us" class="c-btn style-3">About Us</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- HOME ABOUT SECTION END -->

  <!-- PROJECT SECTION START -->
  <section class="projectSection spacing__x">
    <!-- PROP -->
    <img src="@/assets/images/home/project-prop.png" alt="" class="prop prop-top-right">


    <div class="container" style="max-width: 1300px;">
      <div class="section__text text-center">
        <h4 class="section__heading color-1">Our Gallery</h4>
      </div>
      <HomeGalleryCarousel :carousels="galleryCarousels"/>
    </div>
  </section>
  <!-- PROJECT SECTION END -->

  <!-- EXPERIENCE SECTION START -->
  <section class="experienceSection spacing__x">
    <!-- PROP -->
    <img src="@/assets/images/home/experience-prop-1.png" alt="" class="prop prop-top-left">
    <img src="@/assets/images/home/experience-prop-2.png" alt="" class="prop prop-top-right">

    <div class="container mb-20">
      <div class="row align-items-center">
        <div class="col-lg-6 col-sm-12">
          <div class="experienceText">
            <h6 class="section__label color-secondary-2 fw-700 mb-15">WHAT WE DO</h6>
            <h4 class="section__heading color-1 mb-15">We are not just another place for your kids to play</h4>
            <p class="fs-30 fw-400">Kids Fun Hut is not just a place; it's a state of mind where excitement is
              touching the roofs and every moment of your child is unforgettable.
            </p>
            <ul>
              <li>Wide range of different physical activities for your kids</li>
              <li>State-of-the-art equipment so that your child is safe at every moment</li>
            </ul>
            <router-link to="/booking-form" class="c-btn style-3 mt-4">Book Now</router-link>
          </div>
        </div>
        <div class="col-lg-6 col-sm-12">
          <div class="img__wrap position-relative">
            <img src="@/assets/images/home/expi-1.png" alt="">
            <div class="years-of-experience">
              <h4>Celebrate what <br/>is and yet to be!</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- EXPERIENCE SECTION END -->

  <section class="activitySection spacing__x">
    <!-- PROPS -->
    <img src="@/assets/images/home/acti-prop-1.png" alt="" class="prop prop-top-left">
    <img src="@/assets/images/home/acti-prop-2.png" alt="" class="prop prop-bottom-right">

    <div class="activityMainContainer bottomRotate spacing__x-150">
      <div class="container">
        <div class="section__text text-center pt-0">
          <h4 class="section__heading color-1">We're Always Up <br> To Something New</h4>
        </div>
        <div class="activeMain">
          <div class="row">
            <div class="col-lg-4 col-sm-12">
              <div class="activityCards">
                <div class="img__wrap">
                  <img src="@/assets/images/home/activity-1.png" alt="">
                </div>
                <h6>Soccer and Basketball Court</h6>
              </div>
            </div>
            <div class="col-lg-4 col-sm-12">
              <div class="activityCards">
                <div class="img__wrap">
                  <img src="@/assets/images/home/activity-2.png" alt="">
                </div>
                <h6>Soft Playground Area</h6>
              </div>
            </div>
            <div class="col-lg-4 col-sm-12">
              <div class="activityCards">
                <div class="img__wrap">
                  <img src="@/assets/images/home/activity-3.png" alt="">
                </div>
                <h6>Toddlers Area</h6>
              </div>
            </div>
            <div class="col-lg-4 col-sm-12">
              <div class="activityCards">
                <div class="img__wrap">
                  <img src="@/assets/images/home/activity-4.png" alt="">
                </div>
                <h6>Zip Line</h6>
              </div>
            </div>
            <div class="col-lg-4 col-sm-12">
              <div class="activityCards">
                <div class="img__wrap">
                  <img src="@/assets/images/home/activity-5.png" alt="">
                </div>
                <h6>Ninja Warriors</h6>
              </div>
            </div>
            <div class="col-lg-4 col-sm-12">
              <div class="activityCards">
                <div class="img__wrap">
                  <img src="@/assets/images/home/activity-6.png" alt="">
                </div>
                <h6>Climbing wall and interactive wall</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- GALLERY SECTION START -->
  <section class="gallerySection spacing__x">
    <img src="@/assets/images/tickets/gallery-prop-1.png" class="element element-1" alt="">
    <img src="@/assets/images/elements/element-7.png" class="element element-2" alt="">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section__text text-center">
            <h4 class="section__heading color-1">Our Featured Gallery</h4>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="galleryButtons d-flex gap-20">
            <template v-for="(zone, index) in zones" :key="index">
              <button :class="`c-btn style-${index + 1}`" @click="populateZoneGallery(zone.slug)">
                {{ zone.title }}
              </button>
            </template>
          </div>
        </div>
      </div>
      <div class="galleryGrid">
        <template v-for="(gallery, index) in zoneGallery" :key="index">
          <div :class="`img__wrap ${applyClassOnBehalfOfIndex(gallery.priority)}`">
            <img :src="`${baseUrl}/${gallery.path}`" alt="">
          </div>
        </template>
      </div>

    </div>
  </section>
  <!-- GALLERY SECTION END -->
  <Footer/>
</template>

<script>
import Header from "./layouts/Header.vue";
import Footer from "./layouts/Footer.vue";
import HomeHeadingCarousel from "./layouts/carousels/HomeHeadingCarousel.vue";
import HomeGalleryCarousel from "./layouts/carousels/HomeGalleryCarousel.vue";
import {Home} from '../apiAgent'
import SectionType from '../enums/home/sectionType';

export default {
  name: "Home",
  components: {
    Header,
    Footer,
    HomeHeadingCarousel,
    HomeGalleryCarousel
  },
  mounted() {
    this.getPageMeta();
  },
  data() {
    return {
      carousels: [],
      galleryCarousels: [
        {id: 1, image: 'our-gallery/our-gallery-1.png'},
        {id: 2, image: 'our-gallery/our-gallery-2.png'},
        {id: 3, image: 'our-gallery/our-gallery-3.png'},
        {id: 4, image: 'our-gallery/our-gallery-4.png'},
        {id: 5, image: 'our-gallery/our-gallery-5.png'},
        {id: 6, image: 'our-gallery/our-gallery-6.png'},
        {id: 7, image: 'our-gallery/our-gallery-7.png'},
      ],
      baseUrl: `${process.env.VUE_APP_FORGOT_PASSWORD_LINK}/`,
      zones: [],
      zoneGallery: []
    }
  },
  methods: {
    populateZoneGallery(slug) {
      this.zoneGallery = [];
      const _zone = this.zones.find(x => x.slug === slug);
      if (_zone)
        this.zoneGallery = _zone.media;
    },
    applyClassOnBehalfOfIndex(index) {
      let cssClass;
      switch (index) {
        case 1:
        case 4:
          cssClass = 'span-column-two';
          break;
        case 3:
          cssClass = 'span-row-two';
          break;
        default:
          cssClass = '';
      }
      return cssClass;
    },
    getPageMeta() {
      this.$store.dispatch("loader/setLoader", true);
      Home.pageMeta()
          .then(({data}) => {
            this.getFeaturedGallery(data);
            this.getHomeCarouselGallery(data);
            this.$store.dispatch("loader/setLoader", false);
          })
          .catch(() => {
            this.$store.dispatch("loader/setLoader", false);
          });
    },
    getFeaturedGallery(galleries) {
      if (galleries?.length > 0) {
        const filter = galleries.filter(section => {
          if (section.slug === SectionType.allZones ||
              section.slug === SectionType.ninjaCourse ||
              section.slug === SectionType.soccerBasketBall ||
              section.slug === SectionType.softPlay) {
            return section;
          }
        });
        this.zones = filter;
        this.populateZoneGallery(SectionType.allZones);
      }
    },
    getHomeCarouselGallery(galleries) {
      if (galleries?.length > 0) {
        const filter = galleries.filter(section => section.slug === SectionType.ourGallerySection);
        this.carousels = filter.media;
      }
    }
  }
};
</script>
