<template>
    <Header />
    <section class="spacing__x f-header banner ninja-text-section over-hidden">
        <img class="prop prop-top-left" src="@/assets/images/elements/element-2.png" alt="" />
        <img class="prop prop-bottom-left" src="@/assets/images/elements/element-5.png" alt="" />
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mb-5">
                        <h1 class="fs-60 heading-color-1">{{ this.ComponentName }}</h1>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-7">
                    <div class="mb-30">
                        <span class="color-secondary-2 fw-700 mb-10 d-inline-block">Unleash Your Imagination, Illuminate Your World</span>
                        <h2 class="mb-20 heading-color-1 fs-30">
                            Interactive Lightwall for Kids
                        </h2>
                        <p class="fs-17 regular-color-1">
                            Make your kids experience light in a whole new way. Our interactive light wall for kids is a one-of-a-kind masterpiece. This Kids Fun Hut exclusive attraction lets your child experience his surrounding in a truly
                            dynamic way. At Kids Fun Hut we are committed to deliver out of the box experiences for your child and Lightwall gives kids exactly what we say.
                        </p>
                    </div>
                    <h3 class="fs-20 heading-color-1 tt-capitalize fw-600 mb-30">
                        Key Features:
                    </h3>

                    <div class="row">
                        <div class="col-lg-6">
                            <div class="count-listing">
                                <p class="heading-color-1"><span>1</span> Touch-sensitive technology</p>
                                <p class="heading-color-1"><span>2</span> Customizable Color Schemes and Patterns</p>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="count-listing">
                                <p class="heading-color-1 align-items-start">
                                    <span>3</span>
                                    Endless Creativity
                                </p>
                                <p class="heading-color-1"><span>4</span> Future of Fun and Learning</p>
                            </div>
                        </div>
                    </div>
                    <p class="fs-17 regular-color-1 mb-30">
                        Let your kids step into the future with our artistic Light Wall that reacts to touch and movements. Watch as our state-of-the-art attraction blow your kids’ minds with stunning visuals and responsive displays.
                    </p>
                </div>
                <div class="col-lg-5">
                    <img class="right-side" src="@/assets/images/attractions/light-wall.png" alt="" />
                </div>
            </div>
            <div class="row">
                <div class="btn-list mb-20">
                    <router-link to="/shop/weekends" class="c-btn style-3">
                        Buy Weekend Tickets
                    </router-link>
                    <router-link to="/shop/weekdays" class="c-btn style-2">
                        Buy Weekday Tickets
                    </router-link>
                </div>
            </div>
        </div>
    </section>
    <footer />
</template>


<script>
import Header from "../layouts/Header.vue";
import Footer from "../layouts/Footer.vue";
export default {
    name: "InteractiveLightWall",
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            ComponentName: 'Interactive Light Wall'
        };
    },
    methods: {},
};
</script>
