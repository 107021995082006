import Cookies from 'js-cookie';
export default {
    namespaced: true,
    state: {
        membership: {},
        promotion: {},
    },
    getters: {
        membership(state) {
            const mycookie = Cookies.get('membership');
            if (Object.keys(state.membership).length == 0 && mycookie != undefined) {
                const json = mycookie.replace(/"-"/g, '","');
                const membership = JSON.parse(json);
                state.membership = membership;
            }
            return state.membership;
        },
        promotion(state) {
            const mycookie = Cookies.get('promotion');
            if (Object.keys(state.promotion).length == 0 && mycookie != undefined) {
                const json = mycookie.replace(/"-"/g, '","');
                const promotion = JSON.parse(json);
                state.promotion = promotion;
            }
            return state.promotion;
        }
    },
    actions: {
        setMembershipForCart({ commit }, _activity) {
            commit('SET_MEMBERSHIP', _activity);
        },
        unSetMembershipForCart({ commit }){
            commit('UNSET_MEMBERSHIP');
        },
        setPromotionForCart({ commit }, _activity) {
            commit('SET_Promotion', _activity);
        },
        unSetPromotionForCart({ commit }){
            commit('UNSET_Promotion');
        }
    },
    mutations: {
        SET_MEMBERSHIP(state, _membership) {
            state.membership = _membership;
            const json = JSON.stringify(state.membership);
            const mycookie = json.replace(/","/g, '"-"');
            Cookies.set('membership', mycookie);
        },
        UNSET_MEMBERSHIP(state){
            state.membership = {};
            Cookies.remove('membership');
        },
        SET_Promotion(state, _promotion) {
            state.promotion = _promotion;
            const json = JSON.stringify(state.promotion);
            const mycookie = json.replace(/","/g, '"-"');
            Cookies.set('promotion', mycookie);
        },
        UNSET_Promotion(state){
            state.promotion = {};
            Cookies.remove('promotion');
        }
    }
};
