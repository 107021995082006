<template>
    <Header></Header>
    <section class="spacing__x f-header ninja-text-section">
        <img class="element element-3" src="@/assets/images/elements/element-3.png" alt="" />
        <img class="element-2" src="@/assets/images/elements/element-2.png" alt="" />
        <img class="element-5" src="@/assets/images/elements/element-5.png" alt="" />
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mb-5">
                        <h1 class="fs-60 heading-color-1">Wall Climbing</h1>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-7">
                    <div class="mb-30">
                        <span class="color-secondary-2 fw-700 mb-10 d-inline-block">Reach New Heights</span>
                        <h2 class="mb-20 heading-color-1 fs-30">
                            Wall Climbing Zone
                        </h2>
                        <p class="fs-17 regular-color-1">
                            At Kids Fun Hut Wall Climbing Zone, we invite your little ones to reach new heights and discover the joy of conquering challenges through wall climbing. Our facility is designed specifically for kids, offering a
                            safe, thrilling, and confidence-boosting experience. Here's why Kids Fun Hut is the ultimate destination for young climbers.
                        </p>
                    </div>
                    <h3 class="fs-20 heading-color-1 tt-capitalize fw-600 mb-30">
                        What your kid's will love:
                    </h3>

                    <div class="row">
                        <div class="col-lg-6">
                            <div class="count-listing">
                                <p class="heading-color-1"><span>1</span>Customized for Kids</p>
                                <p class="heading-color-1"><span>2</span>Fun Learning Experience</p>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="count-listing">
                                <p class="heading-color-1 align-items-start">
                                    <span>3</span>
                                    Diverse Climbing Challenges
                                </p>
                                <p class="heading-color-1">
                                    <span>4</span>
                                    Safe and Supervised
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="btn-list mb-20">
                        <router-link to="/shop/weekends" class="c-btn style-3">
                            Buy Weekend Tickets
                        </router-link>
                        <router-link to="/shop/weekdays" class="c-btn style-2">
                            Buy Weekday Tickets
                        </router-link>
                    </div>
                    <p class="fs-17 regular-color-1 mb-30">
                        At Kids Fun Hut we don't just offer a climbing wall; it's a haven of adventure for your young explorers. Let your kids scale new heights, conquer challenges, and unleash their inner climbers in a space designed
                        exclusively for their boundless energy and enthusiasm.
                    </p>
                </div>
                <div class="col-lg-5">
                    <img class="right-side" src="@/assets/images/attractions/wall-climbing.png" alt="" />
                    <!-- <img class="right-side" src="@/assets/images/attractions/wall-climbing.jpg" alt=""> -->
                </div>
            </div>
        </div>
    </section>

    <section class="spacing__x showcase-ninja-section">
        <img class="element-4" src="@/assets/images/elements/element-4.png" alt="" />

        <div class="container">
            <div class="text-center">
                <div class="mb-20">
                    <h4 class="mb-20 heading-color-1 fs-30 tt-capitalize">
                        Why Wall Climbing?
                    </h4>
                    <p class="fs-17 regular-color-1">
                        Immerse your child in a world of adventure with our themed climbing walls. Develop agility and balance with our low-level bouldering challenges, perfect for young climbers. Explore the fusion of physical and digital with
                        our interactive climbing challenges that add a layer of excitement and learning.
                    </p>
                </div>
                <div class="mb-20">
                    <span class="color-secondary-2 fw-700 mb-10 d-inline-block tt-capitalize">
                        Kid-Centric Design
                    </span>
                    <p class="fs-17 regular-color-1 mb-20">
                        Our climbing walls are custom-designed for kids, featuring vibrant holds, whimsical themes, and a variety of routes to keep the excitement alive.
                    </p>
                </div>
                <div class="mb-20">
                    <span class="color-secondary-2 fw-700 mb-10 d-inline-block tt-capitalize">
                        Educational Fun
                    </span>
                    <p class="fs-17 regular-color-1 mb-20">
                        Climbing is more than just physical activity. It's a brain-boosting, confidence-building experience that promotes problem-solving and strategic thinking.
                    </p>
                </div>
                <div class="mb-20">
                    <span class="color-secondary-2 fw-700 mb-10 d-inline-block tt-capitalize">
                        Inclusive Challenges
                    </span>
                    <p class="fs-17 regular-color-1 mb-20">
                        We cater to all skill levels. From first-timers to seasoned climbers, there's a climbing challenge for every child.
                    </p>
                </div>
                <div class="mb-20">
                    <span class="color-secondary-2 fw-700 mb-10 d-inline-block tt-capitalize">
                        Healthy Bodies, Healthy Minds
                    </span>
                    <p class="fs-17 regular-color-1 text-left">
                        We believe that climbing isn't just about reaching the top—it's about the joy, courage, and friendships formed along the way. Book your climbing adventure today and let your child discover the thrill of conquering
                        heights in a space designed just for them!
                    </p>
                </div>
                <div class="mb-20">
                    <span class="color-secondary-2 fw-700 mb-10 d-inline-block tt-capitalize">
                        Expert Supervision
                    </span>
                    <p class="fs-17 regular-color-1 text-left">
                        Safety is paramount. Our experienced staff is dedicated to ensuring a secure climbing environment, offering guidance and support every step of the way.
                    </p>
                </div>
            </div>
        </div>
    </section>

    <Footer></Footer>
</template>

<script>
import Header from "../layouts/Header.vue";
import Footer from "../layouts/Footer.vue";
export default {
    name: "WallClimbing",
    components: {
        Header,
        Footer,
    },
};
</script>
