<template>
    <Header />
    <section class="spacing__x f-header cart-detail-section">
        <div class="container position-relative">
            <img class="element element-2"
                 src="@/assets/images/elements/element-2.png"
                 alt="" />
            <img class="element element-3"
                 src="@/assets/images/elements/element-3.png"
                 alt="" />
            <img class="element element-rocket"
                 src="@/assets/images/elements/rocket-element.png"
                 alt="" />
            <img class="element element-4-1"
                 src="@/assets/images/elements/element-4-1.png"
                 alt="" />
            <div class="d-flex justify-content-end align-items-center gap-10 mb-15">
                <h6 class="color-secondary-2 mb-0">
                    Cart
                    <i class="fas fa-shopping-cart"></i>
                    <span>{{ allAttractions().count }} Items</span>
                </h6>
                <a class="c-btn bg-color-tertiary br-10 text-shadow"
                   href="#">View</a>
                <router-link class="c-btn bg-color-tertiary br-10 text-shadow"
                             to="/shop/weekends">
                    Continue Shopping
                </router-link>
                <template v-if="!$store.getters['auth/isAuth']">
                    <hr class="vr" />
                    <router-link to="/login"
                                 class="color-1">
                        Login / Register
                    </router-link>
                </template>
            </div>
            <h1 class="fs-30 color-1 mb-10">CART DETAILS (ALL PRICES ARE IN CANADIAN DOLLARS)</h1>
            <div class="cart-table-wrap">
                <div class="cart-table-view">
                    <ul class="dtbl t-head">
                        <li class="dtd bg-color-secondary-3">
                            <h6 class="fs-20 mb-0">{{ currentDateTime().formattedDateTime }}</h6>
                        </li>
                        <li class="dtd wd-10 bg-color-secondary-3">
                            <h6 class="fs-20 mb-0">Price (CAD)</h6>
                        </li>
                        <li class="dtd wd-100 bg-color-secondary-3">
                            <h6 class="fs-20 mb-0">Discount (CAD)</h6>
                        </li>
                        <li class="dtd wd-330 bg-color-secondary-3 text-center">
                            <h6 class="fs-20 mb-0">Qty</h6>
                        </li>
                        <li class="dtd wd-110 bg-color-secondary-3">
                            <h6 class="fs-20 mb-0">Total</h6>
                        </li>
                        <li class="dtd wd-110 bg-color-secondary-3"></li>
                    </ul>
                    <ul class="dtbl"
                        v-for="product in allAttractions().attractions"
                        :key="product.id">
                        <li class="dtd text-left">
                            <p class="pad-left">{{ product.attractionName }}</p>
                        </li>
                        <li class="dtd wd-10">
                            <p class="color-secondary-2">${{ product.singlePrice }}</p>
                            <span style='font-size: 14px;'>
                                {{(product.attractionType === 'Party') ? `Total price of this product is ${product?.totalPayment}` : '' }}
                            </span>
                        </li>
                        <li class="dtd wd-100">
                            <p class="color-secondary-2"
                               v-if="product.discount.type == 'percentage'">
                                {{ product.discount.amount }}%
                            </p>
                            <p class="color-secondary-2"
                               v-else>
                                ${{ product.discount.amount }}
                            </p>
                        </li>
                        <li class="dtd wd-330">
                            <div class="quantity-list">
                                <a class="bg-color-tertiary text-shadow"
                                   @click="minusQuantity(product)"
                                   :class="{ 'not-allowed': plusMinusBtnValidation(membership.isApplied, product.attractionType) }">
                                    <i class="fas fa-minus"></i>
                                </a>
                                <p class="color-secondary-2">{{ product.quantity }}</p>
                                <a class="bg-color-primary text-shadow"
                                   @click="plusQuantity(product)"
                                   :class="{ 'not-allowed': plusMinusBtnValidation(membership.isApplied, product.attractionType) }">
                                    <i class="fas fa-plus"></i>
                                </a>
                            </div>
                        </li>
                        <li class="dtd wd-110">
                            <p>${{ findTotalPrice(product) }}</p>
                        </li>
                        <li class="dtd wd-110">
                            <a class="bg-color-primary text-shadow"
                               @click="() => this.$store.dispatch('cart/RemoveCart', product)">
                                <i class="fas fa-trash-alt"></i>
                            </a>
                        </li>
                    </ul>
                    <ul class="dtbl">
                        <li class="dtd text-right">
                            <p>Subtotal:</p>
                        </li>
                        <li class="dtd wd-220 bor-right-0"></li>
                        <li class="dtd wd-330"></li>
                        <li class="dtd wd-110">
                            <p>${{ this.calculation.subTotal.toFixed(2) }}</p>
                        </li>
                        <li class="dtd wd-110"></li>
                    </ul>
                    <ul class="dtbl">
                        <li class="dtd text-right">
                            <p>Tax:</p>
                        </li>
                        <li class="dtd wd-220 bor-right-0"></li>
                        <li class="dtd wd-330"></li>
                        <li class="dtd wd-110">
                            <p>$ {{ this.calculation.tax.toFixed(2) }}</p>
                        </li>
                        <li class="dtd wd-110"></li>
                    </ul>
                    <ul class="dtbl">
                        <li class="dtd text-right">
                            <p>Promo Discount:</p>
                        </li>
                        <li class="dtd wd-220 bor-right-0"></li>
                        <li class="dtd wd-330"></li>
                        <li class="dtd wd-110">
                            <p>$ {{ this.calculation.totalPromoDiscount.toFixed(2) }}</p>
                        </li>
                        <li class="dtd wd-110"></li>
                    </ul>
                    <ul class="dtbl">
                        <li class="dtd text-right">
                            <p>Total(CAD):</p>
                        </li>
                        <li class="dtd wd-220 bor-right-0"></li>
                        <li class="dtd wd-330"></li>
                        <li class="dtd wd-110">
                            <p>${{ this.finalPrice.toFixed(2) }}</p>
                        </li>
                        <li class="dtd wd-110"></li>
                    </ul>
                </div>
                <div class="text-right">
                    <div class="d-flex gap-15 justify-content-end align-items-center mb-20">
                        <input class="enter-code"
                               v-model="promo.code"
                               type="text"
                               placeholder="Enter Code"
                               :disabled="isPromoApplied ? true : false" />
                        <a class="c-btn bg-color-secondary-2 box-button-shadow br-10"
                           :disabled="!promoCodeValidation(promo.code, isPromoApplied)"
                           :class="{ 'not-allowed': !promoCodeValidation(promo.code, isPromoApplied) }"
                           @click="applyPromotionCode()">
                            Apply Promo-Code
                        </a>
                        <a class="c-btn bg-color-secondary-2 box-button-shadow br-10"
                           :disabled="membership.isApplied"
                           :class="{ 'not-allowed': membership.isApplied }"
                           v-if="membershipBtnVisible() == 'Have-Membership'"
                           @click="payByMembershipOnClick()">
                            Pay By Membership
                        </a>
                    </div>
                    <div class="bill-text">
                        <h6>Total: ${{ this.finalPrice.toFixed(2) }}</h6>
                        <h6>Paid: $0.00</h6>
                        <h6>Balance: ${{ this.finalPrice.toFixed(2) }}</h6>
                    </div>
                    <div class="btn-list justify-content-end">
                        <a class="c-btn bg-color-tertiary br-10 text-shadow"
                           @click="back">Back</a>
                        <a class="c-btn bg-color-tertiary br-10 text-shadow"
                           @click="pay()">
                            PAY ${{ this.finalPrice.toFixed(2) }}
                        </a>
                    </div>
                </div>
            </div>
            <div class="paymentResult"
                 v-html="gatewayResultMessage"></div>
        </div>
        <!-- Bootstrap Modal -->
        <div class="modal"
             :style="{ display: membership.showModal ? 'block' : 'none' }">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Membership Details</h5>
                        <button type="button"
                                class="close"
                                aria-label="Close"
                                @click="() => { membership.showModal = false }">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="container">
                            <div class="row">
                                <div class="col-4">
                                    <b>Title</b>
                                </div>
                                <div class="col-2 text-start text-center">
                                    <p><b>Attractions Covered</b></p>
                                </div>
                                <div class="col-2 text-center">
                                    <p><b>Attractions Not Covered</b></p>
                                </div>
                                <div class="col-2 text-center">
                                    <p><b>Remaining In Membership</b></p>
                                </div>
                                <div class="col-2 text-center">
                                    <p><b>Total Price</b></p>
                                </div>
                            </div>
                            <div class="row"
                                 v-for="detail in membership.details"
                                 :key="detail.id">
                                <div class="col-4">
                                    <p>{{ detail.title }}</p>
                                </div>
                                <div class="col-2">
                                    <p>{{ detail?.ticketCovered }}</p>
                                </div>
                                <div class="col-2">
                                    <p>{{ detail?.ticketNotCovered }}</p>
                                </div>
                                <div class="col-2">
                                    <p>{{ detail?.remainingInMembership }}</p>
                                </div>
                                <div class="col-2">
                                    <p>{{ detail?.priceToPay }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button"
                                class="btn btn-secondary"
                                @click="() => { membership.showModal = false }">Close</button>
                        <button type="button"
                                class="btn btn-primary"
                                @click="applyMembership()">Apply Membership</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
</template>
<script>
import Header from './layouts/Header.vue';
import Footer from './layouts/Footer.vue';
import { Promotions, Membership } from '../apiAgent';
import { AttractionType } from '../enums/index';
import {
    currentDateTime,
    calculateDiscountByType,
    back,
    handleSuccess,
    findTotalPrice,
    findActivityInCart,
    promoCodeValidation,
    allAttractions,
    plusMinusBtnValidation,
    generateTransactionId,
    handleError,
    setMembershipAndPromoDetailsGotFromPreviousSession,
    membershipBtnVisible
} from '../utility/helpers';
export default {
    name: 'CartDetail',
    components: {
        Header,
        Footer
    },
    mounted() {
        // Set HPP URL
        document.addEventListener('DOMContentLoaded', () => {
            RealexHpp.setHppUrl(process.env.VUE_APP_GLOBAL_PAY_API);
            RealexHpp.setMobileXSLowerBound(480);
        });
        this.setMembershipAndPromoDetailsGotFromPreviousSession();
    },
    data() {
        return {
            HST: 13, // NOTE: 13% tax on combine price.
            isPromoApplied: false,
            promo: {
                id: 0,
                code: '',
                amount: 0.00,
                type: ''
            },
            membership: {
                showModal: false,
                details: [
                    //   {
                    //     id,
                    //     title: 0,
                    //     ticketCovered: 0,
                    //     ticketNotCovered: 0
                    //     priceToPay: 0.0,
                    //     isAppliedOnTicket : false,
                    //     remainingInMembership : 0,
                    //   }
                ],
                membershipUserId: 0,
                isApplied: false,
            },
            gatewayResultMessage: '',
            isTransactionIdCreated: false,
            requestBodyCollection: []
        };
    },
    computed: {
        calculation() {//$store.getters['cart/Cart'] is the dependency of this computed property.
            let subTotal = 0.0;
            let totalProductDiscount = 0.00;
            let totalPromoDiscount = 0.00;
            let totalPriceAfterDiscount = 0.0;
            // Total Items In Cart.
            const attractionsInCart = this.allAttractions().attractions;//1st Dependency.
            for (const cart of attractionsInCart) {
                //This Part will cater the individual item discount.
                //It will reflect the total price of each item in cart.

                //Total Price of items
                subTotal += Number(cart.quantity) * parseFloat(cart.singlePrice);
                const productDiscountAmount = this.calculateDiscountByType(cart.discount.type, cart.discount.amount, cart.singlePrice);
                subTotal = subTotal - productDiscountAmount;
                totalProductDiscount += productDiscountAmount;
                const afterDiscountPrice = cart.singlePrice - productDiscountAmount;
                totalPriceAfterDiscount += parseFloat(afterDiscountPrice) * Number(cart.quantity);

                //This Part will cater the Membership.
                if (this.membership.isApplied) {//3rd Dependency.
                    const item = this.membership.details.find(x => x.id == cart.attractionId);
                    if (item.ticketCovered > 0) {
                        const coveredPrice = item.ticketCovered * afterDiscountPrice;
                        totalPriceAfterDiscount += coveredPrice;
                        subTotal = subTotal - coveredPrice;
                    }
                }
            }
            if (this.isPromoApplied) { //2nd Dependency.
                totalPromoDiscount = this.calculateDiscountByType(this.promo.type, this.promo.amount, totalPriceAfterDiscount);
                totalPriceAfterDiscount = totalPriceAfterDiscount - totalPromoDiscount;
            }
            const tax = totalPriceAfterDiscount * (this.HST / 100);
            return { totalPriceAfterDiscount, tax, totalProductDiscount, totalPromoDiscount, subTotal };
        },
        finalPrice() {
            const result = this.calculation.totalPriceAfterDiscount + this.calculation.tax;
            return result;
        }
    },
    methods: {
        currentDateTime,
        calculateDiscountByType,
        back,
        handleSuccess,
        findTotalPrice,
        findActivityInCart,
        promoCodeValidation,
        allAttractions,
        plusMinusBtnValidation,
        generateTransactionId,
        handleError,
        setMembershipAndPromoDetailsGotFromPreviousSession,
        membershipBtnVisible,
        pay() {
            const user = this.$store.getters['auth/userDetails'];
            const carts = this.$store.getters['cart/Cart'];
            //Is user authenticated.
            if (!this.$store.getters['auth/isAuth']) {
                this.$iziToast.info({
                    title: 'Info',
                    message: 'Please login or signup in order to checkout.'
                });
                return;
            }
            //Is cart empty
            if (carts.length === 0) {
                this.$iziToast.info({
                    title: 'Info',
                    message: 'There are no items in cart.'
                });
                return;
            }
            //Membership is either already purchased or not.
            if (carts.some(o => o.attractionType == AttractionType.Membership)) {
                if (user.active_membership && user.active_membership.length > 0) {
                    // const cartMembership = carts.find(o => o.attractionType == AttractionType.Membership);
                    // const isContains = user.active_membership.some(user => {
                    //     return cartMembership.attractions.some(cart => cart.attractionId === user.id);
                    // });
                    // if (isContains) {
                    // }
                    this.$iziToast.info({
                        title: 'Info',
                        message: 'The Membership is Already Purchased, Please remove it from Cart.'
                    });
                    return;
                }
            }
            this.$store.dispatch('loader/setLoader', true);
            for (const cart of carts) {
                let _meta = {};
                _meta = { ...cart.attractions[0].meta };
                if (this.isPromoApplied) {
                    _meta.promo_applied = 'yes';
                    _meta.promo_discount = `${this.calculation.totalPromoDiscount.toFixed(2)} $`;
                }
                //Party purchase.
                if (cart.attractionType == AttractionType.Party) {
                    for (const attraction of cart.attractions) {
                        const body = {
                            user_id: Number(user.id),
                            payment_status: "partialy-paid",
                            amount_paid: this.finalPrice,
                            total_amount: attraction.totalPayment,
                            tax: this.calculation.tax.toFixed(4),
                            tax_rate: this.HST,
                            subtotal: this.calculation.subTotal.toFixed(4),
                            promotion_ids: this.promo.id > 0 ? [this.promo.id] : [],
                            booking_date: cart.booking_date,
                            booking_time: cart.booking_time,
                            meta: _meta,
                            reservation_details: [{
                                activity_id: attraction.attractionId,
                                item_id: 0,
                                quantity: attraction.quantity,
                                unit_price: attraction.singlePrice,
                                meta: attraction.meta
                            }],
                            tag_slug : "book-party",
                            room_id : attraction.roomId
                        };
                        this.requestBodyCollection.push({
                            type: AttractionType.Party,
                            payload: body
                        });
                    }
                }
                //Membership purchase.
                else if (cart.attractionType == AttractionType.Membership) {
                    //Membership purchase.
                    const membership = cart.attractions[0];
                    const body = {
                        paid_amount: membership.singlePrice,
                        payment_status: 'Paid',
                        membership_id: membership.attractionId,
                        meta: _meta
                    };
                    this.requestBodyCollection.push({
                        type: AttractionType.Membership,
                        payload: body
                    });
                }
                //Ticket purchase.
                else if (cart.attractionType == AttractionType.Ticket) {
                    const reservationDetails = [];
                    const membershipTransaction = [];
                    if (this.membership.isApplied) {
                        for (const ticket of this.membership.details) {
                            if (ticket.isAppliedOnTicket) {
                                membershipTransaction.push({
                                    membership_user_id: this.membership.membershipUserId,
                                    activity_id: ticket.id,
                                    quantity: ticket.ticketCovered
                                });
                            }
                        }
                    }
                    for (const activity of cart.attractions) {
                        reservationDetails.push({
                            activity_id: activity.attractionId,
                            item_id: 0,
                            quantity: activity.quantity,
                            unit_price: activity.singlePrice,
                            meta: activity.meta
                        });
                    }
                    const body = {
                        user_id: Number(user.id),
                        payment_status: "paid",
                        amount_paid: this.finalPrice,
                        total_amount: this.finalPrice,
                        tax: this.calculation.tax.toFixed(4),
                        tax_rate: this.HST,
                        subtotal: this.calculation.subTotal.toFixed(4),
                        promotion_ids: this.promo.id > 0 ? [this.promo.id] : [],
                        booking_date: cart.booking_date,
                        booking_time: cart.booking_time,
                        meta: _meta,
                        reservation_details: reservationDetails,
                        membershipTransaction: membershipTransaction
                    };
                    this.requestBodyCollection.push({
                        type: AttractionType.Ticket,
                        payload: body
                    });
                }
                else { }
            }
            //Check User Role, if its Admin or final price is 0, then don't pay for this transaction.
            if (this.finalPrice === 0 || user.id === 1) {
                this.handleSuccess({ totalPrice: 0 }, this.requestBodyCollection);
                return;
            }
            // Fetch the HPP JSON from the server-side SDK.
            this.generateTransactionId();
        },
        minusQuantity(activity) {
            if (this.membership.isApplied) {
                return;
            }
            if (activity.attractionType == AttractionType.Ticket) {
                const value = activity.quantity;
                if (value > 0) {
                    activity.quantity = value - 1;
                    this.$store.dispatch('cart/UpdateCart', {
                        activity: activity,
                        type: activity.attractionType
                    });
                }
            }
        },
        plusQuantity(activity) {
            if (this.membership.isApplied) {
                return;
            }
            if (activity.attractionType == AttractionType.Ticket) {
                activity.quantity += 1;
                this.$store.dispatch('cart/UpdateCart', {
                    activity: activity,
                    type: activity.attractionType
                });
            }
        },
        applyPromotionCode() {
            if (this.$store.getters['auth/isAuth']) {
                if (this.promoCodeValidation(this.promo.code, this.isPromoApplied)) {
                    this.$store.dispatch('loader/setLoader', true);
                    Promotions.VerifyPromotionCode(this.promo.code)
                        .then(({ data }) => {
                            this.$iziToast.success({
                                title: 'Promo code applied!',
                                message: data.message
                            });
                            this.promo.id = data.promoDiscount.id;
                            this.promo.amount = data.promoDiscount.discount_amount;
                            this.promo.type = data.promoDiscount.discount_type;
                            this.isPromoApplied = true;
                            const _promo = {
                                id: this.promo.id,
                                code: this.promo.code,
                                amount: this.promo.amount,
                                type: this.promo.type,
                                isPromoApplied: this.isPromoApplied
                            };
                            this.$store.dispatch('promotionMembership/setPromotionForCart', _promo);
                            this.$store.dispatch('loader/setLoader', false);
                        })
                        .catch((error) => {
                            this.$store.dispatch('loader/setLoader', false);
                            this.$iziToast.error({
                                title: 'Error',
                                message: 'Invalid Promo Code.'
                            });
                            console.error(error);
                        });
                }
            }
            else {
                this.$router.push('/login');
            }
        },
        payByMembershipOnClick() {
            if (!this.membership.isApplied) {
                this.$store.dispatch('loader/setLoader', true);
                Membership.GetMembershipDetails()
                    .then(({ data }) => {
                        const userMembership = this.$store.getters['auth/userDetails'].active_membership[0];
                        const membershipDetail = data.data.find(o => o.id == userMembership.id);
                        this.membership.membershipUserId = membershipDetail.pivot.id;
                        const membership_activities = membershipDetail.membership_activities;
                        const tickets = this.$store.getters['cart/Cart'].find(o => o.attractionType == AttractionType.Ticket)?.attractions ?? [];
                        if (tickets.length > 0) { //Tickets in Cart are not empty.
                            const ticketVariedToMembership = [];
                            for (const ticket of tickets) {
                                const productDiscountAmount = this.calculateDiscountByType(ticket.discount.type, ticket.discount.amount, ticket.singlePrice);
                                const _singlePrice = ticket.singlePrice - productDiscountAmount;
                                const memebershipTicket = membership_activities.find(o => o.activity_id == ticket.attractionId);
                                if (memebershipTicket) {
                                    const askedQuantity = ticket.quantity;
                                    const availableQunatity = memebershipTicket.remaining;
                                    if (askedQuantity <= availableQunatity) {
                                        const _availableQunatity = availableQunatity - askedQuantity;
                                        ticketVariedToMembership.push({
                                            id: ticket.attractionId,
                                            title: ticket.attractionName,
                                            ticketCovered: askedQuantity,
                                            ticketNotCovered: 0,
                                            priceToPay: 0.0,
                                            isAppliedOnTicket: true,
                                            remainingInMembership: _availableQunatity > -1 ? _availableQunatity : 0
                                        });
                                        //note: ticket is in membership.
                                    } else {
                                        const differenceInQuantity = askedQuantity - availableQunatity;
                                        ticketVariedToMembership.push({
                                            id: ticket.attractionId,
                                            title: ticket.attractionName,
                                            ticketCovered: availableQunatity,
                                            ticketNotCovered: differenceInQuantity,
                                            priceToPay: _singlePrice * differenceInQuantity,
                                            isAppliedOnTicket: availableQunatity > 0 ? true : false,
                                            remainingInMembership: 0,
                                        });
                                        //note: ticket is in membership, but available qunatity exceeds.
                                    }
                                } else {
                                    ticketVariedToMembership.push({
                                        id: ticket.attractionId,
                                        title: ticket.attractionName,
                                        ticketCovered: 0,
                                        ticketNotCovered: ticket.quantity,
                                        priceToPay: _singlePrice * ticket.quantity,
                                        isAppliedOnTicket: false,
                                        remainingInMembership: 0,
                                    });
                                    //note: ticket is not in membership.
                                }
                            }
                            this.membership.showModal = true;
                            this.membership.details = [];
                            this.membership.details.push(...ticketVariedToMembership);
                        }
                        this.$store.dispatch('loader/setLoader', false);
                    })
                    .catch((error) => {
                        this.$store.dispatch('loader/setLoader', false);
                        console.error(error);
                    });
            }
        },
        applyMembership() {
            this.$store.dispatch('loader/setLoader', true);
            this.membership.showModal = false;
            this.membership.isApplied = true;
            const _membership = {
                showModal: this.membership.showModal,
                details: this.membership.details,
                membershipUserId: this.membership.membershipUserId,
                isApplied: this.membership.isApplied,
            };
            this.$iziToast.success({
                title: 'Membership applied!',
                message: data.message
            });
            this.$store.dispatch('promotionMembership/setMembershipForCart', _membership);
            setTimeout(() => {
                this.$store.dispatch("loader/setLoader", false);
            }, 1000);
        }
    }
};
</script>
<style scoped>
.modal h5,
p {
    color: black;
}

.modal .row div {
    border: 1px solid;
}
</style>
