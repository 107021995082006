<template>
    <Loader v-if="$store.getters['loader/isLoader']" />
    <router-view></router-view>
</template>
<script>
import Loader from './components/Helpers/Loader.vue';
export default {
    name: 'App',
    components: {
        Loader,
    }
}
</script>
