<template>
    <Header />
    <!-- CART SECTION START -->
    <section class="cartSection spacing__x-150 ">
        <img src="@/assets/images/tickets/cart-prop-2.png" class="element element-1" alt="">
        <img src="@/assets/images/tickets/cart-prop-4.png" class="element element-4" alt="">

        <div class="cartSectionMain spacing__x-150 bg-color-primary">
            <div class="container">
                <div class="tab__buttonsContainer cartButtonContainer row justify-content-between">
                    <div class="col-lg-3 col-sm-12 tab__button" v-for="(tag, index) in tags" :key="index">
                        <template v-if="tagsValidation(tag)">
                            <button class="activated cartTabButtons" @click="onTagClick(tag)"
                                :style="{ backgroundImage: `url(${getImgPath('tickets/cart-button-1.png')})` }">
                                <h6>{{ tag.name }}</h6>
                            </button>
                        </template>
                    </div>
                </div>
                <div class="tab__panelContainer">
                    <div class="tab__panel">
                        <div class="cartHeading">
                            <h4>{{ this.pageHeading }}</h4>
                        </div>
                        <div class="cartMain">
                            <div class="cartContainer row justify-content-between" v-for="(tagDetail, index) in tagDetails"
                                :key="index">
                                <div class="cartItemText col-lg-6 col-sm-12">
                                    <div class="cartTextProp">
                                        {{ tagDetail.title }}
                                    </div>
                                    <div class="text__wrap">
                                        <div class="d-flex gap-30  align-items-center">
                                            <h6>{{ tagDetail.title }}</h6>
                                            <span class="cartItemPrice"
                                                v-if="isShowDiscountPrice(tagDetail.discount_value || 0)">
                                                Price: <s>$ {{ tagDetail.price }}</s>
                                                $ {{ calculatePriceAfterDiscount(tagDetail) }}
                                            </span>
                                            <span class="cartItemPrice" v-else>
                                                Price: ${{ tagDetail.price }}
                                            </span>
                                        </div>
                                        <p>{{ tagDetail.description }}</p>
                                    </div>
                                </div>

                                <div class="cartItemButtons col-lg-4 col-sm-12">
                                    <template v-if="tagDetail?.meta?.bookable">
                                        <button class="c-btn style-4" @click="bookNow(tagDetail)">Book Now</button>
                                    </template>
                                    <template v-else>
                                        <div class="d-flex gap-15 justify-content-lg-end align-items-center" v-if="isShowQuantity()">
                                            <h6>Select Quantity: </h6>
                                            <select v-model="quantity[`${tagDetail.promotionId}_${tagDetail.id}`]"
                                                @change="onQuantityChange($event.target.value, `${tagDetail.promotionId}_${tagDetail.id}`, tagDetail.id)">
                                                <option v-for="i in 50" :value="i" :key="i">
                                                    {{ i }}
                                                </option>
                                            </select>
                                        </div>
                                        <template v-if="isAddedToCart(tagDetail.id)">
                                            <button class="c-btn style-4 flex-grow-0"
                                                style="max-width: 160px; display: block;background-color: green;">Added</button>
                                            <button class="c-btn style-4 flex-grow-0"
                                                style="max-width: 160px; display: block;" @click="viewCart()">View
                                                Cart</button>
                                        </template>
                                        <template v-else>
                                            <button class="c-btn style-4" @click="addToCart(tagDetail)">Add To Cart</button>
                                        </template>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
</template>

<script>
import { defineComponent } from 'vue';
import Header from './layouts/Header.vue';
import Footer from './layouts/Footer.vue';
import { Activities } from '../apiAgent';
import { AttractionType } from '../enums/index';
import { redirectScreen } from '../utility/helpers';
export default defineComponent({
    name: 'AttractionListing',
    components: {
        Header,
        Footer
    },
    data: () => ({
        quantity: {},
        tags: [],
        tagDetails: [],
        currentTagType: ''
    }),
    mounted() {
        this.getTags();
        //NOTE: Remove last party package details, that needed to render pizza and cake.
        this.$store.dispatch('bookingForm/unSetActivityForBooking');
        //Note: Remove data stored in cache, on cart detail scree to tackle refresh issue.
        this.$store.dispatch('promotionMembership/unSetMembershipForCart');
        this.$store.dispatch('promotionMembership/unSetPromotionForCart')
    },
    watch: {
        //NOTE: On route changes, repopulate the tagDetails.
        $route(to, from) {
            this.populateTagDetail(this.tags, this.$route.params.name);
        }
    },
    computed: {
        //NOTE: Passing 'this.$route.params.name' as dependencies.
        pageHeading() {
            const result = this.getRouteAttractionNameByParam(this.$route.params.name);
            return result.heading;
        }
    },
    methods: {
        redirectScreen,
        getImgPath(img) {
            return img ? require('@/assets/images/' + img) : '';
        },
        addToCart(activity) {
            const _type = this.currentTagType === AttractionType.Membership ? AttractionType.Membership : AttractionType.Ticket;
            const _quantity = this.currentTagType === AttractionType.Membership ? 1 : Number(this.quantity[`${activity.promotionId}_${activity.id}`]);
            const cartObject = {
                attractionType: _type,
                booking_date: '',
                booking_time: '',
                attractions: [{
                    attractionId: activity.id,
                    attractionName: activity.title,
                    singlePrice: Number(activity.price),
                    quantity: _quantity,
                    discount: {
                        amount: activity.discount_value ? parseFloat(activity.discount_value) : 0.00,
                        type: activity.discount_type
                    },
                    meta: {
                        booking_source: "Website",
                        party_reservation: {}
                    }
                }]
            };
            this.$store.dispatch('cart/SaveCart', cartObject);
            if (this.currentTagType === AttractionType.Membership)
                this.$router.push('/membership-detail');
        },
        onQuantityChange(value, id, activityId) {
            this.quantity[id] = value;
            const activity = this.findAttraction(activityId, AttractionType.Ticket);
            if (activity) {
                activity.quantity = Number(this.quantity[id]);
                this.$store.dispatch('cart/UpdateCart', {
                    activity: activity,
                    type: AttractionType.Ticket
                });
            }
        },
        viewCart() {
            this.$store.dispatch("loader/setLoader", true);
            this.redirectScreen();
            setTimeout(() => {
                this.$store.dispatch("loader/setLoader", false);
            }, 1000);
        },
        isAddedToCart(id) {
            const _type = this.currentTagType === AttractionType.Membership ? AttractionType.Membership : AttractionType.Ticket;
            const isAdded = this.findAttraction(id, _type) ? true : false;
            return isAdded;
        },
        getTags() {
            this.$store.dispatch('loader/setLoader', true);
            Activities.GetTags()
                .then(({ data }) => {
                    this.tags = data.data;
                    //NOTE: Set initial value for quantity dropdown.
                    for (const tag of this.tags) {
                        for (const activity of tag.active_activities) {
                            this.quantity[`${tag.id}_${activity.id}`] = '1';
                        }
                    }
                    //NOTE: Populating attractions, behalf of router param.
                    this.populateTagDetail(this.tags, this.$route.params.name);
                    this.$store.dispatch('loader/setLoader', false);
                })
                .catch((error) => {
                    this.$store.dispatch('loader/setLoader', false);
                    console.error(error);
                });
        },
        onTagClick(tag) {
            const result = this.getRouteAttractionNameByParam(tag.slug);
            result.route && this.$router.push(result.route);
        },
        getRouteAttractionNameByParam(_parameter) {
            let route;
            let heading;
            let parameter;
            let tagDetailAreIn;
            let tagType;
            switch (_parameter) {
                case 'weekends':
                    route = '/shop/weekends';
                    heading = "WEEKENDS TICKETS";
                    parameter = _parameter;
                    tagDetailAreIn = 'active_activities';
                    tagType = AttractionType.Ticket;
                    break;
                case 'weekdays':
                    route = '/shop/weekdays';
                    heading = "WEEKDAYS TICKETS";
                    parameter = _parameter;
                    tagDetailAreIn = 'active_activities';
                    tagType = AttractionType.Ticket;
                    break;
                case 'book-party':
                    route = '/shop/book-party';
                    heading = "PLEASE SELECT PARTY PACKAGE";
                    parameter = _parameter;
                    tagDetailAreIn = 'active_activities';
                    tagType = AttractionType.Party;
                    break;
                case 'memberships':
                    route = '/shop/memberships';
                    heading = "PLEASE SELECT MEMBERSHIP";
                    parameter = _parameter;
                    tagDetailAreIn = 'active_memberships';
                    tagType = AttractionType.Membership;
                    break;
                default:
                    route = null;
                    route = null;
                    parameter = null;
            }
            return { route, heading, parameter, tagDetailAreIn, tagType };
        },
        populateTagDetail(_tags, _router) {
            const result = this.getRouteAttractionNameByParam(_router);
            if (result.parameter) {
                this.heading = result.heading;
                this.tagDetails = [];
                const _tagDetail = _tags.find(x => x.slug === result.parameter);
                if (_tagDetail) {
                    this.currentTagType = result.tagType;
                    this.tagDetails = _tagDetail[result.tagDetailAreIn];
                    this.tagDetails.forEach((element) => {
                        element.promotionId = _tagDetail.id
                    });
                }
            }
        },
        bookNow(_activity) {
            this.$store.dispatch('bookingForm/setActivityForBooking', _activity);
            this.$router.push('/booking-form');
        },
        isShowDiscountPrice(discount) {
            if ((parseFloat(discount) == 0.00))
                return false;
            return true;
        },
        calculatePriceAfterDiscount(attraction) {
            const price = parseFloat(attraction.price);
            const discountValue = parseFloat(attraction.discount_value);
            const discountAmount = attraction.discount_type === 'percentage' ? price * (discountValue / 100) : discountValue;
            const afterDiscount = price - discountAmount;
            return afterDiscount.toFixed(2);
        },
        findAttraction(id, type) {
            const attractionByType = this.$store.getters['cart/Cart'].find(x => x.attractionType === type);
            if (attractionByType) {
                const activity = attractionByType.attractions.find((item) => item.attractionId == id);
                return activity;
            }
            return null;
        },
        tagsValidation(tag) {
            if (tag.slug === 'memberships') {
                const isEmpty = tag.active_memberships.length > 0 ? true : false;
                return isEmpty;
            } else {
                const isEmpty = tag.active_activities.length > 0 ? true : false;
                return isEmpty;
            }
        },
        isShowQuantity(){
            const isShow = this.currentTagType !== AttractionType.Membership ? true : false;
            return isShow;
        }
    }
});
</script>
