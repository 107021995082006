<template>
  <div class="container">
    <div class="row" v-if="showHeadingPanel">
      <div class="col-lg-12">
        <div class="wavier-signin-info br-0">
          <img class="element element-2"
               src="@/assets/images/elements/element-2.png">
          <img class="element element-3"
               src="@/assets/images/elements/element-3.png">
          <h4 class="fs-60 fw-700 tt-uppercase ls-3 heading-color-1">Signing A Waiver</h4>
          <span class="regular-color-2 ff-fredoka fs-30">Signing Person</span>
          <p class="regular-color-2 ff-fredoka fs-17">This person must be 18+ year old</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <form class="row fg-mb"
              action=""
              method="POST"
              ref="signingPersonForm">
          <div class="col-lg-6">
            <div class="form-group">
              <input type="text"
                     v-model="formData.first_name"
                     class="custom-input-style"
                     placeholder="First Name*"
                     required>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <input type="text"
                     v-model="formData.last_name"
                     class="custom-input-style"
                     placeholder="Last Name*"
                     required>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <input type="email"
                     v-model="formData.email"
                     class="custom-input-style"
                     placeholder="Email*"
                     required>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <input type="tel"
                     v-model="formData.phone"
                     class="custom-input-style"
                     placeholder="Phone*"
                     required>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="form-group d-flex align-items-center mb-0">
              <label class="fs-19 mb-0 w-100">Date Of Birth:*</label>

              <div class="d-flex mr-3 align-items-center w-100">
                <label class="mr-10">Date</label>
                <select v-model="formData.dob_day" class="form-control">
                    <option v-for="n in 31" :value="n">{{ n }}</option>
                </select>
              </div>
              <div class="d-flex align-items-center w-100">
                <label class="mr-10">Month</label>
                <select class="form-control" v-model="formData.dob_month">
                    <option v-for="month in months" :value="month">{{ month }}</option>
                </select>
              </div>
              <div class="d-flex mr-3 align-items-center w-100">
                <label class="mr-10">Year</label>
                <select v-model="formData.dob_year" class="form-control">
                    <option v-for="year in years" :value="year">{{ year }}</option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <WaiverUser v-for="(waiver, index) in childComponentsData"
        v-bind:key="index"
        :waiver="waiver"
        :index="index"
        @custom-event-name="createFormDataIndex"
        @remove-waiver="removeWaiver" 
  />
  <section class="spacing__x">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mb-5">
          <div class="text-center">
            <h3 class="fs-60 fw-600 heading-color-1 mb-4">All Adults, Children And Infants Are Required To Be Added In
              The Waiver.</h3>
            <a @click.prevent="replicateItem"
               class="c-btn style-3">Add Family Member</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <h5 class="regular-color-2 tt-uppercase ff-fredoka fw-600 d-inline-block bb-1 mb-5">Please Read Following
            Information And Click On Submit Button</h5>
        </div>
        <div class="col-lg-12">
          <h5 class="fw-500 tt-uppercase regular-color-1">Terms And Conditions</h5>
          <div class="waiver-terms-condition">
            <b>KIDS FUN HUT WAIVER</b>
            <br />
            <br />
            <b>PLEASE READ WITH FULL ATTENTION!</b>
            <br />
            <b>COVID-19 Waiver/ Disclaimer:</b>
            <p class="regular-color-1">
              I acknowledge the contagious nature of the Coronavirus/COVID-19 and that the Heath Canada and many other
              public health authorities still recommend practicing social distancing. I further acknowledge that
              Kids fun hut has put in place
              preventative measures to reduce the spread of the Coronavirus/COVID-19. I further acknowledge that
              Kids fun hut cannot guarantee that I will not become infected with the Coronavirus/Covid-19. I understand
              that the risk of becoming exposed
              to and/or infected by the Coronavirus/COVID-19 may result from the actions, omissions, or negligence of
              myself and others, including, but not limited to, Kids fun hut staff, and other Kids fun hut clients and
              their
              families. I voluntarily seek
              services provided by Kids fun hut and acknowledge that I am increasing my risk to exposure to the
              Coronavirus/COVID-19. I acknowledge that I must comply with all set procedures to reduce the spread while
              participating in Kids fun hut
              activities. I attest that: * I am not experiencing any symptom of illness such as cough, shortness of
              breath or difficulty breathing, fever, chills, repeated shaking with chills, muscle pain, headache, sore
              throat, or new loss of taste
              or smell. I hereby release and agree to hold Kids fun hut harmless from, and waive on behalf of myself, my
              heirs, and any personal representatives any and all causes of action, claims, demands, damages, costs,
              expenses and compensation for
              damage or loss to myself and/or property that may be caused by any act, or failure to act of Kids fun hut,
              or that may otherwise arise in any way in connection with any services received from Kids fun hut. I
              understand that this release
              discharges Kids fun hut from any liability or claim that I, my heirs, or any personal representatives may
              have against Kids fun hut with respect to any bodily injury, illness, death, medical treatment, or property
              damage that may arise from, or
              in connection to, any services received from Kids fun hut. This liability waiver and release extends to the
              Kids fun hut together with all owners, partners, associated companies, and employees.
            </p>
            <br />
            <p class="regular-color-1">Kids fun hut (and or any entity acting as or on behalf of Kids fun hut) is a
              parent/guardian supervised facility. Parents/guardians are strongly urged to keep their kids in their own
              sight and monitor their activities closely.</p>
            <p class="regular-color-1">
              Kids and parents/guardians are expected to observe the rules of the premises and rules of each activity,
              displayed at or around the respective activities. Everyone is urged to respect and accommodate the patrons
              around them to create a
              safe and fun environment while at the premises. Patrons failing to observe the rules will be requested to
              vacate the premises.
            </p>
            <p class="regular-color-1">
              Activities in the premises are physically demanding and potentially dangerous. Some of these activities
              can cause heart attack, dislocation of joints, broken bones, concussion, and even death. All patrons are
              requested to understand
              their own health and physical conditions and use the activities at their own risk. Kids fun hut (and or any
              entity acting as or on behalf of Kids fun hut) will not and can not be held responsible for any physical,
              mental, or emotional injury or
              death; while using or due to the usages of any of its activities.
            </p>
            <p class="regular-color-1">
              Kids fun hut (and or any entity acting as or on behalf of Kids fun hut) facility is monitored by
              surveillance
              devices. This surveillance is for monitoring purpose only and can only be shared with Law Enforcing
              Authorities, should there ever be
              a need for one.
            </p>
            <p class="regular-color-1">
              Patrons are welcomed to take pictures of their families and participating guests while at the premises.
              Everyone is requested to make reasonable efforts to avoid including any person who is not part of their
              group or a willing
              participant.
            </p>
            <p class="regular-color-1">
              By accepting and/or signing this Waiver, I agree to GIVE UP MY RIGHT TO SUE Kids fun hut (and or any entity
              acting as or on behalf of Kids fun hut) for any damage, expense, physical or emotional injury, paralysis, or
              death that I or my family
              or estate may suffer as a result of my participation in Kids fun hut (and or any entity acting as or on
              behalf of Kids fun hut) due to any cause whatsoever, including Kids fun hut (and or any entity acting as or
              on
              behalf of Kids fun hut)'s negligence,
              failure to warn or protect me from risks, breach of contract, breach of any other duty of care, or breach
              of Occupiers' Liability Act, R.S.O 1990, c. O.2. I agree to waive any and all rights that I have or may
              have in the future against
              Kids fun hut (and or any entity acting as or on behalf of Kids fun hut) and release Kids fun hut (and or any
              entity acting as or on behalf of Kids fun hut) for any damage, expense, injury, or death
            </p>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="bg-color-4">
            <form class="row">
              <div class="col-lg-12">
                <div class="c-check-box form-group">
                  <input type="checkbox"
                         v-model="checkbox1"
                         id="wcondition1"
                         name="wcondition1"
                         value="Bike">
                  <label for="wcondition1">I would like to subscribe to the monthly newsletter to be emailed about
                    upcoming events and promotions!</label>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="c-check-box form-group">
                  <input type="checkbox"
                         id="wcondition2"
                         v-model="checkbox2"
                         name="wcondition2"
                         value="Car">
                  <label for="wcondition2">I, AGREE TO THE TERMS AND DECLARE THAT ALL PERSONAL INFORMATION ARE
                    CORRECT</label><br>
                </div>
              </div>
              <div class="col-lg-10">
                <div class="row align-items-center">
                  <div class="col-lg-1">
                    <label class="fs-20 mb-0">Email:</label>
                  </div>
                  <div class="col-lg-11">
                    <div class="form-group">
                      <input type="text"
                             class="custom-input-style h-60"
                             placeholder="Email*"
                             v-model="formData.email"
                             required>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="bg-color-4"
               v-if="!$store.getters['auth/isAuth']">
            <h4 class="fw-600 heading-color-1 mb-4 text-center">Please enter a password to access/update your waiver in
              future</h4>
            <form class="row justify-content-center"
                  action=""
                  method="POST"
                  ref="passwordForm">
              <div class="col-lg-10">
                <div class="form-group position-relative">
                  <input :type="showPassword ? 'text' : 'password'"
                         class="custom-input-style h-60"
                         v-model="formData.password"
                         placeholder="Password*"
                         required />
                  <div class="show-password">
                    <button class="button"
                            @click="toggleShow($event, 'password')">
                      <span class="icon is-small is-right">
                        <i class="fas"
                           :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-10">
                <div class="form-group position-relative">
                  <input :type="showConfirmPassword ? 'text' : 'password'"
                         class="custom-input-style h-60"
                         v-model="formData.confirmpassword"
                         placeholder="Re-enter Password*"
                         required />
                  <div class="show-password">
                    <button class="button"
                            @click="toggleShow($event, 'confirmPassword')">
                      <span class="icon is-small is-right">
                        <i class="fas"
                           :class="{ 'fa-eye-slash': showConfirmPassword, 'fa-eye': !showConfirmPassword }"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="text-center">
            <div class="d-inline-block">
              <button type="submit"
                      class="c-btn style-3"
                      @click="handleSubmit()">Submit Waver</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { WaiverDetail } from '../../apiAgent';
import WaiverUser from './WaiverUser.vue';
import { _months, _years } from '../../utility/constants';
import _ from 'lodash';
import { validPhoneNo } from '../../utility/Validator';
export default {
  name: 'WaiverDetail',
  props: {
      showHeadingPanel: {
          type: Boolean,
          required: true
      }
  },
  components: {
    WaiverUser
  },
  data() {
    return {
      checkbox1: false,
      checkbox2: false,
      childComponentsData: [],
      waiver: [],
      formData: {
        first_name: '',
        last_name: '',
        email: localStorage.getItem('email'),
        phone: '',
        dob_day: '',
        dob_year: '',
        dob_month: '',
        password: '',
        confirmpassword: '',
        waivers: [],
      },
      showPassword: false,
      showConfirmPassword: false,
      months: _months,
      years: _years
    }
  },
  mounted() {
    this.getWaivers();
  },
  methods: {
    getWaivers() {
      this.$store.dispatch('loader/setLoader', true);
      WaiverDetail.GetWaiver()
        .then(response => {
          const parts = response.data.user.name.split(' ');
          const firstPart = parts[0];
          const secondPart = parts.slice(1).join(' ');
          this.formData.first_name = firstPart;
          this.formData.last_name = secondPart;
          this.formData.email = response.data.user.email;
          this.formData.phone = response.data.user.phone_number;
          this.formData.dob_day = response.data.user.dob_day;
          this.formData.dob_year = response.data.user.dob_year;
          this.formData.dob_month = response.data.user.dob_month;
          this.childComponentsData = response.data.user.waivers;
          // localStorage.setItem('waivers',JSON.stringify(response.data.user.waivers));
          this.$store.dispatch('loader/setLoader', false);
        })
        .catch(error => {
          this.$store.dispatch('loader/setLoader', false);
          console.error(error);
        });
    },
    replicateItem() {
      // const newItem = `Item ${this.childComponentsData.length + 1}`;
      let newWaiver = {
        waiver_first_name: '',
        waiver_last_name: '',
        dob_day: '',
        waiver_dob_year: '',
        waiver_dob_month: ''
      };
      this.childComponentsData.push(newWaiver);
    },
    createFormDataIndex(obj) {
      this.childComponentsData.push(obj);
    },
    removeWaiver(waiver) {
      // Find the index of the waiver to remove
      const index = this.childComponentsData.indexOf(waiver);
      if (index !== -1) {
        // Remove the waiver from the array
        this.childComponentsData.splice(index, 1);
        if(waiver.id){
        this.$store.dispatch('loader/setLoader', true);
        WaiverDetail.DeleteWaiver(waiver.id)
          .then((response) => {
            this.$store.dispatch('loader/setLoader', false);
            // Handle the successful deletion or update the UI accordingly.
            this.$iziToast.success({
              title: 'Success',
              message: response.data.message,
            });
          })
          .catch((error) => {
            this.$store.dispatch('loader/setLoader', false);
            // Handle errors or display error messages.
            this.$iziToast.error({
              title: 'Something went wrong',
              message: error,
            });
            console.error('Error deleting waiver', error);
          });
        }
      }
    },
    handleSubmit() {
      const form = this.$refs.signingPersonForm;
      if (!form.checkValidity()) {
        this.$iziToast.error({
          title: 'Error',
          message: 'Please fill the form with correct information!',
        });
        return;
      }
      if (parseInt(this.formData.dob_year) > 2005) {
        this.$iziToast.error({
          title: 'Error',
          message: 'User Must Be Of 18 Years Of Age',
        });
        return;
      }
      let self = this;
      this.formData.waivers = this.childComponentsData
      for (const item of this.formData.waivers) {
        if (item.waiver_first_name === '') {
          self.$iziToast.error({
            title: 'Error',
            message: 'Please Enter First Name',
          });
          return; // Exit the entire function when validation fails
        }
        if (item.waiver_last_name === '') {
          self.$iziToast.error({
            title: 'Error',
            message: 'Please Enter Last Name',
          });
          return; // Exit the entire function when validation fails
        }
        // if (parseInt(item.waiver_dob_year) > 2005 || item.waiver_dob_year === '' || item.waiver_dob_month === '') {
        //   self.$iziToast.error({
        //     title: 'Error',
        //     message: 'User Must Be Of 18 Years Of Age',
        //   });
        //   return; // Exit the entire function when validation fails
        // }

        if (item.waiver_dob_year === '' || item.waiver_dob_year === '' || item.waiver_dob_month === '') {
          self.$iziToast.error({
            title: 'Error',
            message: 'Please Enter Valid Date of Birth',
          });
          return; // Exit the entire function when validation fails
        }
      }
      if (this.checkbox2 == false) {
        this.$iziToast.error({
          title: 'Error',
          message: 'Please Accept Terms And Conditions',
        });
        return;
      }
      const passwordForm = this.$refs.passwordForm;
      if (passwordForm && !passwordForm.checkValidity()) {
        this.$iziToast.error({
          title: 'Error',
          message: 'Please fill in the required password fields!',
        });
        return;
      }
      if (!validPhoneNo(this.formData.phone)) {
        this.$iziToast.error({
          title: 'Error',
          message: 'Invalid Phone Number. Valid phone number only contain Number.',
        });
        return;
      }
      if ((!this.$store.getters['auth/isAuth']) && this.formData.password == '' && this.formData.confirmpassword == '') {
        this.$iziToast.error({
          title: 'Error',
          message: 'Please Enter Password',
        });
        return;
      }
      if ((!this.$store.getters['auth/isAuth']) && (this.formData.password != this.formData.confirmpassword)) {
        this.$iziToast.error({
          title: 'Error',
          message: 'Confirm password should match the password field',
        });
        return;
      }
      if ((!this.$store.getters['auth/isAuth']) && (this.formData.password.length < 5)) {
        this.$iziToast.error({
          title: 'Error',
          message: 'Password should be alteast of Five characters',
        });
        return;
      }
      this.$store.dispatch('loader/setLoader', true);
      WaiverDetail.SignWaiver(this.formData)
        .then(response => {
          this.$store.dispatch('loader/setLoader', false);
          this.$iziToast.success({
            title: 'Success',
            message: response.data.message,
          });
          const userDetails = this.removeFirstLastNameInUserObject({ ...this.$store.getters['auth/userDetails'], ...this.formData }, response.data.user.waivers);
          this.$store.dispatch('auth/updateUser', userDetails);
          this.$router.push('/thankyou');
        })
        .catch(error => {
          this.$store.dispatch('loader/setLoader', false);
          console.error(error);
          this.$iziToast.error({
            title: 'Error',
            message: error.response.data.message,
          });
          localStorage.clear();
        });
    },
    toggleShow(event, arg) {
      event.preventDefault();
      if (arg === 'password')
        this.showPassword = !this.showPassword;
      else
        this.showConfirmPassword = !this.showConfirmPassword;
    },
    removeFirstLastNameInUserObject(userObject, _waivers) {
      let object = _.cloneDeep(userObject);
      object.name = userObject.first_name + ' ' + userObject.last_name;
      delete object.waivers;
      object.waivers = _waivers;
      delete object.first_name;
      delete object.last_name;
      return object;
    }
  }
}
</script>