<template>
    <Carousel :wrap-around="false"
              :autoplay="3000">
        <Slide v-for="carousel in carousels"
               :key="carousel.id">
            <template v-if="carousel.media.length > 0">
                <div class="item promotion-slide promotionMain"
                     :style="{ 'background-image': 'url(' +  getImgPath(carousel.media[0].path) + ')' }">
                </div>
            </template>
            <template v-else>
                <div class="item promotion-slide pslide-1 promotionMain">
                    <div class="promotionCards">
                        <h4>
                            {{ carousel.title }}
                        </h4>
                        <p>{{ carousel.description }}</p>
                    </div>
                </div>
            </template>
        </Slide>
        <template #addons>
            <!-- <Navigation /> -->
            <Pagination />
        </template>
    </Carousel>
</template>
<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
export default {
    name: 'PromotionCarousel',
    props: {
        carousels: {
            type: Array,
            required: true,
        }
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation
    },
    data() {
        return {
            // carousel settings
            settings: {
                fade: 1,
                itemsToShow: 1,
                snapAlign: 'center',
            },
            // breakpoints are mobile first
            // any settings not specified will fallback to the carousel settings
            breakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 1,
                    snapAlign: 'start',
                },
            },
            baseUrl: `${process.env.VUE_APP_FORGOT_PASSWORD_LINK}/`,
        }
    },
    methods: {
        getImgPath(img) {
            return `${this.baseUrl}/${img}`;
        }
    }
};
</script>
<style>
.carousel__pagination-button::after {
    background-color: yellow;
}
</style>
