<template>
    <Header></Header>
    <section class="spacing__x f-header">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="wavier-signin-info br-0">
                        <img class="element element-2"
                             src="@/assets/images/elements/element-2.png" />
                        <img class="element element-3"
                             src="@/assets/images/elements/element-3.png" />
                        <h4 class="fs-60 fw-700 tt-uppercase ls-3 heading-color-1">Registers</h4>
                        <p class="regular-color-2 ff-fredoka fs-17">
                            PLEASE FILL OUT ALL THE FIELDS TO COMPLETE REGISTRATION.
                        </p>
                    </div>
                </div>
            </div>

            <div class="row fg-mb">
                <div class="col-lg-6">
                    <div class="form-group">
                        <input type="text"
                               class="custom-input-style"
                               v-model="formData.first_name"
                               placeholder="First Name*"
                               @blur="v$.formData.first_name.$touch" />
                    </div>

                    <div class="input-errors"
                         v-for="(error, index) of v$.formData.first_name.$errors"
                         :key="index">
                        <div class="error-msg">{{ error.$message }}</div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <input type="text"
                               class="custom-input-style"
                               v-model="formData.last_name"
                               placeholder="Last Name*"
                               @blur="v$.formData.last_name.$touch" />
                    </div>

                    <div class="input-errors"
                         v-for="(error, index) of v$.formData.last_name.$errors"
                         :key="index">
                        <div class="error-msg">{{ error.$message }}</div>
                    </div>

                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <input type="tel"
                               class="custom-input-style"
                               v-model="formData.phone"
                               placeholder="Phone*"
                               @blur="v$.formData.phone.$touch" />
                    </div>

                    <div class="input-errors"
                         v-for="(error, index) of v$.formData.phone.$errors"
                         :key="index">
                        <div class="error-msg">{{ error.$message }}</div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <input type="email"
                               class="custom-input-style"
                               v-model="formData.email"
                               placeholder="Email*"
                               @blur="v$.formData.email.$touch" />
                    </div>

                    <div class="input-errors"
                         v-for="(error, index) of v$.formData.email.$errors"
                         :key="index">
                        <div class="error-msg">{{ error.$message }}</div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group position-relative">
                        <input :type="showPassword ? 'text' : 'password'"
                               class="custom-input-style"
                               v-model="formData.password"
                               placeholder="Password*"
                               @blur="v$.formData.password.$touch" />
                        <div class="show-password">
                            <button class="button"
                                    @click="toggleShow($event, 'password')">
                                <span class="icon is-small is-right">
                                    <i class="fas"
                                       :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                                </span>
                            </button>
                        </div>
                    </div>

                    <div class="input-errors"
                         v-for="(error, index) of v$.formData.password.$errors"
                         :key="index">
                        <div class="error-msg">{{ error.$message }}</div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group position-relative">
                        <input :type="showConfirmPassword ? 'text' : 'password'"
                               class="custom-input-style"
                               v-model="formData.confirmpassword"
                               placeholder="Re-enter Password*"
                               @blur="v$.formData.confirmpassword.$touch" />
                        <div class="show-password">
                            <button class="button"
                                    @click="toggleShow($event, 'confirmPassword')">
                                <span class="icon is-small is-right">
                                    <i class="fas"
                                       :class="{ 'fa-eye-slash': showConfirmPassword, 'fa-eye': !showConfirmPassword }"></i>
                                </span>
                            </button>
                        </div>
                    </div>

                    <div class="input-errors"
                         v-for="(error, index) of v$.formData.confirmpassword.$errors"
                         :key="index">
                        <div class="error-msg">{{ error.$message }}</div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="form-group text-center">
                        <ul class="inline-flex justify-content-center">
                            <li>
                                <button @click="handleSubmit"
                                        :disabled="v$.formData.$invalid"
                                        class="c-btn bg-color-secondary"
                                        :class="{ 'not-allowed': v$.formData.$invalid }">
                                    Register Now
                                </button>
                            </li>
                            <li><button @click="login"
                                        class="c-btn style-2">Already Registred - Login</button></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer></Footer>
</template>
<script>
import Header from './layouts/Header.vue';
import Footer from './layouts/Footer.vue';
import { Register, Login } from '../apiAgent';
import useVuelidate from '@vuelidate/core';
import { required, email, minLength, sameAs } from '@vuelidate/validators';
import { validName, validPhoneNo } from '../utility/Validator';
import { routes } from '../../router';
export default {
    name: 'Register',
    components: {
        Header,
        Footer
    },
    setup() {
        return { v$: useVuelidate() };
    },
    mounted() {
        this.$store.dispatch('auth/logout');
    },
    data() {
        return {
            formData: {
                first_name: '',
                last_name: '',
                phone: '',
                email: '',
                password: '',
                confirmpassword: ''
            },
            showPassword: false,
            showConfirmPassword: false
        };
    },
    methods: {
        handleSubmit() {
            this.v$.$validate();
            if (!this.v$.$error) {
                this.$store.dispatch('loader/setLoader', true);
                Register(this.formData)
                    .then((registerResponse) => {
                        Login(this.formData).then(loginResponse => {
                            this.$store.dispatch('loader/setLoader', false);
                            this.$store.dispatch('auth/login', {
                                token: loginResponse.data.authorization.token,
                                user: loginResponse.data.user
                            });
                            this.$iziToast.success({
                                title: 'Success',
                                message: registerResponse.data.message
                            });
                           this.redirectScreen();
                        }).catch((error) => {
                            this.$store.dispatch('loader/setLoader', false);
                            console.error(error);
                            this.$iziToast.error({
                                title: 'Error',
                                message: error.response.data.message,
                            });
                        });
                    })
                    .catch((error) => {
                        this.$store.dispatch('loader/setLoader', false);
                        console.error(error);
                        this.$iziToast.error({
                            title: 'Error',
                            message: error.response.data.message
                        });
                    });
            }
        },
        login() {
            this.$router.replace('/login');
        },
        toggleShow(event, arg) {
            event.preventDefault();
            if (arg === 'password')
                this.showPassword = !this.showPassword;
            else
                this.showConfirmPassword = !this.showConfirmPassword;
        },
        redirectScreen() {
            const previousRoute = this.$router.options.history.state.back;
            const components = ['CartDetail', 'MembershipForm'];
            for (const component of components) {
                const path = routes.find(o => o.name == component).path;
                if (previousRoute == path) {
                    this.$router.push(path);
                    return;
                }
            }
            this.$router.push('/waiver-detail');
        },
    },
    validations() {
        return {
            formData: {
                first_name: {
                    required,
                    name_validation: {
                        $validator: validName,
                        $message: 'Invalid Name. Valid name only contain letters, dashes (-) and spaces.'
                    }
                },
                last_name: {
                    required,
                    name_validation: {
                        $validator: validName,
                        $message: 'Invalid Name. Valid name only contain letters, dashes (-) and spaces.'
                    }
                },
                phone: {
                    required,
                    phone_validation: {
                        $validator: validPhoneNo,
                        $message: 'Invalid Phone Number. Valid phone number only contain Number.'
                    }
                },
                email: {
                    required,
                    email
                },
                password: {
                    required,
                    min: minLength(5)
                },
                confirmpassword: {
                    required, sameAsPassword: sameAs(this.formData.password, 'Password')
                }
            }
        };
    }
};
</script>

<style>
.error-msg {
    color: red;
}

.not-allowed {
    cursor: not-allowed !important;
}
</style>
