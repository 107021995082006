<template>
    <Header></Header>
    <section class="spacing__x f-header">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="wavier-signin-info br-0">
                            <img class="element element-2" src="@/assets/images/elements/element-2.png">
                            <img class="element element-3" src="@/assets/images/elements/element-3.png">
                            <h4 class="fs-60 fw-700 tt-uppercase ls-3 heading-color-1">Signing A Waiver</h4>
                            <p class="regular-color-2 tt-uppercase ff-fredoka">Signing Person Email (Must Be 18+ Year Old)</p>
                        </div>
                    </div>
                </div>
                <form class="row fg-mb align-items-center justify-content-center" v-on:submit="signinWaiver($event)">
                    <div class="col-lg-1">
                        <div class="form-group">
                            <label class="fs-20 mb-0">Email:</label>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <input type="email" class="custom-input-style" v-model="formData.email" placeholder="Email*" required>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <button type="submit" class="c-btn style-2 tt-uppercase fw-500">Next</button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    <Footer></Footer>
  </template>
  
  <script>
  import Header from './layouts/Header.vue'
  import Footer from './layouts/Footer.vue'
  import { CheckUser } from '../apiAgent';
  export default {
    name: 'SignIn',
    components: {
      Header,
      Footer
    },
    data() {
    return {
        formData:{
            email: '',
        },    
    };
  },
  methods:{
    signinWaiver(event){

      event.preventDefault();
      if (!event.target.checkValidity()) {
        // If the form is invalid, prevent the default submission behavior
        alert('Please fill in all required fields.'); // You can customize the error message
      } else {
        // If the form is valid, you can proceed with your submission logic here
        this.$store.dispatch('loader/setLoader', true);
        CheckUser(this.formData)
            .then(response => {
              this.$store.dispatch('loader/setLoader', false);
              if(response.data.user==null){
                localStorage.setItem('email',this.formData.email);
                this.$router.push('/waiver-detail');
              }
              else{
                this.$iziToast.error({
                  title: 'Error',
                  message: 'User Already Exists. Please Login.',
                });
                this.$router.push('/login');
              }
            })
            .catch(error=>{
              this.$store.dispatch('loader/setLoader', false);
              console.error(error);
            })
      }

    //     this.$iziToast.success({
    //     title: 'Success',
    //     message: response.data.message,
    //   });
    //     this.$router.push('/login');
    //   })
    //   .catch(error => {
    //     console.log(error);
    //     this.$iziToast.error({
    //     title: 'Error',
    //     message: error.response.data.message,
    //   });
    }
        //localStorage.setItem('email',this.email);
    }
  }

  </script>