<template>
    <div class="container">
        <template v-if="!isUserSignedWaiver && !showWaiverForm">
            <div class="row">
                <div class="col-12">
                    <h1 style="color: black">THERE IS NO WAIVER SIGNED BY YOU, PLEASE CLICK FOLLOWING BUTTON TO SIGN NOW.
                        ALL PARTICIPANTS (ADULT, CHILDREN AND INFANTS) NEED A WAIVER
                    </h1>
                </div>
                <div class="col-12">
                    <button class="c-btn style-1 bg-color-secondary" @click="signWavierBtnClick($event)">SIGN A NEW
                        WAIVER</button>
                </div>
            </div>
        </template>

        <template v-if="isUserSignedWaiver && !showWaiverForm">
            <div class="waiver-detail">
                <div class="row">
                    <div class="col-12">
                        <div class="heading">
                            <h1>Current Waiver</h1>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Sign Date:&nbsp;</label>
                            <p>{{ waiverDetail.signDate }}</p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Ref#:&nbsp;</label>
                            <p>{{ waiverDetail.ref }}</p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Name:&nbsp;</label>
                            <p>{{ waiverDetail.name }}</p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Phone:&nbsp;</label>
                            <p>{{ waiverDetail.phone }}</p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Email:&nbsp;</label>
                            <p>{{ waiverDetail.email }}</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="row">
                <div class="col-lg-12">
                    <div class="heading">
                        <h1>Members</h1>
                    </div>
                </div>
            </div>
            <div class="row waiverTable">
                <div class="col-4">
                    <h5>First Name</h5>
                </div>
                <div class="col-4">
                    <h5>Last Name</h5>
                </div>
                <div class="col-4">
                    <h5>Birth Year</h5>
                </div>
            </div>

            <div class="row waiverTable" v-for="member in waiverMembers" :key="member.id">
                <div class="col-4">
                    <p>{{ member.firstName }}</p>
                </div>
                <div class="col-4">
                    <p>{{ member.secondName }}</p>
                </div>
                <div class="col-4">
                    <p>{{ member.birthYear }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p>If above information are incorrect or changed please sign a new waiver</p>
                </div>
                <div class="col-4">
                    <button class="c-btn style-1 bg-color-secondary" @click="signWavierBtnClick($event)">Update
                        Waiver</button>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <h1>Previously Signed Waiver</h1>
                </div>
            </div>
            <div class="row waiverTable">
                <div class="col-4">
                    <h5>Waiver Ref#</h5>
                </div>
                <div class="col-4">
                    <h5>Sign Date/Time</h5>
                </div>
                <div class="col-4">
                    <h5>Download</h5>
                </div>
            </div>
            <div class="row waiverTable" v-for="waiver in previouslySignedWaivers" :key="waiver.id">
                <div class="col-4">
                    <p>{{ waiver.waiverRef }}</p>
                </div>
                <div class="col-4">
                    <p>{{ waiver.signDate }}</p>
                </div>
                <div class="col-4">
                    <button class="c-btn style-1 bg-color-primary"
                        @click="downloadPreviosWaiver(waiver.id)">Download</button>
                </div>
            </div>
        </template>
    </div>
    <WaiverDetail :showHeadingPanel="false" v-if="showWaiverForm" />
</template>

<script>
import WaiverDetail from '../waiver-detail/WaiverDetail.vue';
import { WaiverDetail as WaiverDetailApi } from '../../apiAgent';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';

export default {
    name: "MyWaiver",
    mounted() {
        this.getWaivers();
    },
    components: {
        WaiverDetail
    },
    data() {
        return {
            showWaiverForm: false,
            isUserSignedWaiver: true,
            waiverMembers: [
                // { id: 0, firstName: '', secondName: '', birthYear: '' }
            ],
            previouslySignedWaivers: [
                // { id: 0, waiverRef: '', signDate: '' },
            ],
            waiverDetail: {
                signDate: '',
                ref: '',
                name: '',
                phone: '',
                email: ''
            }
        };
    },
    methods: {
        signWavierBtnClick(event) {
            event.preventDefault();
            this.showWaiverForm = true;
        },
        downloadPreviosWaiver(waiverId) {
            const doc = new jsPDF();
            const headerContent = ` 
            KIDS FUN HUT WAIVER\n
            PLEASE READ WITH FULL ATTENTION!\n
            COVID-19 Waiver/ Disclaimer:\n`;
            doc.setFontSize(14);
            doc.text(headerContent, 10, 10);

            const content = `
            I acknowledge the contagious nature of the Coronavirus/COVID-19 and that the Heath Canada 
            and many other public health authorities still recommend practicing social distancing. 
            I further acknowledge that Kids fun hut has put in place preventative measures to reduce 
            the spread of the Coronavirus/COVID-19. I further acknowledge that Kids fun hut cannot 
            guarantee that I will not become infected with the Coronavirus/Covid-19. I understand 
            that the risk of becoming exposed to and/or infected by the Coronavirus/COVID-19 may 
            result from the actions, omissions, or negligence of myself and others, including, but 
            not limited to, Kids fun hut staff, and other Kids fun hut clients and their families. 
            I voluntarily seek services provided by Kids fun hut and acknowledge that I am increasing 
            my risk to exposure to the Coronavirus/COVID-19. I acknowledge that I must comply with 
            all set procedures to reduce the spread while participating in Kids fun hut activities. 
            I attest that: * I am not experiencing any symptom of illness such as cough, shortness 
            of breath or difficulty breathing, fever, chills, repeated shaking with chills, muscle 
            pain, headache, sore throat, or new loss of taste or smell. I hereby release and agree 
            to hold Kids fun hut harmless from, and waive on behalf of myself, my heirs, and any 
            personal representatives any and all causes of action, claims, demands, damages, costs, 
            expenses and compensation for damage or loss to myself and/or property that may be caused 
            by any act, or failure to act of Kids fun hut, or that may otherwise arise in any way in 
            connection with any services received from Kids fun hut. I understand that this release 
            discharges Kids fun hut from any liability or claim that I, my heirs, or any personal 
            representatives may have against Kids fun hut with respect to any bodily injury, illness, 
            death, medical treatment, or property damage that may arise from, or in connection to, 
            any services received from Kids fun hut. This liability waiver and release extends to 
            the Kids fun hut together with all owners, partners, associated companies, and employees.
            Kids fun hut (and or any entity acting as or on behalf of Kids fun hut) is a parent/guardian 
            supervised facility. Parents/guardians are strongly urged to keep their kids in their own 
            sight and monitor their activities closely.

            Kids and parents/guardians are expected to observe the rules of the premises and rules of 
            each activity, displayed at or around the respective activities. Everyone is urged to 
            respect and accommodate the patrons around them to create a safe and fun environment while 
            at the premises. Patrons failing to observe the rules will be requested to vacate the 
            premises.

            Activities in the premises are physically demanding and potentially dangerous. 
            Some of these activities can cause heart attack, dislocation of joints, broken bones, 
            concussion, and even death. All patrons are requested to understand their own health 
            and physical conditions and use the activities at their own risk. 
            Kids fun hut (and or any entity acting as or on behalf of Kids fun hut) will not 
            and can not be held responsible for any physical, mental, or emotional injury or 
            death; while using or due to the usages of any of its activities.
            Kids fun hut (and or any entity acting as or on behalf of Kids fun hut) facility 
            is monitored by surveillance devices. This surveillance is for monitoring purpose 
            only and can only be shared with Law Enforcing Authorities, should there ever be 
            a need for one. Patrons are welcomed to take pictures of their families and participating guests 
            while at the premises. Everyone is requested to make reasonable efforts to avoid 
            including any person who is not part of their group or a willing participant.
            `;
            doc.setFontSize(12);
            doc.text(content, 10, 50);
            const content2 = `
            By accepting and/or signing this Waiver, I agree to GIVE UP MY RIGHT TO SUE 
            Kids fun hut (and or any entity acting as or on behalf of Kids fun hut) for any 
            damage, expense, physical or emotional injury, paralysis, or death that I or my 
            family or estate may suffer as a result of my participation in Kids fun hut 
            (and or any entity acting as or on behalf of Kids fun hut) due to any cause 
            whatsoever, including Kids fun hut (and or any entity acting as or on behalf 
            of Kids fun hut)'s negligence, failure to warn or protect me from risks, breach 
            of contract, breach of any other duty of care, or breach of Occupiers' Liability 
            Act, R.S.O 1990, c. O.2. I agree to waive any and all rights that I have or may 
            have in the future against Kids fun hut (and or any entity acting as or on behalf 
            of Kids fun hut) and release Kids fun hut (and or any entity acting as or on behalf 
            of Kids fun hut) for any damage, expense, injury, or death.
            `;
            doc.addPage();
            doc.text(content2, 10, 10);
            const waiver = this.previouslySignedWaivers.find(x => x.id === waiverId);
            const headers = ["Ref Number", "First Name", "Last Name","Birth Year", "Sign Date"];
            const records = [[waiver.waiverRef,  waiver.firstName, waiver.secondName, waiver.birthYear, waiver.signDate]];
            doc.autoTable({
                startY: 85,
                head: [headers],
                body: records,
            });
            doc.save("WaiverHistory.pdf");
        },
        getWaivers() {
            this.$store.dispatch('loader/setLoader', true);
            const user = this.$store.getters['auth/userDetails'];
            const userRefId = moment(user.created_at).format('YYYYMMDD') + user.id;
            if (user.waivers.length > 0) {
                this.isUserSignedWaiver = true;
                // for (const waiver of user.waivers) {
                //     this.previouslySignedWaivers.push({
                //         id: waiver.id,
                //         waiverRef: userRefId + waiver.id,
                //         firstName: waiver.first_name,
                //         secondName: waiver.last_name,
                //         birthYear: waiver.dob_year,
                //         signDate: moment(waiver.created_at).format('YYYY/MM/DD')
                //     });
                // }
                const index = user.waivers.length - 1;
                if (index != -1) {
                    const waiver = user.waivers[index];
                    this.previouslySignedWaivers.push({
                        id: waiver.id,
                        waiverRef: userRefId + waiver.id,
                        firstName: waiver.first_name,
                        secondName: waiver.last_name,
                        birthYear: waiver.dob_year,
                        signDate: moment(waiver.created_at).format('YYYY/MM/DD')
                    });
                }
            }
            WaiverDetailApi.GetWaiver()
                .then(response => {
                    if (response.data.user.waivers.length > 0) {
                        for (const member of response.data.user.waivers) {
                            this.waiverMembers.push({
                                id: member.id,
                                firstName: member.waiver_first_name,
                                secondName: member.waiver_last_name,
                                birthYear: member.dob_year
                            });
                        }
                    }
                    this.waiverDetail = {
                        signDate: this.dateFormatter(response.data.user.created_at),
                        name: response.data.user.name,
                        phone: response.data.user.phone_number,
                        email: response.data.user.email,
                        ref: userRefId
                    };
                    this.$store.dispatch('loader/setLoader', false);
                })
                .catch(error => {
                    this.$store.dispatch('loader/setLoader', false);
                    console.error(error);
                });
        },
        dateFormatter(dateString) {
            const dateObject = new Date(dateString);
            const year = dateObject.getFullYear();
            const month = dateObject.getMonth() + 1;
            const day = dateObject.getDate();
            const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
            return formattedDate;
        }
    }
}
</script>
<style scoped>
.heading h1,
h5,
p {
    color: black;
}

.waiverTable div {
    border: 1px solid;
}

.waiver-detail label {
    font-weight: bolder;
}
</style>