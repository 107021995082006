<template>
    <Header />
    <div class="container spacing__x-150 f-header">
        <div class="row">
            <!-- Side Panel -->
            <nav class="col-md-2">
                <div class="d-md-block user-detail-sidebar bg-light sidebar">
                    <div class="sidebar-sticky">
                        <ul class="nav flex-column">
                            <li class="nav-item">
                                <a class="nav-link" @click="() => this.activeScreen = 0" :class="activeScreen == 0 && 'active'">
                                    <span data-feather="home"></span>
                                    Recent Orders
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" @click="() => this.activeScreen = 1" :class="activeScreen == 1 && 'active'">
                                    <span data-feather="file"></span>
                                    My Waivers
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" @click="() => this.activeScreen = 2" :class="activeScreen == 2 && 'active'">
                                    <span data-feather="shopping-cart"></span>
                                    My Profile
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" @click="() => this.activeScreen = 3" :class="activeScreen == 3 && 'active'">
                                    <span data-feather="shopping-cart"></span>
                                    Billing Info
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <!-- Main Content -->
            <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4">
                <Orders v-if="activeScreen == 0"/>
                <MyWaiver v-else-if="activeScreen == 1"/>
                <MyProfile v-else-if="activeScreen == 2"/>
                <BillingInfo :showHeaderFooterPanel="false" v-else/>
            </main>
        </div>
    </div>
    <Footer />
</template>

<script>
import Header from '../layouts/Header.vue';
import Footer from '../layouts/Footer.vue';
import Orders from './Orders.vue';
import MyWaiver from './MyWaiver.vue';
import MyProfile from './MyProfile.vue';
import BillingInfo from '../BillingInfo.vue';
export default {
    name: "Index",
    components: {
        Header,
        Footer,
        Orders,
        MyWaiver,
        MyProfile,
        BillingInfo
    },
    data() {
        return {
            activeScreen: 0,
        };
    },
    methods: {
      
    }
}
</script>