<template>
    <Header />
    <section class="spacing__x f-header">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="thank-you-container">
                        <div class="thank-you-text">Thank You!</div>
                        <div>Your waiver has been submitted successfully</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
</template>
<script>
import Header from '../layouts/Header.vue'
import Footer from '../layouts/Footer.vue'
export default {
    name: 'Thankyou',
    components: {
        Header,
        Footer
    },
    mounted() {
        setTimeout(() => {
            this.redirectToWaiver();
        }, 4000);
    },
    methods: {
        redirectToWaiver() {
            this.$store.dispatch("loader/setLoader", true);
            setTimeout(() => {
                this.$router.replace('/sign-in');
                this.$store.dispatch("loader/setLoader", false);
            }, 1000);
        }
    }
}
</script>
<style>
.thank-you-container {
    text-align: center;
}

.thank-you-text {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
}

.greeting-gif {
    max-width: 100%;
    height: auto;
}
</style>