import { AttractionType } from '../../enums/index';
import { GRIP_SOCKS } from '../constants';
import moment from 'moment';
export function redirectScreen() {
    const cart = this.$store.getters['cart/Cart'];
    const ticket = cart.find(o => o.attractionType === AttractionType.Ticket);
    //If Attraction is only Grip Sock than it wont take the booking time or booking data.
    if((ticket) && (ticket.attractions.length === 1) && (ticket.attractions[0].attractionName === GRIP_SOCKS)){
        ticket.booking_date = moment().format('YYYY/MM/DD');
        ticket.booking_time = moment().format("HH:mm");
        this.$router.push('/cart-detail');
        return;
    }
    if (ticket && (ticket.booking_date === '' || ticket.booking_time === '')) {
        this.$router.push('/cart-schedule');
        return;
    }
    this.$router.push('/cart-detail');
}