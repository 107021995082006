<template>
  <Header></Header>
  <!-- HEADING SECTION START -->
  <section class="spacing__x f-header banner pb-0">
    <img class="prop prop-middle-left" src="@/assets/images/elements/element-2.png" alt="">
    <img class="prop prop-bottom-right" src="@/assets/images/elements/element-3.png" alt="">
    <div class="container text-center position-relative mb-4">
      <h1 class="fs-60 heading-color-1">Kids Fun Hut Promotions & Specials</h1>
    </div>
  </section>
  <!-- HEADING SECTION END -->


  <!-- PROMOTION SECTION START -->
  <section class="promotionSection spacing__x pt-0">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="promotionSlider">
            <PromotionCarousel :carousels="promotions"/>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- PROMOTION SECTION END -->
  <Footer></Footer>
</template>
  
<script>
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
import PromotionCarousel from './layouts/carousels/PromotionCarousel.vue';
import { Promotions } from '../apiAgent';
export default {
  name: 'Promotions',
  components: {
    Header,
    Footer,
    PromotionCarousel
  },
  mounted() {
    //NOTE: Api call to get Promotions.
    this.getPromotions();
  },
  data: () => ({
        promotions: [],
    }),
  methods: {
    getPromotions(){
      this.$store.dispatch('loader/setLoader', true);
      Promotions.GetPromotions()
      .then(({ data }) => {
        this.promotions = data.promotions.data;
        this.$store.dispatch('loader/setLoader', false);
      })
      .catch((error) => {
        this.$store.dispatch('loader/setLoader', false);
        console.error(error);
      })
    }
  }
}
</script>