import { Activities, Membership, PaymentSetup } from '../../apiAgent';
import { routes } from '../../../router';
import { AttractionType } from '../../enums/index';
export function currentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Month is 0-based
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    const formattedDate = `${year}-${month}-${day}`;
    return { formattedDateTime, formattedDate };
}
export function calculateDiscountByType(discountType, discountAmount, price) {
    const _price = parseFloat(price);
    const _discountAmount = parseFloat(discountAmount);
    const discount = discountType == 'percentage' ? _price * (_discountAmount / 100) : _discountAmount;
    return discount;
}
export function back() {
    const previousRoute = this.$router.options.history.state.back;
    const componentsNotToRedirectTo = [];
    const route = routes.find((r) => r.path === previousRoute);
    const isExist = componentsNotToRedirectTo.some((c) => c === route.name);
    if (!isExist) this.$router.push(previousRoute);
}
export function handleSuccess(answer, requestBodyCollection) {
    // When user entered the card detail and payment is successfull.
    // Pass order and payment details, to server.
    this.$store.dispatch('loader/setLoader', true);
    const promises = [];
    for (const obj of requestBodyCollection) {
        obj.payload.payment_transaction = answer;
        if (obj.type === AttractionType.Ticket) {
            promises.push(Activities.PostActivities(obj.payload));
        } else if (obj.type === AttractionType.Membership) {
            promises.push(Membership.PostMemberships(obj.payload));
        } else if (obj.type === AttractionType.Party) {
            promises.push(Activities.PostActivities(obj.payload));
        } else {
        }
    }
    // Need to make to internal server call for further reservation confirmation.
    Promise.all(promises)
        .then((response) => {
            this.$iziToast.success({
                title: 'Success',
                message: 'Payment Completed!'
            });
            const _user = response.find((x) => x.data?.user);
            if (_user) {
                this.$store.dispatch('auth/updateUser', _user.data.user);
            }
            this.$store.dispatch('cart/DeleteCart');
            this.$store.dispatch('promotionMembership/unSetMembershipForCart');
            this.$store.dispatch('promotionMembership/unSetPromotionForCart');
            this.$router.push('/payment-thankyou');
            this.$store.dispatch('loader/setLoader', false);
        })
        .catch((error) => {
            this.$store.dispatch('loader/setLoader', false);
            console.error(error);
        });
}
export function findTotalPrice(product) {
    const singlePrice = product.singlePrice;
    const quantity = Number(product.quantity);
    const productDiscount = calculateDiscountByType(
        product.discount.type,
        product.discount.amount,
        singlePrice
    );
    const discountPrice = singlePrice - productDiscount;
    if (this.membership.isApplied) {
        const item = this.membership.details.find((x) => x.id == product.attractionId);
        return item.priceToPay.toFixed(2);
    }
    const totalPrice = discountPrice * quantity;
    return totalPrice.toFixed(2);
}
export function findActivityInCart(id, type) {
    const attractionByType = this.$store.getters['cart/Cart'].find((x) => x.attractionType == type);
    if (attractionByType) {
        const attraction = attractionByType.attractions.find((item) => item.attractionId == id);
        return attraction;
    }
    return null;
}
export function promoCodeValidation(promoCode, isPromoApplied) {
    if (promoCode.length == 0 && !isPromoApplied) return false;
    else if (promoCode.length !== 0 && isPromoApplied) return false;
    return true;
}
export function allAttractions() {
    let count = 0;
    let attractions = [];
    const cart = this.$store.getters['cart/Cart'];
    const tickets = cart.find((o) => o.attractionType == AttractionType.Ticket);
    const parties = cart.find((o) => o.attractionType == AttractionType.Party);
    const membership = cart.find((o) => o.attractionType == AttractionType.Membership);
    if (tickets) {
        let _tickets = [...tickets.attractions].map((o) => {
            return {
                ...o,
                attractionType: AttractionType.Ticket
            };
        });
        attractions.push(..._tickets);
        count += tickets.attractions.length;
    }
    if (parties) {
        let _parties = [...parties.attractions].map((o) => {
            return {
                ...o,
                attractionType: AttractionType.Party
            };
        });
        attractions.push(..._parties);
        count += parties.attractions.length;
    }
    if (membership) {
        let _membership = [...membership.attractions].map((o) => {
            return {
                ...o,
                attractionType: AttractionType.Membership
            };
        });
        attractions.push(..._membership);
        count += membership.attractions.length;
    }
    return { attractions, count };
}
export function plusMinusBtnValidation(membershipApplied, attractionType) {
    if (membershipApplied) {
        return true;
    }
    if (attractionType == AttractionType.Party || attractionType == AttractionType.Membership) {
        return true;
    }
    return false;
}
export function generateTransactionId() {
    // Check is billing details exist or not.
    const user = this.$store.getters['auth/userDetails'];
    if (user.billing_address == null) {
        this.$router.push('./billing-info');
        return;
    }
    PaymentSetup.BillingInfo({ total_amount: this.finalPrice })
        .then((response) => {
            const jsonFromServerSdk = JSON.parse(response.data.hppJson);
            this.isTransactionIdCreated = true;
            // Initialize Realex HPP lightbox
            RealexHpp.lightbox.init(
                'autoload',
                (answer, close) => {
                    close();
                    if (answer.AUTHCODE) {
                        this.handleSuccess(answer, this.requestBodyCollection);
                    } else {
                        this.handleError(answer.MESSAGE);
                    }
                },
                jsonFromServerSdk
            );
            this.$store.dispatch('loader/setLoader', false);
        })
        .catch((error) => {
            console.error('Error fetching server SDK:', error);
        });
}
export function handleError(errorMessage) {
    // Handle error logic.
    this.gatewayResultMessage = '<div class="alert alert-danger">' + errorMessage + '</div>';
}
export function setMembershipAndPromoDetailsGotFromPreviousSession() {
    this.$store.dispatch('loader/setLoader', true);
    try {
        const _promo = this.$store.getters['promotionMembership/promotion'];
        if (Object.keys(_promo).length > 0) {
            this.isPromoApplied = _promo.isPromoApplied;
            this.promo = {
                id: _promo.id,
                code: _promo.code,
                amount: _promo.amount,
                type: _promo.type
            };
        }
        const _membership = this.$store.getters['promotionMembership/membership'];
        if (Object.keys(_membership).length > 0) {
            this.membership = {
                showModal: false,
                details: _membership.details,
                membershipUserId: _membership.membershipUserId,
                isApplied: _membership.isApplied
            };
        }
        this.$store.dispatch('loader/setLoader', false);
    } catch (error) {
        console.error(error);
        this.$store.dispatch('loader/setLoader', false);
    }
}
export function membershipBtnVisible() {
    if (this.$store.getters['auth/isAuth']) {
        const userDetails = this.$store.getters['auth/userDetails'];
        if (userDetails.active_membership && userDetails.active_membership.length > 0) {
            return 'Have-Membership';
        }
        return 'No-Membership';
    }
    return 'No-Auth';
}
