<template>
    <Header />

    <section class="spacing__x f-header ninja-text-section">
        <img class="element element-3" src="@/assets/images/elements/element-3.png" alt="" />
        <img class="element-2" src="@/assets/images/elements/element-2.png" alt="" />
        <img class="element-5" src="@/assets/images/elements/element-5.png" alt="" />
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mb-5">
                        <h1 class="fs-60 heading-color-1">{{ this.ComponentName }}</h1>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-7">
                    <div class="mb-30">
                        <span class="color-secondary-2 fw-700 mb-10 d-inline-block">A World of Wonder for Your Little Explorers</span>
                        <h2 class="mb-20 heading-color-1 fs-30">
                            Ultimate Soft Playground Experience
                        </h2>
                        <p class="fs-17 regular-color-1">
                            At Kids Fun Hut, we've crafted a magical space where the tiniest adventurers can giggle, crawl, and play to their hearts' content. Our dedicated Toddler Area is designed with love, safety, and endless joy in mind,
                            ensuring that your little ones have the perfect environment to take their first steps into the wonders of play.
                        </p>
                    </div>
                    <h3 class="fs-20 heading-color-1 tt-capitalize fw-600 mb-30">
                        Key Features of Toddler Area:
                    </h3>

                    <div class="row">
                        <div class="col-lg-6">
                            <div class="count-listing">
                                <p class="heading-color-1"><span>1</span>Safe Haven for Tiny Feet</p>
                                <p class="heading-color-1"><span>2</span>Parent Friendly</p>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="count-listing">
                                <p class="heading-color-1 align-items-start">
                                    <span>3</span>
                                    Soft Play Structures
                                </p>
                                <p class="heading-color-1">
                                    <span>4</span>
                                    Colorful Environment
                                </p>
                            </div>
                        </div>
                    </div>
                    <p class="fs-17 regular-color-1 mb-30">
                        Foster early social skills as your little ones make friends in a space designed for shared play, cooperation, and joyful interactions. Relax in our cozy seating areas strategically placed within the Toddler Area,
                        allowing you to keep a close eye on your little adventurers while they explore and play. Our Toddler Area is designed with a focus on safety. Soft padded surfaces, gentle slopes, and age-appropriate features create a
                        secure play palace for your little explorers.
                    </p>
                </div>
                <div class="col-lg-5">
                    <img class="right-side" src="@/assets/images/attractions/toddler-area.png" alt="" />
                    <!-- <img class="right-side" src="@/assets/images/attractions/wall-climbing.jpg" alt=""> -->
                </div>
            </div>
            <div class="row">
                <div class="btn-list mb-20">
                    <router-link to="/shop/weekends" class="c-btn style-3">
                        Buy Weekend Tickets
                    </router-link>
                    <router-link to="/shop/weekdays" class="c-btn style-2">
                        Buy Weekday Tickets
                    </router-link>
                </div>
            </div>
        </div>
    </section>

    <section class="spacing__x showcase-ninja-section">
        <img class="element-4" src="@/assets/images/elements/element-4.png" alt="" />
    </section>


    <Footer />
</template>

<script>
import Header from "../layouts/Header.vue";
import Footer from "../layouts/Footer.vue";
export default {
    name: "ToddlerArea",
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            ComponentName: "Toddler Area",
        };
    },
    methods: {},
};
</script>
