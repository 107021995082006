<template>
    <Header />
    <section class="spacing__x f-header">
        <img class="prop prop-top-left" src="@/assets/images/elements/element-3.png" alt="" />
        <img class="prop prop-middle-left" src="@/assets/images/elements/element-4-1.png" alt="" />
        <img class="prop prop-middle-right" src="@/assets/images/elements/element-5.png" alt="" />
        <img class="prop prop-bottom-right" src="@/assets/images/elements/element-4.png" alt="" />
        <img class="prop prop-bottom-left" src="@/assets/images/elements/element-2.png" alt="" />
        <div class="container">
            <div class="cart-detail-section formButton mb-40 d-flex justify-content-between">
                <h1 class="fs-30 color-1 mb-10 flex-1 text-sm-center">
                    {{ showHeading() }}
                </h1>
            </div>
            <div class="form__wrap leadForm">
                <h4 class="section__heading color-white text-center py-4">
                    Please Fill In The Details
                </h4>
                <form @submit.prevent="formSubmit">
                    <div class="input__wrap">
                        <div class="form--label-wrap">
                            <label for="partyDate">Booking Date:</label>
                        </div>
                        <input type="date" id="partyDate" v-model="bookingForm.partyDate"
                            @blur="v$.bookingForm.partyDate.$touch" />
                        <div class="input-errors" v-for="(error, index) of v$.bookingForm.partyDate.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                    </div>
                    <div class="input__wrap">
                        <div class="form--label-wrap">
                            <label for="partyTime">Booking Time:</label>
                        </div>
                        <input type="time" id="partyTime" v-model="bookingForm.partyTime"
                            @blur="v$.bookingForm.partyTime.$touch" />
                        <div class="input-errors" v-for="(error, index) of v$.bookingForm.partyTime.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                    </div>
                    <button type="submit" class="c-btn style-4" :class="{ 'not-allowed': v$.bookingForm.$invalid }"
                        :disabled="v$.bookingForm.$invalid">
                        SAVE INFORMATION
                    </button>
                </form>
            </div>
        </div>
    </section>
    <Footer />
</template>
  
<script>
import Header from './layouts/Header.vue';
import Footer from './layouts/Footer.vue';
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { validateTimeWithCurrentTime, validateDateWithCurrentDate } from '../utility/Validator';
import { AttractionType } from '../enums/index';
import { redirectScreen } from '../utility/helpers';

export default {
    name: 'CartDateTime',
    components: {
        Header,
        Footer
    },
    setup() {
        return { v$: useVuelidate() };
    },
    mounted(){
        const attractionByType = this.$store.getters['cart/Cart'].find(x => x.attractionType === AttractionType.Ticket);
        if(attractionByType && attractionByType.attractions.length > 0){
            this.bookingForm.partyDate = attractionByType.booking_date;
            this.bookingForm.partyTime = attractionByType.booking_time;
        }
    },
    data() {
        return {
            bookingForm: {
                partyDate: '',
                partyTime: '',
            }
        }
    },
    methods: {
        redirectScreen,
        formSubmit() {
            this.v$.$validate();
            if (!this.v$.$error) {
                try {
                    this.$store.dispatch("loader/setLoader", true);
                    //Update Cart Details
                    let attractionByType = this.$store.getters['cart/Cart'].find(x => x.attractionType === AttractionType.Ticket);
                    attractionByType.booking_date = this.bookingForm.partyDate;
                    attractionByType.booking_time = this.bookingForm.partyTime;
                    this.$store.dispatch('cart/UpdateCart', {
                        activity: attractionByType,
                        type: AttractionType.Ticket
                    });
                    //Redirect to cart detail screen.
                    this.redirectScreen();
                    setTimeout(() => {
                        this.$store.dispatch("loader/setLoader", false);
                    }, 1000);
                } catch (error) {
                    console.error('error: ', error);
                    this.$store.dispatch("loader/setLoader", false);
                }
            }
        },
        showHeading() {
            let heading = '';
            const attractions = this.allActivities().attractions || [];
            const total = attractions.length > 0 ? this.allActivities().total : 0;
            let count = 1;
            for (const activity of attractions) {
                heading += activity.attractionName;
                if (count !== total) {
                    heading += ' | ';
                }
                count++;
            }
            if(heading === ''){
                this.$router.replace('/shop/weekends');
                return;
            }
            return heading;
        },
        allActivities() {
            const cart = this.$store.getters['cart/Cart'];
            const tickets = cart.find(o => o.attractionType === AttractionType.Ticket);
            if(tickets){
                const attractions = [...tickets.attractions];
                const total = attractions.length;
                return { attractions, total };
            }
            return {};
        }
    },
    validations() {
        {
            return {
                bookingForm: {
                    partyDate: {
                        required,
                        valid_Date: {
                            $validator: function () {
                                return validateDateWithCurrentDate(this.bookingForm.partyDate);
                            },
                            $message: `The Date must be bigger or equal to today date.`
                        }
                    },
                    partyTime: {
                        required,
                        validate_Time: {
                            $validator: function () {
                                return validateTimeWithCurrentTime(this.bookingForm.partyTime);
                            },
                            $message: `The given time is not between 9 AM and 9 PM.`
                        }
                    }
                },
            };
        }
    }
}
</script>