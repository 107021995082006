<template v-if="carousels && carousels.length !== 0">
  <Carousel :wrap-around="true" :autoplay="3000">
    <Slide v-for="carousel in carousels" :key="carousel.id">
      <div class="carousel__item">
        <div class="img__wrap">
          <img :src="`${baseUrl}/${carousel.path}`" alt="">
        </div>
      </div>
    </Slide>
    <template #addons>
      <!-- <Navigation /> -->
      <!-- <Pagination /> -->
    </template>
  </Carousel>
</template>
<script>
import 'vue3-carousel/dist/carousel.css';
import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel';

export default {
  name: 'HomeHeadingCarousel',
  props: {
    carousels: {
      type: Array,
      required: true,
    }
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  data() {
    return {
      // carousel settings
      settings: {
        fade: 1,
        itemsToShow: 1,
        snapAlign: 'center',
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 1,
          snapAlign: 'start',
        },
      },
      baseUrl: `${process.env.VUE_APP_FORGOT_PASSWORD_LINK}/`,
    }
  },
  methods: {}
};
</script>
