<template>
    <Header></Header>

    <section class="spacing__x f-header ninja-text-section">
         <img class="element element-3" src="@/assets/images/elements/element-3.png" alt="">
        <img class="element-2" src="@/assets/images/elements/element-2.png" alt="">
        <img class="element-5" src="@/assets/images/elements/element-5.png" alt="">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mb-5">
                        <h1 class="fs-60 heading-color-1">{{ this.ComponentName }}</h1>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-7">
                    <div class="mb-30">
                        <span class="color-secondary-2 fw-700 mb-10 d-inline-block">
                            Zip-line for Kids
                        </span>
                        <h2 class="mb-20 heading-color-1 fs-30">
                            Where Fun meets Adrenaline!
                        </h2>
                        <p class="fs-17 regular-color-1">
                            Get ready for an adrenaline-packed, gravity-defying, and heart-pounding adventure that will leave your kids grinning from ear to ear? Look no further, because our Zipline for Kids is here to turn their most anticipated day into a much more extravagant adventure.
                        </p>
                    </div>
                    <h3 class="fs-20 heading-color-1 tt-capitalize fw-600 mb-30">
                        Why Choose Our Zipline for Kids?
                    </h3>

                    <div class="row">
                        <div class="col-lg-6">
                            <div class="count-listing">
                                <p class="heading-color-1"><span>1</span>Fun for kids of all ages</p>
                                <p class="heading-color-1"><span>2</span>Top of the line safety ensured</p>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="count-listing">
                                <p class="heading-color-1 align-items-start">
                                    <span>3</span>Thrilling Ride
                                </p>
                                <p class="heading-color-1">
                                    <span>4</span>Soar like a bird
                                </p>
                            </div>
                        </div>
                    </div>

                    <p class="fs-17 regular-color-1 mb-30">
                        Don't let your kids miss out on this epic zipline experience! Book now and watch their faces light up with thrill and joy as they cruise on our amazing zipline. A ride they will never be bored of.
                    </p>
                </div>
                <div class="col-lg-5">
                    <img class="right-side" src="@/assets/images/attractions/zip-line.png" alt="" />
                </div>
            </div>
            <div class="row">
                <div class="btn-list mb-20">
                    <router-link to="/shop/weekends" class="c-btn style-3">
                        Buy Weekend Tickets
                    </router-link>
                    <router-link to="/shop/weekdays" class="c-btn style-2">
                        Buy Weekday Tickets
                    </router-link>
                </div>
            </div>
        </div>
    </section>


    <section class="spacing__x showcase-ninja-section">
        <img
            class="element-4"
            src="@/assets/images/elements/element-4.png"
            alt=""
        />
    </section>

    <Footer></Footer>
</template>
  
<script>
import Header from '../layouts/Header.vue'
import Footer from '../layouts/Footer.vue'
export default {
    name: 'ZipLine',
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            ComponentName: 'ZipLine'
        };
    },
    methods: {
    }
};
</script>
