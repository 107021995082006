<template>
    <section class="gallerySection__5 spacing__x">
        <img src="@/assets/images/elements/element-2.png" alt="" class="prop prop-top-left">
        <img src="@/assets/images/elements/element-3.png" alt="" class="prop prop-top-right">

        <div class="container">
            <div class="section__text text-center">
                <h4 class="section__heading color-1 fs-60 mb-0">Kids Fun Hut Image Gallery</h4>
            </div>
            <!-- 
            <div class="gallerySwitchButtons d-flex align-items-center justify-content-center gap-40">
                <button class="c-btn active">Indoor</button>
                <button class="c-btn ">Book</button>
                <button class="c-btn ">Gift</button>
            </div> -->

            <div class="col-5-grid col-5-gridContainer">
                <div class="grid__column">
                    <div class="img__wrap">
                        <img src="@/assets/images/gallery-image/1.png" alt="">
                    </div>
                    <div class="img__wrap">
                        <img src="@/assets/images/gallery-image/2.png" alt="">
                    </div>
                    <div class="img__wrap">
                        <img src="@/assets/images/gallery-image/3.png" alt="">
                    </div>
                    <div class="img__wrap">
                        <img src="@/assets/images/gallery-image/4.png" alt="">
                    </div>
                </div>
                <div class="grid__column">
                    <div class="img__wrap">
                        <img src="@/assets/images/gallery-image/5.png" alt="">
                    </div>
                    <div class="img__wrap">
                        <img src="@/assets/images/gallery-image/6.png" alt="">
                    </div>
                    <div class="img__wrap">
                        <img src="@/assets/images/gallery-image/7.png" alt="">
                    </div>
                    <div class="img__wrap">
                        <img src="@/assets/images/gallery-image/8.png" alt="">
                    </div>
                </div>
                <div class="grid__column">
                    <div class="img__wrap">
                        <img src="@/assets/images/gallery-image/9.png" alt="">
                    </div>
                    <div class="img__wrap">
                        <img src="@/assets/images/gallery-image/10.png" alt="">
                    </div>
                    <div class="img__wrap">
                        <img src="@/assets/images/gallery-image/11.png" alt="">
                    </div>
                    <div class="img__wrap">
                        <img src="@/assets/images/gallery-image/12.png" alt="">
                    </div>
                </div>
                <div class="grid__column">
                    <div class="img__wrap">
                        <img src="@/assets/images/gallery-image/13.png" alt="">
                    </div>
                    <div class="img__wrap">
                        <img src="@/assets/images/gallery-image/14.png" alt="">
                    </div>
                    <div class="img__wrap">
                        <img src="@/assets/images/gallery-image/15.png" alt="">
                    </div>
                    <div class="img__wrap">
                        <img src="@/assets/images/gallery-image/16.png" alt="">
                    </div>
                </div>
                <div class="grid__column">
                    <div class="img__wrap">
                        <img src="@/assets/images/gallery-image/17.png" alt="">
                    </div>
                    <div class="img__wrap">
                        <img src="@/assets/images/gallery-image/18.png" alt="">
                    </div>
                    <div class="img__wrap">
                        <img src="@/assets/images/gallery-image/19.png" alt="">
                    </div>
                    <div class="img__wrap">
                        <img src="@/assets/images/gallery-image/20.png" alt="">
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
export default {
    name: 'FiveColGridSection'
}
</script>