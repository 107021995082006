<template>
    <Header></Header>
    <!-- RECTANGLE BANNER START -->
    <!-- style="background: url(assets/images/contact-bg.png) center/cover no-repeat;" -->
    <section class="rectangleBanner style-1"
        :style="{ backgroundImage: `url(${getImgPath('contact-bg.png')}) center/cover no-repeat;` }">
        <div class="container">
            <div class="rectangleBannerText">
                <h1>Contact Us</h1>
                <div class="path d-flex align-items-center justify-content-center">
                    <router-link to="/"><i class="fas fa-home mr-2"></i>Home</router-link>
                    <router-link to="/contact">Contact Us</router-link>
                </div>
            </div>
        </div>
    </section>
    <!-- RECTANGLE BANNER END -->

    <section class="contactMainSection spacing__x">
        <img src="@/assets/images/elements/element-2.png" alt="" class="prop prop-top-left">
        <img src="@/assets/images/elements/element-4.png" alt="" class="prop prop-bottom-left">
        <img src="@/assets/images/elements/element-3.png" alt="" class="prop prop-bottom-right">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-sm-12">
                    <div class="contactMainText">
                        <h4 class="color-1 fw-700 fs-40 mb-20">
                            Having Trouble Finding Us
                        </h4>
                        <p class="mb-40 fs-16 regular-color-1">
                            Find the live pin location for Fun Kid’s Hut Below. Still having troubles? Give us a call and we
                            will be happy to guide you. Drop us a message in case if you have queries.
                        </p>
                    </div>

                    <div class="row justify-content-md-center mb-10 justify-content-lg-between">
                        <div class="col-lg-6 col-md-6 mb-20 col-sm-12">
                            <div class="contactCards">
                                <div class="icon__wrap">
                                    <i class="fas fa-phone-alt"></i>
                                </div>
                                <h6 class="color-1 fs-20">Phone</h6>
                                <a href="tel:5488893504">548-889-3504</a>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 mb-20 col-sm-12">
                            <div class="contactCards">
                                <div class="icon__wrap">
                                    <i class="fas fa-map-marker-alt"></i>
                                </div>
                                <h6 class="color-1 fs-20">Address</h6>
                                <a href="https://www.google.com/maps/place/Kids+Fun+Hut,+525+Hespeler+Rd+g2,+Cambridge,+ON+N1R+6J2/data=!4m2!3m1!1s0x882b89ddf6618ba3:0xcf0efced8dbcc5a0?utm_source=mstt_1&entry=gps&lucs=47068615,,47075915&g_ep=CAESCTExLjk3LjMwMxgAINeCAyoSNDcwNjg2MTUsLDQ3MDc1OTE1QgJDQQ%3D%3D"
                                    target="_blank">G2-525 Hespeler Rd, Cambridge, ON ,
                                    N1R6J2</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-12">
                    <div class="form__wrap contactFormMain">
                        <h6 class="form__heading color-1 fs-24">Send Message</h6>
                        <form ref="contactFormRef" @submit.prevent="handleSubmit">
                            <div class="row">
                                <div class="col-lg-6 col-sm-12 mb-20">
                                    <input type="text" name="name" id="name" placeholder="Name" v-model="formData.name"
                                        @blur="v$.formData.name.$touch">
                                    <div class="input-errors" v-for="(error, index) of v$.formData.name.$errors"
                                        :key="index">
                                        <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-12 mb-20">
                                    <input 
                                        type="email" 
                                        placeholder="Email Address"
                                        v-model="formData.email" 
                                        @blur="v$.formData.email.$touch">
                                    <div class="input-errors" v-for="(error, index) of v$.formData.email.$errors"
                                        :key="index">
                                        <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-sm-12 mb-20">
                                    <input type="text" name="subject" id="subject" placeholder="Subject"
                                        v-model="formData.subject" @blur="v$.formData.subject.$touch">
                                    <div class="input-errors" v-for="(error, index) of v$.formData.subject.$errors"
                                        :key="index">
                                        <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-sm-12 mb-20">
                                    <textarea placeholder="Message" name="message" id="message" v-model="formData.message"
                                        @blur="v$.formData.message.$touch"></textarea>
                                    <div class="input-errors" v-for="(error, index) of v$.formData.message.$errors"
                                        :key="index">
                                        <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-sm-12">
                                    <div class="button__wrap">
                                        <button type="submit" class="c-btn style-3" :disabled="v$.formData.$invalid"
                                            :class="{ 'not-allowed': v$.formData.$invalid }">Send Message</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="mapSection">
        <img src="@/assets/images/elements/element-5.png" alt="" class="prop prop-bottom-left">
        <img src="@/assets/images/elements/element-7.png" alt="" class="prop prop-bottom-right">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2898.863790760778!2d-80.3285031151169!3d43.40077629425711!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b89ddf6618ba3%3A0xcf0efced8dbcc5a0!2sKids%20Fun%20Hut!5e0!3m2!1sen!2s!4v1698956282993!5m2!1sen!2s"
            width="100%" height="648" style="border:0;" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
    </section>
    <Footer></Footer>
</template>
  
<script>
import Header from './layouts/Header.vue';
import Footer from './layouts/Footer.vue';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { validName } from '../utility/Validator';
import { Contact } from '../apiAgent';
export default {
    name: 'Contact',
    components: {
        Header,
        Footer
    },
    data() {
        return {
            formData: {
                name: '',
                email: '',
                subject: '',
                message: ''
            }
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    methods: {
        getImgPath(img) {
            return img ? require('@/assets/images/' + img) : '';
        },
        handleSubmit() {
            this.v$.$validate();
            if (!this.v$.$error) {
                this.$store.dispatch('loader/setLoader', true);
                Contact.PostForm(this.formData).then(response => {
                    this.$store.dispatch('loader/setLoader', false);
                    this.$refs.contactFormRef.reset();
                    this.$iziToast.success({
                        title: 'Success',
                        message: 'Response Submitted!'
                    });
                })
                .catch(error => {
                    this.$store.dispatch('loader/setLoader', false);
                    console.error(error);
                    this.$iziToast.error({
                        title: 'Error',
                        message: error.response.data.message,
                    });
                });
            }
        }
    },
    validations() {
        {
            return {
                formData: {
                    name: {
                        required,
                        name_validation: {
                            $validator: validName,
                            $message: 'Invalid Name. Valid name only contain letters, dashes (-) and spaces.'
                        }
                    },
                    email: {
                        required,
                        email
                    },
                    subject: {
                        required
                    },
                    message: {
                        required
                    }
                }
            };
        }
    }
}
</script>