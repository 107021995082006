import { createApp } from 'vue';
import App from './App.vue';
import router from '../router';
import 'izitoast/dist/css/iziToast.min.css';
import iziToast from 'izitoast';
import store from './store/store';

const app = createApp(App);
app.config.globalProperties.$iziToast = iziToast;
app.use(router);
app.use(store);
app.mount('#app');
