import moment from 'moment';

const validName = (name) => {
    const validNamePattern = new RegExp("^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$");
    if (validNamePattern.test(name)) return true;
    return false;
};

const validPhoneNo = (phone) => {
    const validPhonePattern = /^\+?\d+$/;
    if (validPhonePattern.test(phone)) return true;
    return false;
};

const validPostalCode = (postalCode) => {
    const digitsOnly = /^\d+$/;
    if (!digitsOnly.test(postalCode)) {
        return false;
    }
    return true;
}

const validString = (inputString) => {
    const onlyLettersRegex = /^[a-zA-Z\s]*$/;
    if (onlyLettersRegex.test(inputString)) {
        return true;
      } 
    return false;
}

const validateTimeWithCurrentTime = (inputTime) => {
    const currentTime = new Date();
    const inputTimeComponents = inputTime.split(':');
    const userTime = new Date(
        currentTime.getFullYear(),
        currentTime.getMonth(),
        currentTime.getDate(),
        parseInt(inputTimeComponents[0]),
        parseInt(inputTimeComponents[1]),
        0
    );
    const startTime = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), 9, 0, 0);
    const endTime = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), 21, 0, 0);

    if (userTime >= startTime && userTime <= endTime)
        return true;
    return false;
};

const validateDateWithCurrentDate = (userInput) => {
    let current = moment();
    let given = moment(userInput);
    const isSameOrAfter = given.isSameOrAfter(current, 'date');
    return isSameOrAfter;
};

export { validName, validPhoneNo, validPostalCode, validString, validateTimeWithCurrentTime, validateDateWithCurrentDate };
