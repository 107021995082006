<template>
    <div class="container orderTable">
        <div class="row">
            <div class="col-12">
                <h1>Orders</h1>
            </div>
        </div>

        <div class="row orderTableColumn">
            <div class="col-3">
                <h5>Payment Status</h5>
            </div>
            <div class="col-3">
                <h5>Payment Amount</h5>
            </div>
            <div class="col-3">
                <h5>Booking Date</h5>
            </div>
            <div class="col-3">
            </div>
        </div>

        <div class="row orderTableColumn" v-for="order in paginatedItems" :key="order.id">
            <div class="col-3">
                <p>{{ order.payment_status }}</p>
            </div>
            <div class="col-3">
                <p>{{ order.amount_paid }}</p>
            </div>
            <div class="col-3">
                <p>{{ order.booking_date }}</p>
            </div>
            <div class="col-3">
                <button type="button"
                        class="btn btn-secondary"
                        @click="showDetails(order)">Show Details</button>
            </div>
        </div>

         <div class="row">
            <div class="col-12">
                <div class="pagination">
                    <ul>
                        <li v-for="pageNumber in totalPages" :key="pageNumber">
                        <button @click="changePage(pageNumber)" :disabled="currentPage === pageNumber">
                            {{ pageNumber }}
                        </button>
                        </li>
                    </ul>
                </div>
            </div>
         </div>
    </div>
    <!-- Bootstrap Modal -->
    <div class="modal" :style="{ display: showModal ? 'block' : 'none' }">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Order Details</h5>
                    <button type="button"
                            class="close"
                            aria-label="Close"
                            @click="() => { this.showModal = false }">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="col-4">
                                <b>Title</b>
                            </div>
                            <div class="col-2 text-start text-center">
                                <p><b>Quantity</b></p>
                            </div>
                            <div class="col-2 text-center">
                                <p><b>Price</b></p>
                            </div>
                        </div>
                        <div class="row"
                             v-for="detail in orderDetails" :key="detail.id">
                            <div class="col-4">
                                <p>{{ detail?.activity?.title }}</p>
                            </div>
                            <div class="col-2">
                                <p>{{ detail?.quantity }}</p>
                            </div>
                            <div class="col-2">
                                <p>{{ detail?.activity?.price }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="() => { this.showModal = false }">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { MyProfile } from '../../apiAgent';
export default {
    name: "Orders",
    mounted() {
        this.getOrders();
    },
    data() {
        return {
            orders: [],
            orderDetails : [],
            showModal: false,
            itemsPerPage : 10,
            currentPage : 1
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.orders.length / this.itemsPerPage);
        },
        paginatedItems() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return this.orders.slice(startIndex, endIndex);
        },
    },
    methods: {
        getOrders() {
            this.$store.dispatch('loader/setLoader', true);
            MyProfile.getRecentOrders()
                .then((response) => {
                    this.$store.dispatch('loader/setLoader', false);
                    this.orders = response.data.data.data;
                })
                .catch((error) => {
                    this.$store.dispatch('loader/setLoader', false);
                    console.error(error);
                    this.$iziToast.error({
                        title: 'Error',
                        message: error.response.data.message
                    });
                });
            this.showWaiverForm = true;
        },
        showDetails(order) {
            this.orderDetails = [];
            this.showModal = true;
            this.orderDetails.push(...order.reservation_details); 
        },
        changePage(pageNumber){
            this.currentPage = pageNumber;
        }
    }
}
</script>
<style scoped>
.orderTable h1,h5, p {
    color: black;
}
.orderTableColumn div {
    border: 1px solid;
}
.modal .row div {
    border: 1px solid;
}
.pagination ul {
    font-size: 20px;
}
</style>