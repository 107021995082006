import Cookies from 'js-cookie';
export default {
    namespaced: true,
    state: {
        activity: {},
    },
    getters: {
        activityForBooking(state) {
            const mycookie = Cookies.get('activity');
            if (Object.keys(state.activity).length == 0 && mycookie != undefined) {
                const json = mycookie.replace(/"-"/g, '","');
                const activity = JSON.parse(json);
                state.activity = activity;
            }
            return state.activity;
        }
    },
    actions: {
        setActivityForBooking({ commit }, _activity) {
            commit('SET_ACTIVITY', _activity);
        },
        unSetActivityForBooking({ commit }){
            commit('UNSET_ACTIVITY');
        }
    },
    mutations: {
        SET_ACTIVITY(state, _activity) {
            state.activity = _activity;
            const json = JSON.stringify(state.activity);
            const mycookie = json.replace(/","/g, '"-"');
            Cookies.set('activity', mycookie);
        },
        UNSET_ACTIVITY(state){
            state.activity = {};
            Cookies.remove('activity');
        }
    }
};
