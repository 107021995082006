<template>
  <section class="spacing__x pb-0">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div id="" class="text-center">
            <h4 class="fs-60 fw-700 tt-uppercase ls-3 heading-color-1 mb-4">Family Member {{ index + 1 }}</h4>
          </div>
        </div>
      </div>
      <form class="row fg-mb">
        <div class="col-lg-6">
          <div class="form-group">
            <input type="text" class="custom-input-style" v-model="waiver.waiver_first_name" placeholder="First Name*"
                   required>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <input type="text" class="custom-input-style" v-model="waiver.waiver_last_name" placeholder="Last Name*"
                   required>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="form-group d-flex align-items-center mb-0">
            <label class="fs-19 mb-0 w-100">Date Of Birth:*</label>

            <div class="d-flex mr-3 align-items-center w-100">
              <label class="mr-10">Date</label>
              <select v-model="waiver.dob_day" class="form-control">
                  <option v-for="n in 31" :value="n">{{ n }}</option>
              </select>
            </div>
            <div class="d-flex align-items-center w-100">
              <label class="mr-10">Month</label>
              <select class="form-control" v-model="waiver.waiver_dob_month">
                  <option v-for="month in months" :value="month">{{ month }}</option>
              </select>
            </div>
            <div class="d-flex mr-3 align-items-center w-100">
              <label class="mr-10">Year</label>
              <select v-model="waiver.waiver_dob_year" class="form-control">
                  <option v-for="year in years" :value="year">{{ year }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-group text-right">
            <a @click.prevent="removeWaiver" class="c-btn style-2 tt-uppercase">Remove Member</a>
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col">
          <div class="text-left mt-5">
            <img src="@/assets/images/elements/element-4.png" alt="">
          </div>
        </div>
        <div class="col">
          <div class="text-center mt-5">
            <img src="@/assets/images/elements/element-3.png" alt="">
          </div>
        </div>
        <div class="col">
          <div class="text-right mt-5">
            <img src="@/assets/images/elements/element-4.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import ConfirmDialog from "@/components/Helpers/ConfirmDialog";
import Swal from "sweetalert2";//Confirmation Alert.
import { _months, _years } from '../../utility/constants';
export default {
  name: 'WaiverUser',
  data() {
    return {
      showConfirmDialog: false,
      months: _months,
      years: _years
    }
  },
  components: {
    ConfirmDialog,
  },
  props: {
    waiver: Object, // Receive the waiver data as a prop
    index: Number,
  },
  methods: {
    showConfirmDialogFunc() {
      this.showConfirmDialog = true;
    },
    removeWaiver() {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You are about to delete this member.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          // User confirmed, you can proceed with the deletion here
          this.$emit('remove-waiver', this.waiver);
        }
      });

    },
    cancelRemove() {
      this.showConfirmDialog = false; // Close the dialog if canceled
    },
    emitData() {
      // const obj = this.formData;
      // Emit a custom event with data
      // this.$emit('custom-event-name', obj);
    },
  },
  created() {
    // Call the method to emit form data when the component is mounted
    // this.emitData();
    // const localWaivers = localStorage.getItem('waivers');
    // if(localWaivers==='[]'){
    //     return;
    // }
    // let waivers = JSON.parse(localStorage.getItem('waivers'));
    // this.formData = waivers[this.index];

    // this.formData.waiver_first_name = JSON.parse(localStorage.getItem('waivers')[0]);
  },
}
</script>