<template>
    <header :class="isApplyClass ? 'sticky-header' : ''">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-lg navbar-light" :class="{ navbarOpen: showResponsiveNavbar }">
                <router-link class="navbar-brand" to="/">
                    <img class="w-70" src="@/assets/images/logo-100.png" />
                </router-link>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                    @click.stop="toggleNavbar()">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent" :class="{ show: showResponsiveNavbar }">
                    <div class="mobile-menu-logo d-lg-none pb-3">
                        <img class="img-reponsive" src="@/assets/images/logo-100.png" alt="" />
                        <button class="mobile-menu-close" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" @click.stop="toggleNavbar()">
                            <span aria-hidden="true fs-24">&times;</span>
                        </button>
                    </div>
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item">
                            <a class="nav-link" @click="navigateTo('/')">Home</a>
                        </li>
                        <li class="nav-item dropdown-nav" @click="toggleDropdown('attractions')">
                            <a href="javscript:;" class="nav-link">Attractions</a>
                            <ul class="dropdown" :class="{ active: isAttractionsDropdownActive }">
                                <!-- <li>
                                    <a class="dropdown-item" @click="navigateTo('/')">Trampoline Park</a>
                                </li> -->
                                <li>
                                    <a class="dropdown-item" @click="
                                        navigateTo(
                                            '/attractions/wall-climbing'
                                        )
                                        ">
                                        Wall climbing
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item" @click="
                                        navigateTo(
                                            '/attractions/soft-play-ground'
                                        )
                                        ">
                                        Soft Playground
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item" @click="
                                        navigateTo(
                                            '/attractions/ninja-course'
                                        )
                                        ">
                                        Ninja Course
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item" @click="
                                        navigateTo(
                                            '/attractions/interactive-light-wall'
                                        )
                                        ">
                                        Interactive Light Wall
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item" @click="
                                        navigateTo(
                                            '/attractions/toddler-area'
                                        )
                                        ">
                                        Toddler Area
                                    </a>
                                </li>
                                <!-- <li>
                                    <a class="dropdown-item" @click="
                                        navigateTo('/attractions/fun-cafe')
                                        ">FunCafe</a>
                                </li> -->
                                <!-- <li>
                                    <a class="dropdown-item" @click="
                                        navigateTo(
                                            '/attractions/birthday-rooms'
                                        )
                                        ">
                                        BirthdayRooms
                                    </a>
                                </li> -->
                                <li>
                                    <a class="dropdown-item" @click="
                                        navigateTo(
                                            '/attractions/soccer-basketball-court'
                                        )
                                        ">
                                        Soccer And Basketball Court
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item" @click="
                                        navigateTo('/attractions/zipline')
                                        ">ZipLine</a>
                                </li>
                                <!-- <li>
                                    <a class="dropdown-item" @click="navigateTo('/')">Indoor Playground</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" @click="navigateTo('/')">Arcade Games</a>
                                </li> -->
                            </ul>
                        </li>
                        <li class="nav-item dropdown-nav" @click="toggleDropdown('tickets')">
                            <a href="#" class="nav-link">Tickets</a>
                            <ul class="dropdown" :class="{ active: isTicketsDropdownActive }">
                                <li>
                                    <a class="dropdown-item" @click="navigateTo('/shop/weekends')">Weekends</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" @click="navigateTo('/shop/weekdays')">Weekdays</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="navigateTo('/parties')">Parties</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="navigateTo('/groups')">Groups</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="navigateTo('/waiver')">Waiver</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="navigateTo('/membership')">Membership</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="navigateTo('/promotions')">Promotions</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="navigateTo('/terms')">Terms</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="navigateTo('/contact')">Contact Us</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="navigateTo('/about-us')">About Us</a>
                        </li>
                        <li class="nav-item" v-if="this.$store.getters['auth/isAuth']">
                            <a @click="navigateTo('/my-profile')" class="nav-link">MyProfile</a>
                        </li>
                        <li class="nav-item">
                            <a @click="clearSession" class="nav-link">
                                {{
                                    this.$store.getters["auth/isAuth"]
                                    ? "Sign Out"
                                    : "Sign In"
                                }}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="redirectScreen()">
                                <span class="fa fa-shopping-cart"></span>
                            </a>

                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </header>
</template>

<script>
import { CartManagement } from '../../apiAgent';
import { AttractionType } from '../../enums/index';
import { GRIP_SOCKS } from '../../utility/constants';

export default {
    name: "Header",
    props: {
        componentName: {
            type: String,
            default: "noValue",
        },
    },
    created() {
        this.handleScroll();
    },
    data() {
        return {
            isApplyClass: false,
            showResponsiveNavbar: false,
            isAttractionsDropdownActive: false,
            isTicketsDropdownActive: false,
        };
    },
    methods: {
        clearSession() {
            //Logout Case.
            if (this.$store.getters["auth/isAuth"]) {
                const _cart = this.$store.getters['cart/Cart'];
                this.$store.dispatch("loader/setLoader", true);
                CartManagement.postCart({ cart: _cart })
                    .then(() => {
                        this.$store.dispatch("loader/setLoader", false);
                        this.$store.dispatch("auth/logout");
                        this.$store.dispatch('cart/DeleteCart');
                        this.$router.push("/");
                        this.$iziToast.success({
                            title: 'Success',
                            message: 'Logout successfully!',
                        });
                    }).catch((error) => {
                        this.$store.dispatch("loader/setLoader", false);
                        console.error(error);
                        this.$iziToast.error({
                            title: 'Error',
                            message: error.response.data.message,
                        });
                    });
            }
            //Login Case. 
            else {
                this.$router.push("/login");
            }
        },
        handleScroll() {
            window.onscroll = () => {
                const maxHeight =
                    document.body.scrollHeight - window.innerHeight;
                const scrollPercentage = (window.scrollY * 100) / maxHeight;
                this.isApplyClass = scrollPercentage >= 2.2 ? true : false;
            };
        },
        toggleNavbar() {
            this.showResponsiveNavbar = !this.showResponsiveNavbar;
        },
        navigateTo(to) {
            this.$router.push(to);
            this.showResponsiveNavbar = false;
        },
        toggleDropdown(dropdown) {
            if (dropdown === "attractions") {
                this.isAttractionsDropdownActive = !this.isAttractionsDropdownActive;
                // Close the "Tickets" dropdown if it's open
                this.isTicketsDropdownActive = false;
            } else if (dropdown === "tickets") {
                this.isTicketsDropdownActive = !this.isTicketsDropdownActive;
                // Close the "Attractions" dropdown if it's open
                this.isAttractionsDropdownActive = false;
            }
        },
        redirectScreen() {
            const cart = this.$store.getters['cart/Cart'];
            const ticket = cart.find(o => o.attractionType === AttractionType.Ticket);
            //If Attraction is only Grip Sock than it wont take the booking time or booking data.
            if ((ticket) && (ticket.attractions.length === 1) && (ticket.attractions[0].attractionName === GRIP_SOCKS)) {
                ticket.booking_date = moment().format('YYYY/MM/DD');
                ticket.booking_time = moment().format("HH:mm");
                this.$router.push('/cart-detail');
                return;
            }
            const currentRoute = this.$route.path;
            if (currentRoute !== '/cart-schedule') {
                if (ticket && (ticket.booking_date === '' || ticket.booking_time === '')) {
                    this.$router.push('/cart-schedule');
                    return;
                }
                this.$router.push('/cart-detail');
                return;
            }
            this.$iziToast.info({
                title: 'Info',
                message: 'Please Fill Attraction Date and Time.'
            });
        },
    },
};
</script>
