<template>
    <Header />
    <section class="spacing__x pb-0 f-header">
        <WaiverDetail :showHeadingPanel="true" />
    </section>
    <Footer />
</template>

<script>
import WaiverDetail from "./WaiverDetail.vue";
import Header from '../layouts/Header.vue';
import Footer from '../layouts/Footer.vue';
export default {
    name: "Index",
    components: {
        WaiverDetail,
        Header,
        Footer,
    },
}
</script>