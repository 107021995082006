<template>
    <div class="container">
        <div class="row fg-mb">
            <div class="col-lg-6">
                <div class="form-group">
                    <input type="text" class="custom-input-style" v-model="formData.first_name" placeholder="First Name*"
                        @blur="v$.formData.first_name.$touch" />
                </div>
                <div class="input-errors" v-for="(error, index) of v$.formData.first_name.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="form-group">
                    <input type="text" class="custom-input-style" v-model="formData.last_name" placeholder="Last Name*"
                        @blur="v$.formData.last_name.$touch" />
                </div>
                <div class="input-errors" v-for="(error, index) of v$.formData.last_name.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="form-group">
                    <input type="email" class="custom-input-style" v-model="formData.email" placeholder="Email*"
                        @blur="v$.formData.email.$touch" />
                </div>
                <div class="input-errors" v-for="(error, index) of v$.formData.email.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <input type="tel" class="custom-input-style" v-model="formData.phone_number" placeholder="Phone*"
                        @blur="v$.formData.phone_number.$touch" />
                </div>
                <div class="input-errors" v-for="(error, index) of v$.formData.phone_number.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="form-group d-flex align-items-center mb-0">
                    <label class="fs-19 mb-0 w-100">Date Of Birth:*</label>
                    <div class="d-flex mr-3 align-items-center w-100">
                        <label class="mr-10">Date</label>
                        <select v-model="formData.dob_day" class="form-control" @blur="v$.formData.dob_day.$touch">
                            <option v-for="n in 31" :value="n">{{ n }}</option>
                        </select>
                        <div class="input-errors" v-for="(error, index) of v$.formData.dob_day.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center w-100">
                        <label class="mr-10">Month</label>
                        <select class="form-control" v-model="formData.dob_month" @blur="v$.formData.dob_month.$touch">
                            <option v-for="month in months" :value="month">{{ month }}</option>
                        </select>
                        <div class="input-errors" v-for="(error, index) of v$.formData.dob_month.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                    </div>
                    <div class="d-flex mr-3 align-items-center w-100">
                        <label class="mr-10">Year</label>
                        <select v-model="formData.dob_year" class="form-control" @blur="v$.formData.dob_year.$touch">
                            <option v-for="year in years" :value="year">{{ year }}</option>
                        </select>
                        <div class="input-errors" v-for="(error, index) of v$.formData.dob_year.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="form-group d-inline-block mt-4">
                    <button @click="handleSubmit" :disabled="v$.formData.$invalid" class="c-btn bg-color-secondary"
                                :class="{ 'not-allowed': v$.formData.$invalid }">
                                Update
                            </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email, minLength } from '@vuelidate/validators';
import { validName, validPhoneNo, validString } from '../../utility/Validator';
import { MyProfile } from '../../apiAgent';
import { _months, _years } from '../../utility/constants';
import _ from 'lodash';
export default {
    name: "MyProfile",
    setup() {
        return { v$: useVuelidate() };
    },
    mounted() {
        this.setUserDetail();
    },
    data() {
        return {
            formData: {
                id: 0,
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                dob_day: '',
                dob_month: '',
                dob_year: ''
            },
            months: _months,
            years: _years
        };
    },
    methods: {
        setUserDetail() {
            this.$store.dispatch("loader/setLoader", true);
            const userDetails = this.$store.getters['auth/userDetails'];
            const parts = userDetails.name.split(' ');
            const firstPart = parts[0];
            const secondPart = parts.slice(1).join(' ');
            this.formData = {
                id: userDetails.id,
                first_name: firstPart,
                last_name: secondPart,
                email: userDetails.email,
                phone_number: userDetails.phone_number,
                dob_day: userDetails.dob_day,
                dob_month: userDetails.dob_month,
                dob_year: userDetails.dob_year
            };
            setTimeout(() => {
                this.$store.dispatch("loader/setLoader", false);
            }, 1000);
        },
        handleSubmit() {
            this.v$.$validate();
            if (!this.v$.$error) {
                this.$store.dispatch('loader/setLoader', true);
                const body = this.reformatUserObject(this.formData);
                MyProfile.UpdateUser(this.formData.id, body)
                    .then((response) => {
                        this.$store.dispatch('loader/setLoader', false);
                        const userDetails = this.reformatUserObject({ ...this.$store.getters['auth/userDetails'], ...this.formData })
                        this.$store.dispatch('auth/updateUser', userDetails);
                        this.$iziToast.success({
                            title: 'Success',
                            message: response.data.data.message
                        });
                    })
                    .catch((error) => {
                        this.$store.dispatch('loader/setLoader', false);
                        console.error(error);
                        this.$iziToast.error({
                            title: 'Error',
                            message: error.response.data.message
                        });
                    });
            }
        },
        reformatUserObject(userObject) {
            let object = _.cloneDeep(userObject);
            object.name = userObject.first_name + ' ' + userObject.last_name;
            delete object.first_name;
            delete object.last_name;
            return object;
        }
    },
    validations() {
        return {
            formData: {
                first_name: {
                    required,
                    name_validation: {
                        $validator: validName,
                        $message: 'Invalid Name. Valid name only contain letters, dashes (-) and spaces.'
                    }
                },
                last_name: {
                    required,
                    name_validation: {
                        $validator: validName,
                        $message: 'Invalid Name. Valid name only contain letters, dashes (-) and spaces.'
                    }
                },
                email: {
                    required,
                    email
                },
                phone_number: {
                    required,
                    phone_validation: {
                        $validator: validPhoneNo,
                        $message: 'Invalid Phone Number. Valid phone number only contain Number.'
                    }
                },
                dob_day: {
                    required
                },
                dob_month: {
                    required
                },
                dob_year: {
                    required
                }
            }
        };
    }
}
</script>
<style>
.error-msg {
    color: red;
}

.not-allowed {
    cursor: not-allowed !important;
}
</style>
