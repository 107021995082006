<template>
  <Header></Header>
  <section class="spacing__x f-header">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="wavier-signin-info br-0">
            <img class="element element-2"
                 src="@/assets/images/elements/element-2.png">
            <img class="element element-3"
                 src="@/assets/images/elements/element-3.png">
            <h4 class="fs-60 fw-700 tt-uppercase ls-3 heading-color-1">Login</h4>
            <p class="regular-color-2 ff-fredoka">PLEASE LOGIN IF YOU ALREADY HAVE AN ACCOUNT WITH US</p>
          </div>
        </div>
      </div>
      <div class="row fg-mb mt-5">
        <div class="col-lg-6">
          <div class="form-group">
            <input type="email"
                   v-model="formData.email"
                   class="custom-input-style"
                   placeholder="Email*"
                   @blur="v$.formData.email.$touch">
          </div>
          <div class="input-errors"
               v-for="(error, index) of v$.formData.email.$errors"
               :key="index">
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group position-relative">
            <input :type="showPassword ? 'text' : 'password'"
                   v-model="formData.password"
                   class="custom-input-style"
                   placeholder="Password*"
                   @blur="v$.formData.password.$touch" />
            <div class="show-password">
              <button class="button"
                      @click="toggleShow($event)">
                <span class="icon is-small is-right">
                  <i class="fas"
                     :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                </span>
              </button>
            </div>
          </div>
          <div class="input-errors"
               v-for="(error, index) of v$.formData.password.$errors"
               :key="index">
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group text-center">
            <ul class="inline-flex justify-content-center">
              <li><button class="c-btn bg-color-tertiary tt-uppercase fw-500"
                        @click="handleSubmit()"
                        :class="{ 'not-allowed': v$.formData.$invalid }">Login</button></li>
              <li><button class="c-btn style-2 tt-uppercase fw-500"
                        @click="forgotPassword()">Forget Password</button>
              </li>
              <li><button class="c-btn bg-color-secondary fw-500"
                        @click="onRegisterClick">No Account, Yet Register</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer></Footer>
</template>
<script>
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
import { Login } from '../apiAgent';
import useVuelidate from '@vuelidate/core';
import { required, email, minLength } from '@vuelidate/validators';
import { routes } from '../../router';
export default {
  name: 'Login',
  data() {
    return {
      formData: {
        email: '',
        password: '',
      },
      showPassword: false
    };
  },
  components: {
    Header,
    Footer
  },
  setup() {
    return { v$: useVuelidate() };
  },
  mounted() {
    this.$store.dispatch('auth/logout');
  },
  methods: {
    handleSubmit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$store.dispatch('loader/setLoader', true);
        Login(this.formData).then(response => {
          this.$store.dispatch('auth/login', {
            token: response.data.authorization.token,
            user: response.data.user
          });
          const cartObject = response.data.user.cart;
          if(cartObject && cartObject.length > 0){
            for (const cart of cartObject) {
              this.$store.dispatch('cart/SaveCart', cart);
            }
          }
          this.redirectScreen();
          this.$store.dispatch('loader/setLoader', false);
          this.$iziToast.success({
              title: 'Success',
              message: 'Login successfully!',
            });
        })
          .catch(error => {
            this.$store.dispatch('loader/setLoader', false);
            console.error(error);
            this.$iziToast.error({
              title: 'Error',
              message: error.response.data.message,
            });
            this.formData.email = '';
            this.formData.password = '';
          });
      }
    },
    redirectScreen() {
      const previousRoute = this.$router.options.history.state.back;
      const components = ['CartDetail', 'MembershipForm', 'BookingForm'];
      for (const component of components) {
        const path = routes.find(o => o.name == component).path;
        if (previousRoute == path) {
          this.$router.push(path);
          return;
        }
      }
      this.$router.push('/waiver-detail');
    },
    onRegisterClick() {
      this.$router.replace('/register');
    },
    forgotPassword() {
      const forgotPasswordLink = `${process.env.VUE_APP_FORGOT_PASSWORD_LINK}/password/forget`;
      window.open(forgotPasswordLink, "_blank");
    },
    toggleShow(event) {
      event.preventDefault();
      this.showPassword = !this.showPassword;
    }
  },
  validations() {
    {
      return {
        formData: {
          email: {
            required,
            email
          },
          password: {
            required,
            min: minLength(5)
          }
        }
      }
    }
  }
}
</script>