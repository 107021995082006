import axios from 'axios';
import store from './store/store';

const instance = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  timeout: 30000, // Set a timeout (optional)
  headers: {
    'Content-Type': 'application/json' // Set common headers here
    }
});

// Request interceptor to add auth header. 
instance.interceptors.request.use(function (config) {
    const token = store.getters['auth/token'];
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    } else {
        config.headers.Authorization = null;
        /*if setting null does not remove `Authorization` header then try
          delete axios.defaults.headers.common['Authorization'];
        */
    }
    return config;
});

export const Login = (formData) => instance.post('/login', formData);
export const Register = (formData) => instance.post('/register', formData);
export const CheckUser = (formData) => instance.post('/check_user', formData);

export const WaiverDetail = {
    GetWaiver: () => instance.get('/get_waivers'),
    DeleteWaiver: (waiverId) => instance.delete(`/waivers/${waiverId}`),
    SignWaiver: (formData) => instance.post('/sign_waiver', formData)
};

export const Activities = {
    GetTags : () => instance.get('/activities-by-tags'),
    PostActivities : (body) => instance.post('/reservations', body)
}

export const Promotions = {
    GetPromotions : () => instance.get('/promotions'),
    VerifyPromotionCode : (code) => {
        const body = {
            "promo_code": code
        };
        return instance.post('/promotions/verify', body);
    }
}

export const Contact = {
    PostForm : (body) => instance.post('/contact', body)
}

export const Membership = {
    GetMembershipDetails : () => instance.get('/membership-details'),
    PostMemberships : (body) => instance.post('/membership', body)
}

export const MyProfile = {
    UpdateUser : (userId, body) => instance.post(`/user/update/${userId}`, body),
    getRecentOrders : () => instance.get('/customer-reservations'),
    getUserMemberships : () => instance.get('/user-membership'),
}

export const PaymentSetup = {
    BillingInfo : (body) => instance.post(`/serialized-fields`, body),
}

export const CartManagement = {
    postCart : (body) => instance.post(`/update-cart`, body),
}

export const Party = {
    availableSlot : (body) => instance.post('/party-slots', body)
}

export const Home = {
    pageMeta : () => instance.get('/pages'),
}
