<template>
	<footer>
		<div class="footer-top">
			<div class="container">
				<div class="row">
					<div class="col-lg-4">
						<div class="ft-nav">
							<img class="mw-40"
								 src="@/assets/images/logo-100.png"
								 alt="" />
							<p class="mt-3">A cool place for fun and party with state-of-the-art activities. Book now
								and make a memorable day for your kids.</p>
						</div>
					</div>
					<div class="col-lg-8">
						<div class="row">
							<div class="col-lg-4">
								<div class="ft-nav">
									<h3>Quick Link</h3>
									<ul>
										<li><router-link to="/about-us">Attractions</router-link></li>
										<li><router-link to="/shop/weekends">Tickets</router-link></li>
										<li><router-link to="/waiver">Waiver</router-link></li>
										<li><router-link to="/contact">Contact Us</router-link></li>
									</ul>
								</div>
							</div>
							<div class="col-lg-4">
								<div class="ft-nav">
									<h3>Contact</h3>
									<ul>
										<li><a href="mailto:info@kidsfunhut.ca"><i class="fas fa-envelope"></i>
												info@kidsfunhut.ca</a></li>
										<li><a href="tel:5488893504"><i class="fas fa-phone-alt"></i> 548-889-3504</a>
										</li>
									</ul>
								</div>
							</div>
							<div class="col-lg-4">
								<div class="ft-nav">
									<h3>Address</h3>
									<ul>
										<li><a href="https://www.google.com/maps/place/Kids+Fun+Hut,+525+Hespeler+Rd+g2,+Cambridge,+ON+N1R+6J2/data=!4m2!3m1!1s0x882b89ddf6618ba3:0xcf0efced8dbcc5a0?utm_source=mstt_1&entry=gps&lucs=47068615,,47075915&g_ep=CAESCTExLjk3LjMwMxgAINeCAyoSNDcwNjg2MTUsLDQ3MDc1OTE1QgJDQQ%3D%3D"
											   target="_blank">Address: G2-525 Hespeler Rd, Cambridge, ON , N1R6J2</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer-bottom">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="copyrights">
							<p>Copyright © {{ new Date().getFullYear() }} Kids Fun Hut. All Rights Reserved. Powered by
								<a href="https://sparkitsolutions.org"
								   target="_blank">
									<span id="link">
										Spark IT Solutions
									</span>
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>

</template>
<script>
export default {
	name: 'Footer'
}
</script>
<style>
.logo {
	max-width: 40% !important;
}

#link {
	color: white;
}
</style>