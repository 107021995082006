<template>
    <Header></Header>
    <section class="spacing__x f-header">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="thank-you-container">
                        <div class="page-not-found">Sorry!</div>
                        <div>The Requested Page Can't Be Found.</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer></Footer>
</template>  
<script>
import Header from '../layouts/Header.vue'
import Footer from '../layouts/Footer.vue'
export default {
    name: 'PageNotFound',
    components: {
        Header,
        Footer
    },
}
</script>