<template>
    <Header></Header>
    <section class="spacing__x f-header">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="wavier-signin-info br-0">
                        <img class="element element-2"
                             src="@/assets/images/elements/element-2.png">
                        <h4 class="fs-60 fw-700 tt-uppercase ls-3 heading-color-1">Signing A Waiver</h4>
                        <p class="regular-color-1 ff-fredoka">
                            To avoid inconvenience please fill and sign your waiver online before visiting <router-link
                                         to="/">Kids Fun Hut.</router-link>If you have signed your waiver previously and
                            want to update it use the <router-link to="/login">"Update Previous Waiver"</router-link>
                            button.
                        </p>
                        <ul class="inline-flex">
                            <li>
                                <a class="c-btn style-2 tt-uppercase"
                                   @click="UpdatePreviousWaiver()">
                                    Update Previous Waiver {{ !this.$store.getters["auth/isAuth"] ? '(Login)' : '' }}
                                </a>
                            </li>
                            <li>
                                <router-link to="sign-in"
                                             class="c-btn style-1 tt-uppercase">Sign A New Waiver
                                </router-link>
                            </li>
                        </ul>
                        <img class="element element-3"
                             src="@/assets/images/elements/element-3.png">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer></Footer>
</template>  
<script>
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
export default {
    name: 'SignIn',
    components: {
        Header,
        Footer
    },
    methods: {
        UpdatePreviousWaiver() {
            const routeTo = this.$store.getters["auth/isAuth"] ? '/waiver-detail' : '/login';
            this.$router.push(routeTo);
        }
    }
}
</script>