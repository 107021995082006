<template>
    <Header />

    <!-- HEADING SECTION START -->
    <section class=" banner">
        
        <div class="container text-center position-relative">
            
        </div>
    </section>
    <!-- HEADING SECTION END -->

    <section class="spacing__x f-header ninja-text-section">
        <img class="element element-3" src="@/assets/images/elements/element-3.png" alt="" />
        <img class="element-2" src="@/assets/images/elements/element-2.png" alt="" />
        <img class="element-5" src="@/assets/images/elements/element-5.png" alt="" />
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mb-5">
                        <h1 class="fs-60 heading-color-1">Soft PlayGround</h1>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-7">
                    <div class="mb-30">
                        <span class="color-secondary-2 fw-700 mb-10 d-inline-block">Where Every Bounce and Giggle is a Happy Memory!</span>
                        <h2 class="mb-20 heading-color-1 fs-30">
                            Ultimate Soft Playground Experience
                        </h2>
                        <p class="fs-17 regular-color-1">
                            At Kids Fun Hut, we've transformed the ordinary playground into an extraordinary soft play experience for kids. Our indoor soft playground is designed with the safety and delight of your little ones in mind, offering
                            a magical space where imaginations soar, and laughter echoes. Our soft playground is more than just a play area; it's a magical wonderland designed to captivate, engage, and thrill young imaginations.
                        </p>
                    </div>
                    <h3 class="fs-20 heading-color-1 tt-capitalize fw-600 mb-30">
                        Key Features of Soft Playground:
                    </h3>

                    <div class="row">
                        <div class="col-lg-6">
                            <div class="count-listing">
                                <p class="heading-color-1"><span>1</span>Toddler-Friendly</p>
                                <p class="heading-color-1"><span>2</span>Interactive Playzones</p>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="count-listing">
                                <p class="heading-color-1 align-items-start"><span>3</span>Engaging and Stimulating</p>
                                <p class="heading-color-1"><span>4</span>Climb and Slide Heaven</p>
                            </div>
                        </div>
                    </div>

                    <div class="btn-list mb-20">
                        <router-link to="/shop/weekends" class="c-btn style-3">Buy Weekend Tickets</router-link>
                        <router-link to="/shop/weekdays" class="c-btn style-2">Buy Weekday Tickets</router-link>
                    </div>
                    <p class="fs-17 regular-color-1 mb-30">
                        At Kids Fun Hut, we believe that childhood should be filled with laughter, discovery, and adventure. Book your soft playground experience today and witness the joy as your child explores a world of wonder designed just
                        for them!
                    </p>
                </div>
                <div class="col-lg-5">
                    <img class="right-side" src="@/assets/images/attractions/soft-playground.png" alt="" />
                </div>
            </div>
        </div>
    </section>

    <section class="spacing__x showcase-ninja-section">
        <img class="element-4" src="@/assets/images/elements/element-4.png" alt="" />
        <div class="container">
            <div class="text-center">
                <div class="mb-20">
                    <h4 class="mb-20 heading-color-1 fs-30 tt-capitalize">Ultimate Soft Playground Experience</h4>
                    <p class="fs-17 regular-color-1">
                        Step into a world of ninja adventures as your kids take on various challenges inspired by the legendary warriors of ancient Japan. Our ninja-themed decor and immersive environment will transport them to a world of
                        excitement. Whether your child is 3 or 13, we have age-appropriate obstacles and activities suitable for every young ninja. From climbing walls to trampoline parks, there's something for everyone.
                    </p>
                </div>
                <div class="mb-20">
                    <span class="color-secondary-2 fw-700 mb-10 d-inline-block tt-capitalize">Family Fun</span>
                    <p class="fs-17 regular-color-1">
                        Ninja Kids Adventure Park isn't just for kids. We welcome parents to join in the fun, watch their little ninjas conquer obstacles, or relax in our Ninja Lounge while sipping on a beverage.
                    </p>
                </div>
                <div class="mb-20">
                    <span class="color-secondary-2 fw-700 mb-10 d-inline-block tt-capitalize">Safe and Supervised</span>
                    <p class="fs-17 regular-color-1">
                        Safety is our top priority. Our facility is equipped with state-of-the-art safety features, and our trained staff members are always present to ensure a secure and enjoyable experience. Check our opening hours, admission
                        rates, and special offers. Don't forget to review our safety guidelines and dress code to ensure a safe and enjoyable experience for everyone.
                    </p>
                </div>
                <div class="btn-list mb-20 justify-content-center">
                    <router-link to="/shop/weekends" class="c-btn style-3">Buy Weekend Tickets</router-link>
                    <router-link to="/shop/weekdays" class="c-btn style-2">Buy Weekday Tickets</router-link>
                </div>
            </div>
        </div>
    </section>

    <Footer />
</template>
<script>
import Header from "../layouts/Header.vue";
import Footer from "../layouts/Footer.vue";
export default {
    name: "Camps",
    components: {
        Header,
        Footer,
    },
};
</script>
